import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SpaceStore {
  rootStore

  spaces: any = {}
  space: any = null
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setSpaces = (spaces: any) => { this.spaces = spaces }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getSpaces(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getSpaces(pUuid)
      if (response?.ok) {
        const spaces = response.data || {}
        this.setSpaces(spaces)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateSpaces(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateSpaces(pUuid, payload)
      if (response?.ok) {
        this.getSpaces(pUuid)
        showMessage(i18n.t('spaces_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('spaces_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
