import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { DateTime } from 'luxon'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SessionStore {
  rootStore

  me: any = null
  language: string = 'fi'
  time: string = DateTime.local().toFormat('dd.MM.yyyy HH.mm')
  loading = false
  userInvite: any = null
  passwordResetRequest: any = null
  keepAliveStarted: boolean = false
  isLoggedIn = false
  initialized = false
  showInfoBanner = true

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setMe = (user: any) => { this.me = user }
  setLanguage = (language: string) => { this.language = language }
  setTime = (time: string) => { this.time = time }
  setIsLoggedIn = (isLoggedIn: boolean) => { this.isLoggedIn = isLoggedIn }
  setInitialized = (initialized: boolean) => { this.initialized = initialized }
  setUserInvite = (userInvite: any) => { this.userInvite = userInvite }
  setPasswordResetRequest = (passwordResetRequest: any) => { this.passwordResetRequest = passwordResetRequest }
  setKeepAliveStarted = (started: any) => { this.keepAliveStarted = started }
  setShowInfoBanner = (showInfoBanner: boolean) => { this.showInfoBanner = showInfoBanner }
  setLoading = (loading: boolean) => { this.loading = loading }

  async login(email: string, password: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.login({ email, password })
      if (response.ok) {
        const user = response?.data ?? null
        this.setMe(user)
        this.setIsLoggedIn(true)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async logout() {
    this.setLoading(true)
    try {
      const response = await Api.logout()
      if (response.ok) {
        this.setMe(null)
        this.setIsLoggedIn(false)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getMe() {
    this.setLoading(true)
    try {
      const response = await Api.getMe()
      if (response.ok) {
        const user = response.data ?? null
        this.setMe(user)
        this.setIsLoggedIn(true)
        this.startKeepAlive()
      }
    } catch (e) {
      console.log(e)
    }
    this.setInitialized(true)
    this.setLoading(false)
  }

  async updateMe(data: any) {
    this.setLoading(true)
    try {
      const response = await Api.updateMe(data)
      if (response.ok) {
        const user = response.data ?? null
        this.setMe(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateMyProfilePhoto(data: any) {
    this.setLoading(true)
    try {
      const response = await Api.updateMyProfilePhoto(data)
      if (response.ok) {
        const user = response.data ?? null
        this.setMe(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateMyBackgroundPhoto(data: any) {
    this.setLoading(true)
    try {
      const response = await Api.updateMyBackgroundPhoto(data)
      if (response.ok) {
        const user = response.data ?? null
        this.setMe(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async runKeepAlive() {
    if (this.isLoggedIn && this.initialized) {
      const result = await Api.keepAlive()
      if (result.status === 401) {
        this.setMe(null)
        this.setIsLoggedIn(false)
      }
    }
  }
  
  startTimeInterval() {
    setInterval(() => {
      this.setTime(DateTime.local().toFormat('dd.MM.yyyy HH.mm'))
    }, 60000)
  }

  async startKeepAlive() {
    if (!this.keepAliveStarted) {
      this.setKeepAliveStarted(true)
      setTimeout(this.runKeepAlive, 1000)
      setInterval(() => this.runKeepAlive(), 1000 * 60 * 60)
      const seconds = parseInt(DateTime.local().toFormat('s'))
      setTimeout(() => {
        this.setTime(DateTime.local().toFormat('dd.MM.yyyy HH.mm'))
        this.startTimeInterval()
      }, (60 - seconds) * 1000)
    }
  }
  async getUserInvite(code: string) {
    this.setLoading(true)
    try {
      const response = await Api.getInvite(code)
      if (response.ok) {
        const invite = response?.data ?? null
        this.setUserInvite(invite)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async verifyUserAccount(password: string, callback: any) {
    this.setLoading(true)
    try {
      const verifyBody = {
        uuid: this.userInvite?.uuid,
        code: this.userInvite?.code,
        password
      }
      const response = await Api.verifyAccount(verifyBody)
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPasswordResetRequest(code: string) {
    this.setLoading(true)
    try {
      const response = await Api.getPasswordResetRequest(code)
      if (response.ok) {
        const passwordResetRequest = response?.data ?? null
        this.setPasswordResetRequest(passwordResetRequest)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async requestPasswordReset(email: string, callback: any) {
    this.setLoading(true)
    try {
      const response = await Api.requestPasswordReset({ email })
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async completePasswordReset(password: string, callback: any) {
    this.setLoading(true)
    try {
      const passwordResetBody = {
        uuid: this.passwordResetRequest?.uuid,
        code: this.passwordResetRequest?.code,
        password
      }
      const response = await Api.completePasswordReset(passwordResetBody)
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
