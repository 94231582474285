import {
  ComponentTypes,
  ComponentExtraProperties,
  LayoutPosition,
  FormTypes,
  ComponentExtraStyles
} from './layout'
import { EnergyClassOptions } from './options'

const { DisabledIfNoParentValue } = ComponentExtraProperties
const { Left, Right } = LayoutPosition
const { Indented } = ComponentExtraStyles

// Kattomateriaalit
export enum CeilingMaterials {
  // Maali
  Paint = 'paint',
  // Paneeli
  Panel = 'panel'
}

// Lattiamateriaalit
export enum FloorMaterials {
  // Laatta
  Tile = 'tile',
  // Laminaatti
  Laminate = 'laminate',
  // Parketti
  Parquet = 'parquet',
  // Muovimatto
  Vinyl = 'vinyl',
  // Kivi
  Stone = 'stone'
}

// Seinämateriaalit
export enum WallMaterials {
  // Maali
  Paint = 'paint',
  // Tapetti
  Wallpaper = 'wallpaper',
  // Laatta
  Tile = 'tile',
  // Paneeli
  Panel = 'panel'
}

// Palovaroitintyypit
export enum SmokeDetectorTypes {
  // Savu
  Smoke = 'smoke',
  // Häkä
  CarbonMonoxide = 'carbon_monoxide'
}

// Ikkunatyypit
export enum WindowTypes {
  // 1-lasinen
  One_Glass = 'one_glass',
  // 2-lasinen
  Two_Glass = 'two_glass',
  // 3-lasinen
  Three_Glass = 'three_glass'
}

// Ikkunan ilmansuunnat
export enum WindowDirections {
  North = 'north',
  // NorthEast = 'north_east',
  // NorthWest = 'north_west',
  South = 'south',
  // SouthEast = 'south_east',
  // SouthWest = 'south_west',
  East = 'east',
  West = 'west'
}

// Valaisinpisteet
export enum LightSocketTypes {
  // Pistoke
  Socket = 'socket',
  // Sokeripala
  SugarCube = 'sugar_cube'
}

// Pistokkeet
export enum PowerSocketTypes {
  // TV-antenni
  TvAntenna = 'tv_antenna',
  // Radio antenni
  RadioAntenna = 'radio_antenna',
  // Puhelin
  Telephone = 'telephone',
  // Tietoliikenne
  Data = 'data',
  // Sähkö
  Power = 'power'
}

// Valokatkaisijat
export enum LightSwitchTypes {
  // Himmentävä
  Dimmer = 'dimmer',
  // 1-katkaisijaa
  OneSwitch = 'one_switch',
  // 2-katkaisijaa
  TwoSwitch = 'two_switch',
  // 3-katkaisijaa
  ThreeSwitch = 'three_switch'
}

// Lattiakaivot
export enum FloorDrainTypes {
  // Valurauta
  CastIron = 'cast_iron',
  // Muovi
  Plastic = 'plastic'
}

// Lavuaarit
export enum SinkTypes {
  // Posliini
  Porcelain = 'porcelain',
  // Metalli
  Metal = 'metal'
}

// Energialuokat
export enum EnergyClasses {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g'
}

// Liesityypit
export enum StoveTypes {
  // Induktio
  Induction = 'induction',
  // Keraaminen
  Ceramic = 'ceramic',
  // Valurauta
  CastIron = 'cast_iron'
}

// Uunilla on kiertoilma
export const OvenHasCirculatingAirOptions = [
  { value: true, label: 'Kyllä' },
  { value: false, label: 'Ei' }
]

// Kiuastyypit
export enum SaunaStoveTypes {
  // Sähkö
  Electric = 'electric',
  // Puu
  Wood = 'wood'
}

// Tulisijat
export enum FireplaceTypes {
  // Avonainen
  Open = 'open',
  // Umpinainen
  Closed = 'closed',
  // Varaava
  Accumulating = 'accumulating'
}

// Jääkaapit
export enum RefrigeratorTypes {
  // Jääkaappi
  Refrigerator = 'refrigerator',
  // Jääkaappi-pakastin
  RefrigeratorWithFreezer = 'refrigerator_with_freezer'
}

// Pakastimet
export enum FreezerTypes {
  // Arkkupakastin
  ChestFreezer = 'chest_freezer',
  // Kaappi-pakastin
  Freezer = 'freezer'
}

// Ovityypit
export enum DoorTypes {
  // Palo-ovi
  FireDoor = 'fire_door',
  // Ulko-ovi
  ExteriorDoor = 'exterior_door',
  // Väliovi
  InteriorDoor = 'interior_door'
}

// WC-istuimet
export enum ToiletTypes {
  // Lattia-asennus
  FloorMounted = 'floor_mounted',
  // Seinä-asennus
  WallMounted = 'wall_mounted'
}

// WC-istuimen materiaalit
export enum ToiletMaterials {
  // Metalli
  Metal = 'metal',
  // Posliini
  Porcelain = 'porcelain'
}

// Suihkukaapit
export enum ShowerCabinTypes {
  // Ovella
  WithDoor = 'with_door',
  // Verholla
  WithCurtain = 'with_curtain'
}

// Suihkukaapissa allas
export const ShowerCabinHasSinkOptions = [
  { value: true, label: 'Kyllä' },
  { value: false, label: 'Ei' }
]

// Kylpyammeet
export enum BathtubTypes {
  // Poreilla
  WithBubbles = 'with_bubbles',
  // Ilman poreita
  WithoutBubbles = 'without_bubbles'
}

// Palovaroittimien määrät
export const SmokeDetectorCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Seinien määrät
export const WallCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Ikkunoiden määrät
export const WindowCountOptions = {
  ...Array.from({ length: 10 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

// Ovien määrät
export const DoorCountOptions = {
  ...Array.from({ length: 5 }, (_, i) => i + 1).map((item: number) => {
    return { value: item, label: item }
  })
}

export enum RoomTypes {
  // Eteinen
  Hallway = 'hallway',
  // Olohuone
  LivingRoom = 'living_room',
  // Makuuhuone
  Bedroom = 'bedroom',
  // Keittiö
  Kitchen = 'kitchen',
  // WC
  Wc = 'wc',
  // Pesutilat
  Bathroom = 'bathroom',
  // Sauna
  Sauna = 'sauna',
  // Kodinhoitohuone
  UtilityRoom = 'utility_room',
  // Vaatehuone
  WalkInCloset = 'walk_in_closet',
  // Parveke
  Balcony = 'balcony',
  // Muu tila
  Other = 'other'
}

// Huoneen tietojen osiot
export enum RoomSections {
  // Pintamateriaalit
  SurfaceMaterials = 'surface_materials',
  // Ikkunat
  Windows = 'windows',
  // Ovet
  Doors = 'doors',
  // Pistokkeet ja valaistus
  SocketsAndLighting = 'sockets_and_lighting',
  // Imanvaihto
  Ventilation = 'ventilation',
  // Lämmitys
  Heating = 'heating',
  // Kalusteet
  Fixtures = 'fixtures',
  // Muut kalusteet
  OtherFixtures = 'other_fixtures'
}

export const RoomSectionSubSections = {
  [RoomSections.SurfaceMaterials]: [
    /*
    {
      category: 'surface_materials',
      value: 'walls',
      position: Left,
      minHeight: '35rem',
      items: [
        {
          value: 'walls_paint',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'walls_paint_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'walls_wallpaper',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'walls_wallpaper_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'walls_tile',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'walls_tile_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'walls_panel',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'walls_panel_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'walls_notes',
          label: 'notes',
          type: ComponentTypes.Input,
          items: []
        }
      ]
    },
    {
      category: 'surface_materials',
      value: 'ceiling',
      position: Right,
      items: [
        {
          value: 'ceiling_material',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: CeilingMaterials.Paint, label: 'paint' },
            { value: CeilingMaterials.Panel, label: 'panel' }
          ]
        },
        { value: 'ceiling_notes', label: 'notes', type: ComponentTypes.Input }
      ]
    },
    {
      category: 'surface_materials',
      value: 'floor',
      position: Right,
      items: [
        {
          value: 'floor_material',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: FloorMaterials.Tile, label: 'tile' },
            { value: FloorMaterials.Laminate, label: 'laminate' },
            { value: FloorMaterials.Parquet, label: 'parquet' },
            { value: FloorMaterials.Vinyl, label: 'vinyl' },
            { value: FloorMaterials.Stone, label: 'stone' }
          ]
        },
        { value: 'floor_notes', label: 'notes', type: ComponentTypes.Input }
      ]
    }
    */
  ],
  [RoomSections.Windows]: [
    /*
    {
      category: 'windows',
      value: 'window_types',
      position: Left,
      minHeight: '33.125rem',
      items: [
        {
          value: 'window_types_one_glass',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'window_types_one_glass_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'window_types_two_glass',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'window_types_two_glass_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'window_types_three_glass',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'window_types_three_glass_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'window_types_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'windows',
      value: 'window_air_filter',
      position: Right,
      items: [
        {
          value: 'window_air_filter',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'no', label: 'no' },
            { value: 'yes', label: 'yes' }
          ],
          items: [
            {
              value: 'window_air_filter_change_date',
              type: ComponentTypes.Input,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        { value: 'window_air_filter_notes', label: 'notes', type: ComponentTypes.Input }
      ]
    },
    {
      category: 'windows',
      value: 'window_directions',
      position: Right,
      items: [
        {
          value: 'window_directions',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'window_directions_north', label: 'north' },
            { value: 'window_directions_south', label: 'south' },
            { value: 'window_directions_east', label: 'east' },
            { value: 'window_directions_west', label: 'west' }
          ]
        },
      ] 
    }
    */
  ],
  [RoomSections.Doors]: [
    /*
    {
      category: 'doors',
      value: 'door_types',
      position: Left,
      minHeight: '28.25rem',
      items: [
        {
          value: 'door_types_fire_door',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'door_types_fire_door_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'door_types_exterior_door',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'door_types_exterior_door_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'door_types_interior_door',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'door_types_interior_door_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'door_types_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'doors',
      value: 'door_has_window',
      position: Right,
      items: [
        {
          value: 'door_has_window',
          options: [
            { value: 'yes', label: 'has_window' },
            { value: 'no', label: 'no_window' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'door_window_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'doors',
      value: 'door_has_lock',
      position: Right,
      items: [
        {
          value: 'door_has_lock',
          options: [
            { value: 'yes', label: 'has_lock' },
            { value: 'no', label: 'no_lock' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'door_has_lock_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [RoomSections.SocketsAndLighting]: [
    /*
    {
      category: 'sockets_and_lighting',
      value: 'socket_types',
      position: Left,
      items: [
        {
          value: 'socket_types_tv_antenna',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'socket_types_tv_antenna_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'socket_types_radio_antenna',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'socket_types_radio_antenna_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'socket_types_telephone',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'socket_types_telephone_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'socket_types_data',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'socket_types_data_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'socket_types_power',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'socket_types_power_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'socket_types_notes',
          label: 'notes',
          type: ComponentTypes.Input,
        }
      ]
    },
    {
      category: 'sockets_and_lighting',
      value: 'light_socket_types',
      position: Left,
      items: [
        {
          value: 'light_socket_types_socket',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_socket_types_socket_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_socket_types_sugar_cube',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_socket_types_sugar_cube_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_socket_types_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'sockets_and_lighting',
      value: 'light_switch_types',
      position: Right,
      minHeight: '40.125rem',
      items: [
        {
          value: 'light_switch_types_dimmer',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_switch_types_dimmer_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_switch_types_one_switch',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_switch_types_one_switch_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_switch_types_two_switch',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_switch_types_two_switch_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_switch_types_three_switch',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'light_switch_types_three_switch_quantity',
              type: ComponentTypes.NumberSelect,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'light_switch_types_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [RoomSections.Ventilation]: [
    /*
    {
      category: 'ventilation',
      value: 'ventilation_exhaust',
      position: Left,
      items: [
        {
          value: 'ventilation_exhaust_has_exhaust',
          options: [
            { value: 'yes', label: 'yes' },
            { value: 'no', label: 'no' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'ventilation_exhaust_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'ventilation',
      value: 'ventilation_supply',
      position: Left,
      items: [
        {
          value: 'ventilation_supply_has_supply',
          options: [
            { value: 'yes', label: 'yes' },
            { value: 'no', label: 'no' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'ventilation_supply_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'ventilation',
      value: 'ventilation_replacement',
      position: Right,
      items: [
        {
          value: 'ventilation_replacement_has_replacement',
          options: [
            { value: 'yes', label: 'yes' },
            { value: 'no', label: 'no' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'ventilation_replacement_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [RoomSections.Heating]: [
    /*
    {
      category: 'heating',
      value: 'heating_underfloor',
      position: Left,
      items: [
        {
          value: 'heating_underfloor_type',
          options: [
            { value: 'water', label: 'water_circulation' },
            { value: 'electric', label: 'electric' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'heating_underfloor_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'heating',
      value: 'heating_distribution',
      position: Left,
      items: [
        {
          value: 'heating_distribution_working',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'heating_distribution_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'heating',
      value: 'heating_radiator',
      position: Right,
      items: [
        {
          value: 'heating_radiator_type',
          options: [
            { value: 'water_circulation', label: 'water_circulation' },
            { value: 'electric', label: 'electric' }
          ],
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'heating_radiator_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: 'heating',
      value: 'heating_valve',
      position: Right,
      items: [
        {
          value: 'heating_valve_working',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'heating_valve_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [RoomSections.Fixtures]: [
    /*
    [
      {
        title: 'water_fixtures',
        type: FormTypes.SectionSubsections,
        items: [
          {
            category: 'fixtures',
            value: 'water_point',
            items: [
              {
                category: 'fixtures',
                value: 'water_point_type',
                position: Left,
                minHeight: '32.75rem',
                items: [
                  {
                    value: 'water_point_type_mixer',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'water_point_type_mixer_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'water_point_type_sink',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'water_point_type_sink_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'water_point_type_shower',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'water_point_type_shower_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'water_point_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'water_point_material',
                position: Right,
                items: [
                  {
                    value: 'water_point_material_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'brass', label: 'brass' },
                      { value: 'steel', label: 'steel' },
                      { value: 'composite', label: 'composite' },
                      { value: 'plastic', label: 'plastic' }
                    ]
                  },
                  {
                    value: 'water_point_material_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'water_point_age',
                position: Right,
                items: [
                  {
                    value: 'water_point_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'water_point_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'sink',
            items: [
              {
                category: 'fixtures',
                value: 'sink_material',
                position: Left,
                items: [
                  {
                    value: 'sink_material_porcelain',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'sink_material_porcelain_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'sink_material_metal',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'sink_material_metal_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'sink_material_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'sink_age',
                position: Right,
                items: [
                  {
                    value: 'sink_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'sink_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'shower_cabin',
            items: [
              {
                category: 'fixtures',
                value: 'shower_cabin_has_sink',
                position: Left,
                items: [
                  {
                    value: 'shower_cabin_has_sink',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'yes', label: 'has_sink' },
                      { value: 'no', label: 'no_sink' }
                    ]
                  },
                  {
                    value: 'shower_cabin_has_sink_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'shower_cabin_door_type',
                position: Right,
                items: [
                  {
                    value: 'shower_cabin_door_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'with_door', label: 'with_door' },
                      { value: 'with_curtains', label: 'with_curtains' }
                    ]
                  },
                  {
                    value: 'shower_cabin_door_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'bathtub',
            items: [
              {
                category: 'fixtures',
                value: 'bathtub_type',
                position: Left,
                items: [
                  {
                    value: 'bathtub_type_with_bubbles',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'with_bubbles', label: 'with_bubbles' },
                      { value: 'without_bubbles', label: 'without_bubbles' }
                    ]
                  },
                  {
                    value: 'bathtub_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'bathtub_age',
                position: Right,
                items: [
                  {
                    value: 'bathtub_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'bathtub_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'toilet',
            items: [
              {
                category: 'fixtures',
                value: 'toilet_type',
                position: Left,
                minHeight: '22.5rem',
                items: [
                  {
                    value: 'toilet_type_floor_mounted',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'toilet_type_floor_mounted_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'toilet_type_wall_mounted',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'toilet_type_wall_mounted_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'toilet_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'toilet_material',
                position: Right,
                items: [
                  {
                    value: 'toilet_material_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'metal', label: 'metal' },
                      { value: 'porcelain', label: 'porcelain' }
                    ]
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'toilet_age',
                position: Right,
                items: [
                  {
                    value: 'toilet_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'toilet_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'floor_drain',
            items: [
              {
                category: 'fixtures',
                value: 'floor_drain_material',
                position: Left,
                items: [
                  {
                    value: 'floor_drain_material_cast_iron',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'floor_drain_material_cast_iron_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'floor_drain_material_plastic',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'floor_drain_material_plastic_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'floor_drain_material_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'floor_drain_age',
                position: Right,
                items: [
                  {
                    value: 'floor_drain_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'floor_drain_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'water_heater',
            items: [
              {
                category: 'fixtures',
                value: 'water_heater_info',
                position: Left,
                items: [
                  {
                    value: 'water_heater_info_volume',
                    type: ComponentTypes.Input
                  },
                  {
                    value: 'water_heater_info_energy_class',
                    type: ComponentTypes.Select,
                    options: EnergyClassOptions,
                    mt: '1.125rem'
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'water_heater_age',
                position: Right,
                minHeight: '13.875rem',
                items: [
                  {
                    value: 'water_heater_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'water_heater_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ],
          },
        ]
      },
    ],
    [
      {
        title: 'electric_equipment',
        type: FormTypes.SectionSubsections,
        items: [
          {
            value: 'fridge',
            items: [
              {
                category: 'fixtures',
                value: 'fridge_type',
                position: Left,
                minHeight: '25.5rem',
                items: [
                  {
                    value: 'fridge_type_refrigerator',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'fridge_type_refrigerator_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'fridge_type_refrigerator_with_freezer',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'fridge_type_refrigerator_with_freezer_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'fridge_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'fridge_energy_class',
                position: Right,
                items: [
                  {
                    value: 'fridge_energy_class',
                    type: ComponentTypes.Select,
                    options: EnergyClassOptions,
                    mb: '1.125rem'
                  },
                  {
                    value: 'fridge_energy_class_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'fridge_built_year',
                position: Right,
                items: [
                  {
                    value: 'fridge_built_year_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'freezer',
            items: [
              {
                category: 'fixtures',
                value: 'freezer_type',
                position: Left,
                minHeight: '25.5rem',
                items: [
                  {
                    value: 'freezer_type_chest_freezer',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'freezer_type_chest_freezer_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'freezer_type_cabinet_freezer',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'freezer_type_cabinet_freezer_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'freezer_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'freezer_energy_class',
                position: Right,
                items: [
                  {
                    value: 'freezer_energy_class',
                    type: ComponentTypes.Select,
                    options: EnergyClassOptions,
                    mb: '1.125rem'
                  },
                  {
                    value: 'freezer_energy_class_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'freezer_built_year',
                position: Right,
                items: [
                  {
                    value: 'fridge_built_year_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'stove',
            items: [
              {
                category: 'fixtures',
                value: 'stove_type',
                position: Left,
                items: [
                  {
                    value: 'stove_type_induction',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'stove_type_induction_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'stove_type_ceramic',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'stove_type_ceramic_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'stove_type_cast_iron',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'stove_type_cast_iron_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'stove_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'stove_energy_class',
                position: Right,
                items: [
                  {
                    value: 'stove_energy_class',
                    type: ComponentTypes.Select,
                    options: EnergyClassOptions,
                    mb: '1.125rem'
                  },
                  {
                    value: 'stove_energy_class_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'stove_built_year',
                position: Right,
                minHeight: '9.325rem',
                items: [
                  {
                    value: 'stove_built_year_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'oven',
            items: [
              {
                category: 'fixtures',
                value: 'oven_type',
                position: Left,
                items: [
                  {
                    value: 'oven_type_steam',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'oven_type_steam_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'oven_type_pyrolysis',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'oven_type_pyrolysis_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'oven_type_circulating_air',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'oven_type_circulating_air_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'oven_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'oven_energy_class',
                position: Right,
                items: [
                  {
                    value: 'oven_energy_class',
                    type: ComponentTypes.Select,
                    options: EnergyClassOptions,
                    mb: '1.125rem'
                  },
                  {
                    value: 'oven_energy_class_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'oven_built_year',
                position: Right,
                minHeight: '9.325rem',
                items: [
                  {
                    value: 'oven_built_year_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'smoke_detector',
            items: [
              {
                category: 'fixtures',
                value: 'smoke_detector_count',
                position: Left,
                minHeight: '12.125rem',
                items: [
                  {
                    value: 'smoke_detector_count',
                    type: ComponentTypes.Select,
                    options: SmokeDetectorCountOptions
                  },
                  {
                    value: 'smoke_detector_count_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'smoke_detector_age',
                position: Right,
                items: [
                  {
                    value: 'smoke_detector_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'smoke_detector_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          },
          {
            value: 'sauna_stove',
            items: [
              {
                category: 'fixtures',
                value: 'sauna_stove_type',
                position: Left,
                items: [
                  {
                    value: 'sauna_stove_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'electric', label: 'electric' },
                      { value: 'wood', label: 'wood' }
                    ]
                  },
                  {
                    value: 'sauna_stove_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'fixtures',
                value: 'sauna_stove_age',
                position: Right,
                items: [
                  {
                    value: 'sauna_stove_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'sauna_stove_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    */
  ],
  [RoomSections.OtherFixtures]: [
    /*
    [
      {
        type: FormTypes.SectionSubsections,
        items: [
          {
            value: 'hearth',
            items: [
              {
                category: 'other_fixtures',
                value: 'hearth_type',
                position: Left,
                items: [
                  {
                    value: 'hearth_type_open',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'hearth_type_open_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'hearth_type_closed',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'hearth_type_closed_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'hearth_type_accumulating',
                    type: ComponentTypes.BooleanCheckbox,
                    items: [
                      {
                        value: 'hearth_type_accumulating_quantity',
                        type: ComponentTypes.NumberSelect,
                        extraProperties: [DisabledIfNoParentValue],
                        extraStyles: [Indented]
                      }
                    ]
                  },
                  {
                    value: 'hearth_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: 'other_fixtures',
                value: 'hearth_age',
                position: Right,
                items: [
                  {
                    value: 'hearth_age_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'original', label: 'original' },
                      { value: 'replaced', label: 'replaced' }
                    ]
                  },
                  {
                    value: 'hearth_age_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    */
  ]
}

export const RoomLayout = [
  {
    title: RoomSections.SurfaceMaterials,
    sections: RoomSectionSubSections[RoomSections.SurfaceMaterials]
  },
  {
    title: RoomSections.Windows,
    sections: RoomSectionSubSections[RoomSections.Windows]
  },
  {
    title: RoomSections.Doors,
    sections: RoomSectionSubSections[RoomSections.Doors]
  },
  {
    title: RoomSections.SocketsAndLighting,
    sections: RoomSectionSubSections[RoomSections.SocketsAndLighting]
  },
  {
    title: RoomSections.Ventilation,
    sections: RoomSectionSubSections[RoomSections.Ventilation]
  },
  {
    title: RoomSections.Heating,
    sections: RoomSectionSubSections[RoomSections.Heating]
  },
  {
    title: RoomSections.Fixtures,
    sections: RoomSectionSubSections[RoomSections.Fixtures]
  },
  {
    title: RoomSections.OtherFixtures,
    sections: RoomSectionSubSections[RoomSections.OtherFixtures]
  }
]

const getSections = (section: RoomSections) => {
  return RoomSectionSubSections[section]
    .map((section: any) => {
      if (section?.position) {
        return { value: section.value, label: section.value }
      } else {
        if (!section?.items && !section[0]) {
          return null
        }
        if (section?.[0]) {
          return section?.[0]?.items.map((item: any) => {
              return { value: item.value, label: item.value }
          })
        }
        return section?.items.map((item: any) => {
          if (item?.position) {
            return { value: item.value, label: item.value }
          } else {
            return item?.items.map((subItem: any) => {
              return { value: subItem.value, label: subItem.value }
            })
          }
        })
      }
    })
}

export const RoomSectionsOptions = [
  {
    value: RoomSections.SurfaceMaterials,
    label: RoomSections.SurfaceMaterials,
    sections: getSections(RoomSections.SurfaceMaterials)
  },
  {
    value: RoomSections.Windows,
    label: RoomSections.Windows,
    sections: getSections(RoomSections.Windows)
  },
  {
    value: RoomSections.Doors,
    label: RoomSections.Doors,
    sections: getSections(RoomSections.Doors)
  },
  {
    value: RoomSections.SocketsAndLighting,
    label: RoomSections.SocketsAndLighting,
    sections: getSections(RoomSections.SocketsAndLighting)
  },
  {
    value: RoomSections.Ventilation,
    label: RoomSections.Ventilation,
    sections: getSections(RoomSections.Ventilation)
  },
  {
    value: RoomSections.Heating,
    label: RoomSections.Heating,
    sections: getSections(RoomSections.Heating)
  },
  /*
  // TODO: FIX HACK
  {
    value: RoomSections.Fixtures,
    label: RoomSections.Fixtures,
    sections: [
      {
        title: 'water_fixtures',
        sections: getSections(RoomSections.Fixtures)[0]
      },
      {
        title: 'electric_equipment',
        sections: getSections(RoomSections.Fixtures)[1]
      }
    ]
  },
  // TODO: FIX HACK
  {
    value: RoomSections.OtherFixtures,
    label: RoomSections.OtherFixtures,
    sections: [{ value: 'hearth', label: 'hearth' }]
  }
  */
]

// console.log(RoomSectionsOptions)