import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class BuildingStore {
  rootStore

  buildings: any = []
  building: any = null
  files: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setBuildings = (buildings: any) => { this.buildings = buildings }
  setBuilding = (building: any) => { this.building = building }
  setFiles = (files: any) => { this.files = files }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getBuildings(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuildings(pUuid)
      if (response?.ok) {
        const buildings = response.data?.items || []
        this.setBuildings(buildings)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getBuilding(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuilding(pUuid, bUuid)
      if (response?.ok) {
        const building = response?.data || null
        this.setBuilding(building)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getBuildingStructures(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuildingStructures(pUuid, bUuid)
      if (response?.ok) {
        const building = response?.data || null
        this.setBuilding(building)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getBuildingParts(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuildingParts(pUuid, bUuid)
      if (response?.ok) {
        const building = response?.data || null
        this.setBuilding(building)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getBuildingSystems(pUuid: string, bUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuildingSystems(pUuid, bUuid)
      if (response?.ok) {
        const building = response?.data || null
        this.setBuilding(building)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createBuilding(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createBuilding(pUuid, payload)
      if (response?.ok) {
        this.getBuildings(pUuid)
        showMessage(i18n.t('building_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateBuilding(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateBuilding(pUuid, this.building?.uuid, payload)
      if (response?.ok) {
        this.getBuildings(pUuid)
        showMessage(i18n.t('building_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateBuildingStructures(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateBuildingStructures(pUuid, this.building?.uuid, payload)
      if (response?.ok) {
        this.getBuildingStructures(pUuid, this.building?.uuid)
        showMessage(i18n.t('building_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateBuildingParts(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateBuildingParts(pUuid, this.building?.uuid, payload)
      if (response?.ok) {
        this.getBuildingParts(pUuid, this.building?.uuid)
        showMessage(i18n.t('building_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateBuildingSystems(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateBuildingSystems(pUuid, this.building?.uuid, payload)
      if (response?.ok) {
        this.getBuildingSystems(pUuid, this.building?.uuid)
        showMessage(i18n.t('building_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateBuildingBackgroundPhoto(pUuid: string, fUuid: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateBuildingBackgroundPhoto(pUuid, this.building?.uuid, fUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setBuilding(data)
        this.getBuildings(pUuid)
        showMessage(i18n.t('building_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveBuilding(pUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveBuilding(pUuid, this.building?.uuid)
      if (response?.ok) {
        this.getBuildings(pUuid)
        showMessage(i18n.t('building_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('building_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getBuildingFiles(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getBuildingFiles(pUuid, this.building.uuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadBuildingFiles(pUuid: string, files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadBuildingFile(pUuid, this.building.uuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        this.getBuildingFiles(pUuid)
        if (okResponses?.length === files?.length) {
          if (okResponses?.length === 1) {
            showMessage(i18n.t('file_uploaded'))
          } else {
            showMessage(i18n.t('files_uploaded'))
          }
        }
      } else {
        showMessage(i18n.t('file_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveBuildingFile(pUuid: string, fUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveBuildingFile(pUuid, this.building.uuid, fUuid)
      if (response?.ok) {
        this.getBuildingFiles(pUuid)
        showMessage(i18n.t('file_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
