import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class NotificationStore {
  rootStore

  notifications: any = []
  totalNotifications: number = 0
  notification: any = null
  filters: any = null
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setNotifications = (notifications: any) => { this.notifications = notifications }
  setTotalNotifications = (totalNotifications: number) => { this.totalNotifications = totalNotifications }
  setNotification = (notification: any) => { this.notification = notification }
  setFilters = (filters: any) => { this.filters = filters }
  setLoading = (loading: boolean) => { this.loading = loading }
}
