import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Input, Select } from '../../../Components'
import { BuildingYearOptions, FinnishMunicipalities, BuildingTypes, BuildingPurposes } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const CreateBuilding = (props: any) => {
  const { sessionStore, buildingStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [nameFi, setNameFi] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [buildingPurpose, setBuildingPurpose] = useState(BuildingPurposes.Residential)
  const [buildingYear, setBuildingYear] = useState('')
  const [type, setType] = useState(BuildingTypes.Condominium)
  const [areaSize, setAreaSize] = useState(null)
  const [floorCount, setFloorCount] = useState(null)
  const [apartmentCount, setApartmentCount] = useState(null)
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')

  useEffect(() => {
  }, [])

  const uuid = params?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return null
  }

  const getBuildingPurposeOptions = () => {
    return [
      { value: 'residential', label: t('residential') },
      // { value: 'commercial', label: t('commercial') }
    ]
  }
  const getBuildingTypeOptions = () => {
    return [
      { value: BuildingTypes.Condominium, label: t(BuildingTypes.Condominium) }
    ]
  }

  const getFloorCountOptions = () => {
    return Array.from({ length: 40 }, (_, i) => i + 1).map((item: any) => {
      return { value: item, label: item }
    })
  }

  const getApartmentCountOptions = () => {
    return Array.from({ length: 120 }, (_, i) => i + 1).map((item: any) => {
      return { value: item, label: item }
    })
  }

  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }

  const isSendDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const create = () => {
    const callback = () => {
      setNameFi('')
      setBuildingId('')
      setBuildingPurpose(BuildingPurposes.Residential)
      setBuildingYear('')
      setType(BuildingTypes.Condominium)
      setAreaSize(null)
      setFloorCount(null)
      setApartmentCount(null)
      setAddress('')
      setPostalCode('')
      setCity('')
    }
    const newBuilding = {
      nameFi,
      buildingId,
      buildingPurpose,
      buildingYear,
      type,
      areaSize,
      floorCount,
      apartmentCount,
      address,
      postalCode,
      city
    }
    buildingStore.createBuilding(params?.uuid, newBuilding, callback)
  }
  const cancel = () => navigate(-1)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mb='1rem'>{t('building_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('building_name')}
              value={nameFi}
              onChange={setNameFi}
              mr='2rem'
            />
            <Input
              label={t('building_id')}
              value={buildingId}
              onChange={setBuildingId}
              maxWidth='16rem'
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('building_type')}
              options={getBuildingTypeOptions()}
              value={type}
              onChange={setType}
              maxWidth='16rem'
              mr='2rem'
            />
            <Select
              label={t('building_purpose')}
              options={getBuildingPurposeOptions()}
              value={buildingPurpose}
              onChange={buildingPurpose}
              maxWidth='20rem'
              mr='2rem'
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('floor_count')}
              options={getFloorCountOptions()}
              value={floorCount}
              onChange={setFloorCount}
              mr='2rem'
            />
            <Select
              label={t('apartment_count')}
              options={getApartmentCountOptions()}
              value={apartmentCount}
              onChange={setApartmentCount}
              mr='2rem'
            />
            <Input
              label={t('area')}
              value={areaSize}
              onChange={setAreaSize}
              maxWidth='10rem'
            />
          </Box>
          <Select
            label={t('building_year')}
            options={BuildingYearOptions}
            value={buildingYear}
            onChange={setBuildingYear}
            maxWidth='24rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('location')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('address')}
            value={address}
            onChange={setAddress}
            maxWidth='30rem'
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Input
              label={t('postal_code')}
              value={postalCode}
              onChange={setPostalCode}
              maxWidth='12rem'
              mr='2rem'
            />
            <Select
              label={t('city_or_municipality')}
              options={getCityOptions()}
              value={city}
              onChange={setCity}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_building')}
            onClick={create}
            width='100%'
            mb='1rem'
            disabled={isSendDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateBuilding)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '55rem',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
