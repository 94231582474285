import { Routes, Route, Navigate } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Dashboard from './Dashboard/Dashboard'
import Users from './Users/Users'
import CreateUser from './Users/CreateUser'
import User from './Users/User'
import EditUser from './Users/EditUser'
import Profile from './Profile/Profile'
import Support from './Support/Support'
import Properties from './Properties/Properties'
import PropertiesMap from './Properties/PropertiesMap'
import Property from './Properties/Property'
import CreateProperty from './Properties/CreateProperty'
import EditProperty from './Properties/EditProperty'
import CreateBuilding from './Buildings/CreateBuilding'
import Building from './Buildings/Building'
import EditBuilding from './Buildings/EditBuilding'
import CreateApartment from './Apartments/CreateApartment'
import Apartment from './Apartments/Apartment'
import EditApartment from './Apartments/EditApartment'
import CreateRoom from './Rooms/CreateRoom'
import Room from './Rooms/Room'
import EditRoom from './Rooms/EditRoom'
import Projects from './Projects/Projects'
import CreateProject from './Projects/CreateProject'
import EditProject from './Projects/EditProject'
import Costs from './Costs/Costs'
import CostSettings from './Costs/CostSettings'

export default function Authenticated() {
  return (
    <AppLayout>
      <Routes>
        <Route path='/dash' element={<Dashboard />} />
        <Route path='/users' element={<Users />} />
        <Route path='/users/create' element={<CreateUser />} />
        <Route path='/users/:uuid' element={<User />} />
        <Route path='/users/:uuid/edit' element={<EditUser />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/support' element={<Support />} />
        <Route path='/properties' element={<Properties />} />
        <Route path='/properties/map' element={<PropertiesMap />} />
        <Route path='/properties/:uuid' element={<Property />} />
        <Route path='/properties/create' element={<CreateProperty />} />
        <Route path='/properties/:uuid/edit' element={<EditProperty />} />
        <Route path='/properties/:uuid/buildings/create' element={<CreateBuilding />} />
        <Route path='/properties/:pUuid/buildings/:bUuid' element={<Building />} />
        <Route path='/properties/:pUuid/buildings/:bUuid/edit' element={<EditBuilding />} />
        <Route path='/properties/:pUuid/apartments/create' element={<CreateApartment />} />
        <Route path='/properties/:pUuid/apartments/:aUuid' element={<Apartment />} />
        <Route path='/properties/:pUuid/apartments/:aUuid/edit' element={<EditApartment />} />
        <Route path='/properties/:pUuid/apartments/:aUuid/rooms/create' element={<CreateRoom />} />
        <Route path='/properties/:pUuid/apartments/:aUuid/rooms/:rUuid' element={<Room />} />
        <Route path='/properties/:pUuid/apartments/:aUuid/rooms/:rUuid/edit' element={<EditRoom />} />
        <Route path='/properties/:uuid/projects' element={<Projects />} />
        <Route path='/properties/:uuid/projects/create' element={<CreateProject />} />
        <Route path='/properties/:pUuid/projects/:projUuid/edit' element={<EditProject />} />
        <Route path='/properties/:uuid/costs' element={<Costs />} />
        <Route path='/properties/:pUuid/cost-settings' element={<CostSettings />} />
        <Route path='*' element={<Navigate to ='/dash' />}/>
      </Routes>
    </AppLayout>
  )
}
