import { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import CostDevelopment from '../../../Components/Common/CostDevelopment'
import { Button, Select, MultiSelect } from '../../../Components'
import { CostTypes } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const Costs = (props: any) => {
  const { propertyStore, projectStore, costStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { property } = propertyStore
  const { loading, tab } = projectStore
  const { costSettings, costs } = costStore

  const [type, setType] = useState<string | null>(null)
  const [inflation, setInflation] = useState<string | null>(null)
  const [interest, setInterest] = useState<any>(null)

  useEffect(() => {
    if (tab === 'projects') {
      navigate(`/properties/${params.uuid}/projects`)
    }
  }, [tab])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
      projectStore.setTab('costs')
    }
  }, [])

  useEffect(() => {
    if (property) {
      costStore.getCosts(property.uuid)
    }
  }, [property])

  useEffect(() => {
    if (costs) {
    }
  }, [costs])

  const CostTypeOptions = Object.values(CostTypes).map((option: any) => ({ 
    value: option,
    label: t(option)
  }))
  const InflationOptions = [
    { value: '0-10000', label: '0 - 10 000 €' },
    { value: '10000-50000', label: '10 000 - 50 000 €' },
    { value: '50000-100000', label: '50 000 - 100 000 €' },
    { value: '100000-250000', label: '100 0000 - 250 000 €' },
    { value: '250000-500000', label: '250 000 - 500 000 €' },
    { value: '500000-1000000', label: '500 000 - 1 000 000 €' },
    { value: '1000000-100000000', label: '> 1 000 000 €' }
  ]
  const InterestOptions = [
    { value: '0-10000', label: '0 - 10 000 €' },
    { value: '10000-50000', label: '10 000 - 50 000 €' },
    { value: '50000-100000', label: '50 000 - 100 000 €' },
    { value: '100000-250000', label: '100 0000 - 250 000 €' },
    { value: '250000-500000', label: '250 000 - 500 000 €' },
    { value: '500000-1000000', label: '500 000 - 1 000 000 €' },
    { value: '1000000-100000000', label: '> 1 000 000 €' }
  ]

  const toSettings = () => navigate(`/properties/${params.uuid}/cost-settings`)

  const CostsChart = useMemo(() => {
    return (
      <CostDevelopment
        costs={costs}
        type='line'
      />
    )
  }, [costs, type, inflation, interest])

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.actions}>
        <Box sx={styles.row}>
          <MultiSelect
            options={CostTypeOptions}
            value={type}
            onChange={setType}
            placeholder={t('cost_type')}
            sx={styles.sortSelect}
            width='24rem'
            mr='1.5rem'
          />
        </Box>
        <Box sx={styles.actionsRow}>
          {/*
          <Select
            options={InflationOptions}
            value={inflation}
            onChange={setInflation}
            placeholder={t('inflation')}
            sx={styles.sortSelect}
            width='14rem'
            mr='1.5rem'
          />
          <Select
            options={InterestOptions}
            value={interest}
            onChange={setInterest}
            placeholder={t('interest')}
            sx={styles.sortSelect}
            width='14rem'
          />
          */}
          <Button
            text={t('settings')}
            onClick={toSettings}
            sx={styles.actionButton}
            width='10rem'
            ml='1.5rem'
          />
        </Box>
      </Box>
      <Box sx={styles.costsContainer}>
        {CostsChart}
      </Box>
    </Box>
  )
}

export default observer(Costs)

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '0.75rem',
    pb: '4rem',
    overflow: 'hidden'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    mb: '1.25rem',
    border: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  sortSelect: {
    width: {
      xxl: '20rem',
      xl: '18rem',
      xs: '14rem'
    }
  },
  actionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    }
  },
  costsContainer: {
    minHeight: 'calc(100vh - 13rem)',
    // maxHeight: 'calc(100vh - 13rem)',
    maxWidth: 'calc(100vw - 20rem)',
    // overflowY: 'auto',
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1.5rem',
    scrollBehavior: 'smooth'
  }
} as const
