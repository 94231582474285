import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SquareButton from '../../../Components/Common/SquareButton'
import Grid from '@mui/material/Unstable_Grid2'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import PopupButton from '../../../Components/Common/PopupButton'
import TrashIcon from '../../../Assets/Icons/bin.svg'
import { Button, Input, Select } from '../../../Components'
import { DefaultGraphColors, GraphScales, GraphTypes, GraphVisibilityOptions } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const CostSettings = (props: any) => {
  const { sessionStore, costStore }: any = useStore()
  const { me } = sessionStore
  const { loading, tab, costSettings } = costStore
  const { t } = useTranslation()

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [graphType, setGraphType] = useState('')
  const [graphScale, setGraphScale] = useState('')
  const [showCostTypeFilter, setShowCostTypeFilter] = useState(true)
  const [showInflationVariableFilter, setShowInflationVariableFilter] = useState(true)
  const [showLoanInterestFilter, setShowLoanInterestFilter] = useState(true)
  const [graphColors, setGraphColors] = useState<any>(null)
  const [managementCosts, setManagementCosts] = useState<any>([])
  const [propertyTaxCosts, setPropertyTaxCosts] = useState<any>([])
  const [loanCosts, setLoanCosts] = useState<any>([])
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    const pUuid = params?.pUuid || null
    if (pUuid && pUuid?.length === 36) {
      costStore.getCostSettings(pUuid)
      console.log('location', location)
      const queryParams: any = location?.search ?? null
      console.log('queryParams', queryParams)
      if (!queryParams || queryParams?.tab?.includes('tab=types')) {
        costStore.setTab('types')
      } else {
        costStore.setTab('graph')
      }
    }
  }, [])

  useEffect(() => {
    const pUuid = params?.pUuid || null
    if (pUuid && pUuid?.length === 36 && costSettings) {
      setGraphType(costSettings?.graphType || GraphTypes.Line)
      setGraphScale(costSettings?.graphScale || GraphScales.Default)
      setShowCostTypeFilter(costSettings?.showCostTypeFilter || true)
      setShowInflationVariableFilter(costSettings?.showInflationVariableFilter || true)
      setShowLoanInterestFilter(costSettings?.showLoanInterestFilter || true)
      setManagementCosts(costSettings?.managementCosts || [])
      setPropertyTaxCosts(costSettings?.propertyTaxCosts || [])
      setLoanCosts(costSettings?.loanCosts || [])
      if (costSettings?.graphColors) {
        let colors: any = DefaultGraphColors
        for (const color of Object.keys(costSettings?.graphColors)) {
          if (costSettings?.graphColors[color]?.[0] === '#' && costSettings?.graphColors[color].length === 7) {
            colors[color] = costSettings?.graphColors[color]
          }
        }
        setGraphColors(colors)
      } else {
        setGraphColors(DefaultGraphColors)
      }
    }
  }, [costSettings])

  console.log(costSettings)

  const GraphTypeOptions = Object.values(GraphTypes).map((option: any) => ({ 
    value: option,
    label: t(`${option}_graph`)
  }))
  const GraphScaleOptions = Object.values(GraphScales).map((option: any) => ({
    value: option,
    label: t(option)
  }))
  const VisibilityOptions = Object.values(GraphVisibilityOptions).map((option: any) => ({
    value: option === 'visible' ? true : false,
    label: t(option)
  }))

  // Last 10 years and next 10 years
  const CostYearOptions = [
    ...Array.from({ length: 10 }, (_, i) => DateTime.now().minus({ years: 10 - i }).toFormat('yyyy')),
    ...Array.from({ length: 10 }, (_, i) => DateTime.now().plus({ years: i }).toFormat('yyyy'))
  ]
  .map((year: string) => ({ value: year, label: year }))

  const isSaveDisabled = () => {
    if (!graphType) return true
    return false
  }

  const addManagementCost = () => {
    setManagementCosts([...managementCosts, { year: null, total: 0 }])
  }
  const addPropertyTaxCost = () => {
    setPropertyTaxCosts([...propertyTaxCosts, { year: null, total: 0 }])
  }
  const addLoanCost = () => {
    setLoanCosts([...loanCosts, { year: null, total: 0 }])
  }

  const removeCost = (type: string, index: number) => {
    if (type === 'management') {
      const newCosts = managementCosts.filter((cost: any, i: number) => i !== index)
      setManagementCosts(newCosts)
    } else if (type === 'propertyTax') {
      const newCosts = propertyTaxCosts.filter((cost: any, i: number) => i !== index)
      setPropertyTaxCosts(newCosts)
    } else if (type === 'loan') {
      const newCosts = loanCosts.filter((cost: any, i: number) => i !== index)
      setLoanCosts(newCosts)
    }
  }

  const updateCostYear = (type: string, value: string, index: number) => {
    if (type === 'management') {
      const newCosts = [...managementCosts]
      newCosts[index].year = value
      setManagementCosts(newCosts)
    } else if (type === 'propertyTax') {
      const newCosts = [...propertyTaxCosts]
      newCosts[index].year = value
      setPropertyTaxCosts(newCosts)
    } else if (type === 'loan') {
      const newCosts = [...loanCosts]
      newCosts[index].year = value
      setLoanCosts(newCosts)
    }
  }

  console.log('managementcosts', managementCosts)

  const updateCostTotal = (type: string, value: string, index: number) => {
    if (type === 'management') {
      const newCosts = [...managementCosts]
      newCosts[index].total = value
      setManagementCosts(newCosts)
    } else if (type === 'propertyTax') {
      const newCosts = [...propertyTaxCosts]
      newCosts[index].total = value
      setPropertyTaxCosts(newCosts)
    } else if (type === 'loan') {
      const newCosts = [...loanCosts]
      newCosts[index].total = value
      setLoanCosts(newCosts)
    }
  }

  const updateColor = (index: number, value: string) => {
    const newColors = [...graphColors]
    newColors[index] = value
    setGraphColors(newColors)
  }

  const save = () => {
    const updatedSettings = {
      graphType,
      graphScale,
      showCostTypeFilter,
      showInflationVariableFilter,
      showLoanInterestFilter,
      graphColors,
      managementCosts: managementCosts.filter((cost: any) => cost?.year && cost?.total),
      propertyTaxCosts: propertyTaxCosts.filter((cost: any) => cost?.year && cost?.total),
      loanCosts: loanCosts.filter((cost: any) => cost?.year && cost?.total)
    }
    costStore.updateCostSettings(params?.pUuid, updatedSettings)
  }

  const archive = () => {
    closeActionConfirmation()
  }

  const renderCosts = (type: string) => {
    const items = type === 'management' ?
      managementCosts :
      type === 'propertyTax' ?
      propertyTaxCosts :
      loanCosts

    return items.map((cost: any, index: number) => (
      <Box key={index} sx={styles.row}>
        <Select
          label={t('year')}
          options={CostYearOptions.filter((option: any) => !items.find((item: any) => item?.year && item.year === option.value))}
          value={cost?.year || ''}
          onChange={(value: any) => updateCostYear(type, value, index)}
          maxWidth='15rem'
          mr='1rem'
        />
        <Input
          unit='€'
          label={t('amount_of_yearly_costs')}
          value={cost?.total || 0}
          onChange={(value: any) => updateCostTotal(type, value, index)}
          mr='1rem'
        />
        <Box>
          <SquareButton
            icon={TrashIcon}
            onClick={() => removeCost(type, index)}
            size='3.125rem'
            iconSize='1.5rem'
            hoverColor={Colors.primary}
            mt='1.75rem'
          />
        </Box>
      </Box>
    ))
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_project_title')}
          content={t('archive_project_info')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archive}
          actionText={t('archive')}
        />
      )
    }
    return null
  }

  const renderTabContent = () => {
    if (tab === 'types') {
      return (
        <>
          <Typography variant='h2' mb='1rem'>{t('management')}</Typography>
          <Box sx={styles.formContainer}>
            {renderCosts('management')}
            <Button
              text={t('add_new')}
              onClick={addManagementCost}
              width='11rem'
              mb='1.5rem'
            />
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('property_tax')}</Typography>
          <Box sx={styles.formContainer}>
            {renderCosts('propertyTax')}
            <Button
              text={t('add_new')}
              onClick={addPropertyTaxCost}
              width='11rem'
              mb='1.5rem'
            />
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('loan')}</Typography>
          <Box sx={styles.formContainer}>
            {renderCosts('loan')}
            <Button
              text={t('add_new')}
              onClick={addLoanCost}
              width='11rem'
              mb='1.5rem'
            />
          </Box>
        </>
      )
    } else if (tab === 'graph') {
      return (
        <>
          <Typography variant='h2' mb='1rem'>{t('graph_options')}</Typography>
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Select
                label={t('graph_type')}
                options={GraphTypeOptions}
                value={graphType}
                onChange={setGraphType}
                mr='2rem'
              />
              <Select
                label={t('graph_scale_view_settings')}
                options={VisibilityOptions}
                value={graphScale}
                onChange={setGraphScale}
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('cost_type_selection')}
                options={VisibilityOptions}
                value={showCostTypeFilter}
                onChange={setShowCostTypeFilter}
                mr='2rem'
              />
              <Select
                label={t('inflation_variable')}
                options={VisibilityOptions}
                value={showInflationVariableFilter}
                onChange={setShowInflationVariableFilter}
                mr='2rem'
              />
              <Select
                label={t('loan_interest')}
                options={VisibilityOptions}
                value={showLoanInterestFilter}
                onChange={setShowLoanInterestFilter}
              />
            </Box>
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('graph_color_selections')}</Typography>
          <Box sx={styles.formContainer}>
            <Grid container spacing={4} mb='1.5rem'>
              {graphColors?.length && graphColors.map((value: string, index: number) => (
                <Grid xs={6} md={3} key={index}>
                  <Input
                    label={`${t('color')} ${index + 1}`}
                    value={value}
                    onChange={(val: string) => updateColor(index, val)}
                    colorPicker
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{renderTabContent()}</Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={isSaveDisabled()}
          />
        </Box>
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(CostSettings)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '55rem',
    overflowY: 'auto',
  },
  title: {
    borderBottom: `5px solid ${Colors.border}`,
    pb: '1rem',
    mb: '2rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem',
    overflow: 'hidden'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  divider: {
    margin: '0rem 0.75rem',
    marginTop: '1.625rem',
    fontSize: '1.5rem',
    fontWeight: 700,
    color: Colors.text
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  popup: {
    ml: '0.875rem'
  }
} as const
