import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormSection from './FormSection'
import FormComponent from './FormComponent'
import { ComponentExtraProperties } from '../../Utils/layout'

export default function CustomForm(props: any) {
  const { t } = useTranslation()

  const { section, values, isLast } = props

  const getIsDisabled = (
    extraProperties: any,
    parentItemKey: string
  ) => {
    if (extraProperties && extraProperties?.length && parentItemKey) {
      if (extraProperties?.includes(ComponentExtraProperties.DisabledIfNoParentValue)) {
        return !values?.[parentItemKey] || values?.[parentItemKey] === false || values?.[parentItemKey] === 'no'
      }
    }
    return false
  }

  // console.log('LAST_UPDATED_VALUE', props.lastUpdatedValue)

  const onChange = (key: string, value: any) => {
    props.onChange(section.category, key, value)
  }

  const renderSubComponents = (parentItemKey: string, items: any) => {
    return items.map((item: any, index: number) => {
      return (
        <FormComponent
          key={`${parentItemKey}-${item?.value}-${index}`}
          item={item}
          value={values?.[item?.value] || null}
          onChange={onChange}
          mb={index === items.length - 1 ? '1.125rem' : '0.5rem'}
          lastUpdatedValue={props.lastUpdatedValue}
          shouldUpdate={
            (props.lastUpdatedValue?.includes(`${section.category}_${item?.value}`) &&
            (values?.[item?.value] || values?.[item?.value] === false)) ||
            (props.lastUpdatedValue?.includes(`${section.category}_${parentItemKey}`) &&
            values?.[parentItemKey])
          }
          disabled={getIsDisabled(item?.extraProperties, parentItemKey)}
        />
      )
    })
  }

  const renderComponents = () => {
    return (section?.items || []).map((item: any, index: number) => {
      return (
        <>
          <FormComponent
            key={`${section?.category}-${item?.value}-${index}`}
            item={item}
            value={values?.[item?.value] || null}
            onChange={onChange}
            shouldUpdate={props.lastUpdatedValue?.includes(`${section.category}_${item?.value}`) && (values?.[item?.value] || values?.[item?.value] === false)}
            isLast={index === section?.items?.length - 1}
          />
          {renderSubComponents(item?.value, item?.items || [])}
        </>
      )
    })
  }

  const renderForm = () => {
    return (
      <FormSection
        title={section?.value ? t(section.value) : ''}
        position={section?.position}
        isLast={isLast}
        minHeight={section?.minHeight}
      >
        {renderComponents()}
      </FormSection>
    )
  }

  return renderForm()
}

const styles = {
} as const
