import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Input, Button } from '../../../Components'
import VerifiedIcon from '../../../Assets/Icons/verified.svg'
import { Colors } from '../../../Utils/theme'

const ForgotPassword = (props: any) => {
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()

  const [email, setEmail] = useState('')
  const [requestSent, setRequestSent] = useState(false)

  const clearEmail = () => setEmail('')

  const handleKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && email) {
      send()
    }
  }
  const send = () => {
    const callback = () => {
      clearEmail()
      setRequestSent(true)
    }
    sessionStore.requestPasswordReset(email, callback)
  }

  const renderContent = () => {
    if (requestSent) {
      return (
        <>
          <Typography variant='h1' sx={styles.title}>{t('password_reset_request_sent')}</Typography>
          <Box component='img' src={VerifiedIcon} alt='verified' />
          <Typography variant='body1' sx={styles.info} mt='3rem'>{t('password_reset_request_sent_info')}</Typography>
        </>
      )
    }
    return (
      <>
        <Typography variant='h1' sx={styles.title}>{t('forgot_password')}</Typography>
        <Typography variant='body1' sx={styles.info}>{t('forgot_password_info')}</Typography>
        <Input
          placeholder={t('email')}
          value={email}
          onChange={setEmail}
          onAction={clearEmail}
          handleKeyPress={handleKeyPress}
          auth
        />
        <Button
          sx={styles.button}
          text={t('send')}
          onClick={send}
          disabled={!email || !email.includes('@') || !email.includes('.')}
        />
      </>
    )
  }

  return (
    <Box sx={styles.container}>
      {renderContent()}
    </Box>
  )
}

export default observer(ForgotPassword)

const styles = {
  container: {
    width: {
      sm: '40rem',
      xs: '100%'
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    color: Colors.white,
    textAlign: 'center',
    mb: '2.5rem'
  },
  info: {
    fontSize: '1rem',
    color: Colors.white90,
    marginBottom: '2.75rem',
    textAlign: 'center'
  },
  button: {
    height: '4rem',
    width: '100%',
    backgroundColor: Colors.heading,
    mt: '1.25rem',
    ':disabled': {
      backgroundColor: Colors.heading60,
      color: Colors.white90
    }
  }
} as const

