import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomFormV2'
import { BuildingPartsLayout, BuildingSections } from '../../../Utils/building'
import { ComponentTypes } from '../../../Utils/layout'

const BuildingParts = (props: any) => {
  const { sessionStore, buildingStore }: any = useStore()
  const { me } = sessionStore
  const { building }  = buildingStore
  const { t } = useTranslation()
  
  const window: any = useRef({})
  const door: any = useRef({})
  const balcony: any = useRef({})
  const terrace: any = useRef({})
  
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  const [openSections, setOpenSections] = useState([
    ...BuildingPartsLayout.map((section: any) => section.title)
  ])

  useEffect(() => {
    if (building) {
      for (const [key, value] of Object.entries(building?.window || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          window.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.door || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          door.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.balcony || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          balcony.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.terrace || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          terrace.current[key] = value
        }
      }
      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [building])

  const onSave = () => {
    const updatedBuilding = {
      window: window.current,
      door: door.current,
      balcony: balcony.current,
      terrace: terrace.current,
    }
    buildingStore.updateBuildingParts(props?.uuid, updatedBuilding)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    []
  )

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const onChange = (category: string, key: string, value: any) => {
    if (category === BuildingSections.Window) {
      window.current = { ...(window?.current || {}), [key]: value }
    } else if (category === BuildingSections.Door) {
      door.current = { ...(door?.current || {}), [key]: value }
    } else if (category === BuildingSections.Balcony) {
      balcony.current = { ...(balcony?.current || {}), [key]: value }
    } else if (category === BuildingSections.Terrace) {
      terrace.current = { ...(terrace?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${key}_${value}`)
  }

  const getValues = useCallback((category: string) => {
    if (category === BuildingSections.Window) {
      return window?.current || {}
    } else if (category === BuildingSections.Door) {
      return door?.current || {}
    } else if (category === BuildingSections.Balcony) {
      return balcony?.current || {}
    } else if (category === BuildingSections.Terrace) {
      return terrace?.current || {}
    }
  }, [window, door, balcony, terrace])

  const renderCustomForm = (sections: any, title: string, index: number) => {
    if (sections?.length) {
      // Extract values from the current category
      const values = getValues(title)
      // Filter out items that should not be visible
      const items = sections.map((section: any) => {
        if (!section?.visibleIfValues && !section?.items && !section?.items?.find((item: any) => item?.visibleIfValues)) {
          // If no visibleIfValues are defined, show the item
          return section
        }
        if (!section?.visibleIfValues) {
          if (section?.items) {
            return {
              ...section,
              // Filter out items that should not be visible
              items: section?.items?.filter((item: any) => {
                if (!item?.visibleIfValues) {
                  return true
                }
                return item?.visibleIfValues?.every((visibleIfValue: any) => {
                  // const values = getValues(visibleIfValue.key?.split('_')[0])
                  return visibleIfValue.values?.includes(values[visibleIfValue.key])
                })
              })
            }
          }
        }
        // Check if all visibleIfValues are met
        const conditionsMet = section?.visibleIfValues?.every((visibleIfValue: any) => {
          // const values = getValues(visibleIfValue.key?.split('_')[0])
          return visibleIfValue.values?.includes(values[visibleIfValue.key])
        })
        if (conditionsMet) {
          return section
        }
        return null
      }).filter((item: any) => item)

      if (items?.length === 1 && items[0]?.type === ComponentTypes.Subtitle) {
        return null
      }
      if (!items?.length) {
        return null
      }
      return (
        <CustomForm
          key={`form-${title}-${index}`}
          section={items}
          values={values}
          onChange={(key: string, value: string) => onChange(title, key, value)}
          lastUpdatedValue={lastUpdatedValue}
        />
      )
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title: string): any => {
    return sections.map((section: any, index: number) => renderCustomForm(section, title, index))
  }

  const BuildingSectionsLayout = useMemo(() => {
    return BuildingPartsLayout.map((layout: any) => {
      if (!layout?.sections?.length) {
        return null
      }
      return (
        <CollapseSection
          key={`section-${layout.title}`}
          title={t(layout?.label ?? layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections, layout.title)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue])

  // Do not show anything if params are missing
  const uuid = props?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{BuildingSectionsLayout}</Box>
    </Box>
  )
}

export default observer(BuildingParts)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  },
  loading: {
    margin: 'auto'
  }
} as const
