import React, { useEffect, useState, useMemo } from 'react'
import { DateTime } from 'luxon'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from './Button'
import Checkbox from './Checkbox'
import Input from './Input'
import Select from './Select'
import MultiSelect from './MultiSelect'
import { ComponentExtraStyles, ComponentTypes } from '../../Utils/layout'
import { Colors } from '../../Utils/theme'

export default function FormComponent(props: any) {
  const { t } = useTranslation()

  const { item, value, onChange, mb, disabled } = props
  const label = item?.key || item?.value

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value)
    }
  }, [value])

  const handleInputChange = (value: any) => setLocalValue(value)
  const handleDateInputChange = (value: any) => onChange(label, value)
  const handleSelectChange = (value: any) => onChange(label, value)
  const handleMultiSelectChange = (value: any) => onChange(label, value)
  const handleBooleanChange = (label: string, newValue: any) => {
    // console.log('trying to update', label, newValue)
    // console.log('current value', value)
    if (!item?.options?.length) {
      onChange(label, !value ? true : false)
    } else {
      // console.log(newValue)
      if (newValue === value) {
        onChange(label, null)
      } else {
        onChange(label, newValue)
      }
    }
  }

  const handleNumberChange = (value: any) => {
    value = value.trim()

    if (value === '') {
      onChange(label, null)
    }
    else if (value === `${Number(value)}`) {
      onChange(label, Number(value))
    } else {
      // Not a valid number, still typing so use string value
      onChange(label, value)
    }
  }

  const handleValueUpdate = () => {
    if (localValue !== value) {
      onChange(label, localValue)
    }
  }

  const getComponentExtraStyles = (subItem?: any) => {
    let extraStyles: any = {}
    const currentItem = subItem || item
    if (currentItem?.extraStyles) {
      for (const style of currentItem?.extraStyles) {
        if (style === ComponentExtraStyles.Indented) {
          extraStyles.marginLeft = '1.875rem'
          extraStyles.width = 'calc(100% - 1.875rem)'
        }
        if (style === ComponentExtraStyles.Row) {
          extraStyles.display = 'flex'
          extraStyles.flexDirection = 'row'
          extraStyles.alignItems = 'center'
          extraStyles.width = '100%'
        }
      }
    }
    return extraStyles
  }

  const renderSubtitle = () => {
    return (
      <Typography variant='h5' sx={styles.subtitle} mt={props?.isFirst ? 0 : '1rem'}>
        {item?.rawLabel ?? t(item?.label)}
      </Typography>
    )
  }

  const renderInput = () => {
    return (
      <Input
        label={item?.label ? `${t(item?.label)}*` : null}
        placeholder={item?.placeholder ? t(item?.placeholder) : null}
        value={localValue || ''}
        onChange={handleInputChange}
        onBlur={handleValueUpdate}
        disabled={disabled}
        sx={getComponentExtraStyles()}
        mb={mb || (props?.isLast ? '0rem' : '1.25rem')}
        mr={props?.mr || 0}
      />
    )
  }

  const renderMultilineInput = () => {
    return (
      <Input
        label={t(item?.label || label)}
        placeholder={item?.placeholder ? t(item?.placeholder) : null}
        value={localValue || ''}
        onChange={handleInputChange}
        onBlur={handleValueUpdate}
        disabled={disabled}
        mb={mb}
        multiline
      />
    )
  }

  const renderNumberInput = () => {
    return (
      <Input
        label={t(item?.label || label)}
        value={localValue || ''}
        onChange={handleNumberChange}
        onBlur={handleValueUpdate}
        disabled={disabled}
        type='number'
        mb={mb}
      />
    )
  }

  const renderWysiwyg = () => {
    return null
  }

  const renderBooleanCheckboxSubItems = (item: any) => {
    if (!item?.options || !item?.options?.length) {
      return null
    }

    let options = item?.options?.map((item: any) => {
      return {
        value: item.value,
        label: item?.rawLabel ? item?.label : t(item.label)
      }
    })

    return (
      <Box sx={{ ...styles.booleanContainer, ...getComponentExtraStyles() }}>
        {options.map((option: any, index: number) => {
          // console.log(label, value, value === false, value === option?.value)
          return (
            <>
              <Checkbox
                key={index}
                label={option?.label || ''}
                checked={(value || value === false) && value === option?.value}
                onChange={() => handleBooleanChange(label, option.value)}
                disabled={disabled}
                mb={((props?.isLast && index === options?.length - 1)) ? '0rem' : '0.75rem'}
                mr={item?.extraStyles ? '1rem' : '0rem'}
                ml={item?.extraStyles ? '1rem' : '0rem'}
              />
            </>
          )
        })}
      </Box>
    )
  }

  const renderBooleanCheckbox = () => {
    let options = [{ label: t(item?.label || label), value: value || false }]
    if (item?.options?.length) {
      options = item.options.map((item: any) => {
        return {
          value: item.value,
          label: item?.rawLabel ? item?.label : t(item.label),
          items: item?.items || []
        }
      })
    }
    return (
      <Box sx={{ ...styles.booleanContainer, ...getComponentExtraStyles() }}>
        {options.map((option: any, index) => {
          // console.log(label, value, value === false, value === option?.value)
          return (
            <>
              <Checkbox
                key={index}
                label={option?.label || ''}
                checked={(value || value === false) && value === option?.value}
                onChange={() => handleBooleanChange(label, option.value)}
                disabled={disabled}
                mb={(((props?.isLast && index === options?.length - 1) || item?.extraStyles?.includes('row')) && !item?.extraStyles?.includes('allowMargin')) ? '0rem' : '0.75rem'}
                mr={item?.extraStyles ? '1rem' : '0rem'}
                unset={item?.unset || false}
              />
              {option?.items?.length ? renderBooleanCheckboxSubItems(option?.items?.[0]) : null}
            </>
          )
        })}
      </Box>
    )
  }

  const renderSelect = () => {
    const options = (item?.options || []).map((item: any) => {
      return {
        value: item.value,
        label: item?.rawLabel ? item.label : t(item.label)
      }
    })
    
    return (
      <Select
        label={`${t(item?.label || label)}*`}
        placeholder={item?.hidePlaceholder ? null : item?.placeholder ? t(item.placeholder) : t('select')}
        options={options}
        value={value}
        onChange={handleSelectChange}
        disabled={disabled}
        sx={getComponentExtraStyles()}
        mt={item?.mt || 0}
        mb={label ? '1.25rem' : '1.125rem'}
        mr={props?.mr || 0}
      />
    )
  }

  const renderMultiSelect = (subItem?: any) => {
    if (!item?.options || !item?.options?.length) {
      return null
    }
    const options = (item?.options || []).map((item: any) => {
      return {
        value: item.value,
        label: item?.rawLabel ? item.label : t(item.label)
      }
    })
    
    return (
      <MultiSelect
        label={t(item?.label || label)}
        placeholder={item?.hidePlaceholder ? null : item?.placeholder ? t(item.placeholder) : t('select')}
        options={options}
        value={value}
        onChange={handleMultiSelectChange}
        disabled={item?.disabled}
        sx={getComponentExtraStyles()}
        mt={item?.mt || 0}
        mb={label ? '1.25rem' : '1.125rem'}
        mr={props?.mr || 0}
      />
    )
  }

  const renderNumberSelect = () => {
    const options = (Array.from({ length: item?.maxCount || 10 }, (_, i) => i + 1))
      .map((item: any) => ({ value: item, label: `${item}` }))

    return (
      <Select
        label={item?.placeholder ? (item?.label ? t(item?.label) : null) : null}
        placeholder={item?.placeholder ? t(item?.placeholder) : null}
        options={options}
        value={value}
        onChange={handleSelectChange}
        disabled={disabled}
        sx={getComponentExtraStyles()}
        mt={item?.mt || 0}
        mb={label ? '1.25rem' : '1.125rem'}
        mr={props?.mr || 0}
        number
      />
    )
  }

  const renderDateInput = () => {
    return (
      <Input
        label={t(item?.label || label)}
        value={value}
        onChange={handleDateInputChange}
        disabled={disabled}
        type='date'
        sx={getComponentExtraStyles()}
        mt={item?.mt || 0}
        mb={label ? '1.25rem' : '1.125rem'}
        mr={props?.mr || 0}
      />
    )
  }

  const renderTimeInput = () => (
    <Input
      label={t(label)}
      value={value}
      onChange={handleInputChange}
      disabled={disabled}
      type='time'
      mt={item?.mt || 0}
      mb={label ? '1.25rem' : '1.125rem'}
      mr={props?.mr || 0}
    />
  )

  const Content = useMemo(() => {
    const type = item?.type || ''

    if (type === ComponentTypes.Subtitle) {
      return renderSubtitle()
    } else if (type === ComponentTypes.BooleanCheckbox) {
      return renderBooleanCheckbox()
    } else if (type === ComponentTypes.Input) {
      return renderInput()
    } else if (type === ComponentTypes.MultilineInput) {
      return renderMultilineInput()
    } else if (type === ComponentTypes.NumberInput) {
      return renderNumberInput()
    } else if (type === ComponentTypes.Select) {
      return renderSelect()
    } else if (type === ComponentTypes.MultiSelect) {
      return renderMultiSelect()
    } else if (type === ComponentTypes.NumberSelect) {
      return renderNumberSelect()
    } else if (type === ComponentTypes.DateInput) {
      return renderDateInput()
    } else if (type === ComponentTypes.TimeInput) {
      return renderTimeInput()
    }

    return null
  }, [props.shouldUpdate, localValue])

  return Content
}

const styles = {
  verticalSpacer: {
    height: '1rem'
  },
  horizontalSpacer: {
    width: '1rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  checkboxTitle: {
  },
  booleanContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  multiSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  multiSelectContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  multiSelectItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  checkboxSpacer: {
    width: '1rem'
  },
  inputTitle: {
    marginBottom: '0.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  }
} as const