import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import FileUpload from '../../../Components/Common/FileUpload'
import File from '../../../Components/Common/File'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import { Button, CoverImage, Tabs, Tab, Chip, Table } from '../../../Components'
import { BuildingTabs } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'

const Building = (props: any) => {
  const { sessionStore, buildingStore, apartmentStore }: any = useStore()
  const { me } = sessionStore
  const { building, loading } = buildingStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [tab, setTab] = useState(BuildingTabs.GeneralInformation)
  const [selectedImage, setSelectedImage] = useState(false)

  const clearSelectedImage = () => setSelectedImage(false)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    if (pUuid && bUuid && pUuid?.length === 36 && bUuid?.length === 36) {
      buildingStore.getBuilding(pUuid, bUuid)
    }
  }, [])

  useEffect(() => {
    if (tab === BuildingTabs.Attachments) {
      buildingStore.getBuildingFiles(params?.pUuid)
    } else if (tab === BuildingTabs.Reminders) {
      // buildingStore.getBuildingReminders()
    }
  }, [tab])

  useEffect(() => {
    if (building) {
      apartmentStore.getApartments(params.pUuid, params.bUuid)
    }
  }, [building])

  if (
      !building ||
      !params.pUuid ||
      !params.bUuid ||
      params.pUuid?.length !== 36 ||
      params.bUuid?.length !== 36
  ) {
    return null
  }

  const updateBuildingBackgroundPhoto = (file: any) => {
    buildingStore.updateBuildingBackgroundPhoto(params?.pUuid, file?.uuid)
  }

  const downloadFile = async (file: any) => {
    // TODO: image download
    window.open(file?.url, '_blank')
  }

  const archiveFile = (file: any) => buildingStore.archiveBuildingFile(params?.pUuid, file?.uuid)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === building?.backgroundPhoto) {
        return [
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updateBuildingBackgroundPhoto },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const getBuildingMenuOptions = () => {
    return [
      { text: t('edit_building'), onClick: toEditBuilding }
    ]
  }

  const toEditBuilding = () => navigate(`${location.pathname}/edit`)
  const uploadFiles = (files: any) => {
    buildingStore.uploadBuildingFiles(params?.pUuid, files)
  }
  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const viewApartmentPhotos = () => {
  }

  const toCreateApartment = () => navigate(`/properties/${params?.pUuid}/apartments/create`)

  const toApartment = (apartment: any) => navigate(`/properties/${params?.pUuid}/apartments/${apartment.uuid}`)

  const getColumns = () => {
    return [
      {
        field: 'nameFi',
        headerName: t('apartment'),
        flex: 1,
        minWidth: 200,
        headerClassName: 'kiinteistomuisti-table--header',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Button
            sx={styles.name}
            text={params.row.nameFi}
            onClick={() => toApartment(params.row)}
            variant='text'
          />
        )
      },
      {
        field: 'address',
        headerName: t('address'),
        flex: 1,
        headerClassName: 'kiinteistomuisti-table--header',
        disableColumnMenu: true,
        sortable: false,
        valueFormatter: (value: string) => value || '-'
      },
      {
        field: 'type',
        headerName: t('type'),
        flex: 1,
        headerClassName: 'kiinteistomuisti-table--header',
        disableColumnMenu: true,
        valueFormatter: (value: string) => value ? t(value) : '-'
      },
      {
        field: 'updatedAt',
        headerName: t('edited_at'),
        flex: 1,
        maxWidth: 320,
        headerClassName: 'kiinteistomuisti-table--header',
        disableColumnMenu: true,
        valueFormatter: (value: string) => {
          if (value) {
            const datetime = DateTime.fromISO(value)
            return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
          }
          return '-'
        }
      }
    ]
  }

  const getRows = () => {
    return (apartmentStore?.apartments || [])
      .filter((apartment: any) => apartment?.buildingId === building?.id)
  }

  const renderAttachments = () => {
    return buildingStore.files.map((file: any) => (
      <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
        <File
          key={file.uuid}
          file={file}
          menuOptions={getFileActionOptions(file)}
          onFileClick={onFileClick}
        />
      </Grid>
    ))
  }

  const renderTabContent = () => {
    if (tab === BuildingTabs.GeneralInformation) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('building_information')}</Typography>
          <Box sx={styles.row}>
            <Box sx={styles.column} mr='2rem'>
              <Box sx={styles.infoContainer} mr='2rem'>
                <Typography variant='body1' sx={styles.infoLabel}>{t('purpose')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {building?.buildingPurpose ? t(building.buildingPurpose) : '-'}
                </Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('building_year')}</Typography>
                <Typography variant='body1' mb='1rem'>{building?.buildingYear || '-'}</Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('building_id')}</Typography>
                <Typography variant='body1' mb='0.5rem'>{building?.buildingId || '-'}</Typography>
              </Box>
            </Box>
            <Box sx={styles.column}>
              <Box sx={styles.infoContainer}>
                <Typography variant='body1' sx={styles.infoLabel}>{t('area')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {building?.areaSize || ''} m<sup>2</sup>
                </Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('floor_count')}</Typography>
                <Typography variant='body1' mb='1rem'>{building?.floorCount || '-'}</Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('apartment_count')}</Typography>
                <Typography variant='body1' mb='0.5rem'>{building?.apartmentCount || '-'}</Typography>
              </Box>
            </Box>
          </Box>
          <TableActions
            title={t('apartments')}
            onPhotosClick={viewApartmentPhotos}
            onAddClick={toCreateApartment}
          />
          <Table
            rows={getRows()}
            columns={getColumns()}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === BuildingTabs.Attachments) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ xs: 0, sm: 2, md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
            mt='1.5rem'
          >
            <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
              <FileUpload onUploadFiles={uploadFiles} loading={loading} />
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === BuildingTabs.Reminders) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('reminders')}</Typography>
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (buildingStore?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            buildingStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            buildingStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage bgPhotoUrl={building?.backgroundPhoto} />
      <Typography variant='h2' textTransform='uppercase' mt='1rem'>
        {building?.nameFi || '-'}
      </Typography>
      <Box sx={styles.infoRow}>
        <Chip text={building?.property?.nameFi || ''} />
        <Box sx={styles.dot} />
        <Typography variant='body1'>{building?.address || '-'}</Typography>
      </Box>
      <Tabs onEditClick={toEditBuilding} menuOptions={getBuildingMenuOptions()}>
        <Tab
          value={BuildingTabs.GeneralInformation}
          text={t(BuildingTabs.GeneralInformation)}
          selected={tab === BuildingTabs.GeneralInformation}
          onClick={setTab}
          first
        />
        <Tab
          value={BuildingTabs.Attachments}
          text={t(BuildingTabs.Attachments)}
          selected={tab === BuildingTabs.Attachments}
          onClick={setTab}
          count={0}
        />
        <Tab
          value={BuildingTabs.Reminders}
          text={t(BuildingTabs.Reminders)}
          selected={tab === BuildingTabs.Reminders}
          onClick={setTab}
          count={0}
        />
      </Tabs>
      {renderTabContent()}
      {renderLightbox()}
    </Box>
  )
}

export default observer(Building)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '60rem',
    marginBottom: '3rem'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    // width: 'calc(50% - 1rem)',
    // maxWidth: '30rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  }
} as const
