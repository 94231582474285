import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@mui/material/ButtonBase'
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'
import { Colors } from '../../Utils/theme'
import { showMessage } from '../../Utils/message'

const CustomChip = React.forwardRef((props: any, ref: any) => {
  const { text, copy } = props

  const { t } = useTranslation()

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(text)
    showMessage(t('copied_to_clipboard'))
  }
  const onClick = () => props.onClick()

  const renderChip = () => {
    if (props?.href) {
      const href = props?.email ? `mailto:${text}` : props?.phone ? `tel:${text}` : props.href
      return (
        <Link href={href}>
          <Chip
            label={text || '-'}
            variant='outlined'
            size='small'
            sx={props?.sx ? { ...styles.chipButton, ...props.sx } : styles.chipButton}
          />
        </Link>
      )
    }
    if (props?.onClick || copy) {
      return (
        <ButtonBase
          sx={props?.buttonSx || {}}
          onClick={copy ? onCopyToClipboard : onClick}
        >
          <Chip
            label={props?.text || '-'}
            variant='outlined'
            size='small'
            sx={props?.sx ? { ...styles.chipButton, ...props.sx } : styles.chipButton}
          />
        </ButtonBase>
      )
    }
    return (
      <Chip
        label={text || '-'}
        variant='outlined'
        size='small'
        sx={props?.sx ? { ...styles.chip, ...props.sx } : styles.chip}
      />
    )
  }
  return renderChip()
})

export default CustomChip

const styles = {
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.heading,
    borderRadius: '0.3125rem',
    border: 0,
    padding: '0rem 0.125rem'
  },
  chipButton: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.white,
    backgroundColor: Colors.heading,
    borderRadius: '0.3125rem',
    border: 0,
    padding: '0rem 0.125rem',
    '&:hover': {
      backgroundColor: Colors.heading90,
      fontWeight: 600
    }
  }
}