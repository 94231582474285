import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import { AreaLayout, AreaSections } from '../../../Utils/area'
import { FormTypes } from '../../../Utils/layout'
import { Colors } from '../../../Utils/theme'

const Areas = (props: any) => {
  const { sessionStore, areaStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  
  const accessAndDriveways: any = useRef({})
  const waterDrainage: any = useRef({})
  const lawnAreas: any = useRef({})
  const plantings: any = useRef({})
  const buildingSides: any = useRef({})
  const parkingAreas: any = useRef({})
  const playAreas: any = useRef({})
  const equipmentAndFixtures: any = useRef({})
  const yardDeck: any = useRef({})
  const sideBuildings: any = useRef({})
  const wasteManagement: any = useRef({})
  
  const [hiddenItems, setHiddenItems] = useState<any>([])

  // Collapse states
  const [openSections, setOpenSections] = useState([
    ...AreaLayout.map((section: any) => section.title)
  ])

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  useEffect(() => {
    const uuid = props?.uuid || null
    if (uuid) {
      areaStore.getAreas(uuid)
    }
  }, [props.uuid])

  useEffect(() => {
    if (areaStore.areas) {
      accessAndDriveways.current = areaStore?.areas?.accessAndDriveways || {}
      waterDrainage.current = areaStore?.areas?.waterDrainage || {}
      lawnAreas.current = areaStore?.areas?.lawnAreas || {}
      plantings.current = areaStore?.areas?.plantings || {}
      buildingSides.current = areaStore?.areas?.buildingSides || {}
      parkingAreas.current = areaStore?.areas?.parkingAreas || {}
      playAreas.current = areaStore?.areas?.playAreas || {}
      equipmentAndFixtures.current = areaStore?.areas?.equipmentAndFixtures || {}
      yardDeck.current = areaStore?.areas?.yardDeck || {}
      sideBuildings.current = areaStore?.areas?.sideBuildings || {}
      wasteManagement.current = areaStore?.areas?.wasteManagement || {}
      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [areaStore.areas])

  const onSave = () => {
    const updatedAreas = {
      accessAndDriveways: accessAndDriveways.current,
      waterDrainage: waterDrainage.current,
      lawnAreas: lawnAreas.current,
      plantings: plantings.current,
      buildingSides: buildingSides.current,
      parkingAreas: parkingAreas.current,
      playAreas: playAreas.current,
      equipmentAndFixtures: equipmentAndFixtures.current,
      yardDeck: yardDeck.current,
      sideBuildings: sideBuildings.current,
      wasteManagement: wasteManagement.current,
      hiddenItems: hiddenItems.join(',')
    }
    areaStore.updateAreas(props?.uuid, updatedAreas)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    []
  )

  const onChange = (category: string, key: string, value: any) => {
    if (category === AreaSections.AccessAndDriveways) {
      accessAndDriveways.current = { ...(accessAndDriveways?.current || {}), [key]: value }
    } else if (category === AreaSections.WaterDrainage) {
      waterDrainage.current = { ...(waterDrainage?.current || {}), [key]: value }
    } else if (category === AreaSections.LawnAreas) {
      lawnAreas.current = { ...(lawnAreas?.current || {}), [key]: value }
    } else if (category === AreaSections.Plantings) {
      plantings.current = { ...(plantings?.current || {}), [key]: value }
    } else if (category === AreaSections.BuildingSides) {
      buildingSides.current = { ...(buildingSides?.current || {}), [key]: value }
    } else if (category === AreaSections.ParkingAreas) {
      parkingAreas.current = { ...(parkingAreas?.current || {}), [key]: value }
    } else if (category === AreaSections.PlayAreas) {
      playAreas.current = { ...(playAreas?.current || {}), [key]: value }
    } else if (category === AreaSections.EquipmentAndFixtures) {
      equipmentAndFixtures.current = { ...(equipmentAndFixtures?.current || {}), [key]: value }
    } else if (category === AreaSections.YardDeck) {
      yardDeck.current = { ...(yardDeck?.current || {}), [key]: value }
    } else if (category === AreaSections.SideBuildings) {
      sideBuildings.current = { ...(sideBuildings?.current || {}), [key]: value }
    } else if (category === AreaSections.WasteManagement) {
      wasteManagement.current = { ...(wasteManagement?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${category}_${key}_${value}`)
  }

  const getValues = useCallback((category: string) => {
    if (category === AreaSections.AccessAndDriveways) {
      return accessAndDriveways.current
    } else if (category === AreaSections.WaterDrainage) {
      return waterDrainage.current
    } else if (category === AreaSections.LawnAreas) {
      return lawnAreas.current
    } else if (category === AreaSections.Plantings) {
      return plantings.current
    } else if (category === AreaSections.BuildingSides) {
      return buildingSides.current
    } else if (category === AreaSections.ParkingAreas) {
      return parkingAreas.current
    } else if (category === AreaSections.PlayAreas) {
      return playAreas.current
    } else if (category === AreaSections.EquipmentAndFixtures) {
      return equipmentAndFixtures.current
    } else if (category === AreaSections.YardDeck) {
      return yardDeck.current
    } else if (category === AreaSections.SideBuildings) {
      return sideBuildings.current
    } else if (category === AreaSections.WasteManagement) {
      return wasteManagement.current
    }
    return {}
  }, [accessAndDriveways, waterDrainage, lawnAreas, plantings, buildingSides, parkingAreas, playAreas, equipmentAndFixtures, yardDeck, sideBuildings, wasteManagement])

  const renderCustomForm = (sections: any) => {
    if (sections?.length) {
      return sections.map((section: any, index: number) => {
        return (
          <CustomForm
            key={section?.value || index}
            section={section}
            values={getValues(section.category)}
            onChange={onChange}
            lastUpdatedValue={lastUpdatedValue}
            isLast={index === sections.length - 1}
          />
        )
      })
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title?: string): any => {
    // Check if is an array of sections
    if (Array.isArray(sections[0])) {
      return sections.map((section: any) => renderCustomFormContainer(section[0]))
    }
    if (sections?.type === FormTypes.SectionSubsections) {
      return (
        <>
          {
            sections?.title && (
              <Typography variant='h4' mt='0.75rem' mb='1.25rem' color={Colors.primary}>
                {t(sections.title)}
              </Typography>
            )
          }
          {sections?.items.map((item: any) => renderCustomFormContainer(item.items, item.value))}
        </>
      )
    } else {
      const leftColumnSections = sections.filter((section: any) => section?.position === 'left')
      const rightColumnSections = sections.filter((section: any) => section?.position === 'right')
      return (
        <Box sx={styles.customFormOuterContainer}>
          {
            title && (
              <Typography variant='h5' mb='1.25rem' textTransform='uppercase'>
                {t(title)}
              </Typography>
            )
          }
          <Grid container columns={12} sx={styles.customFormContainer}>
            <Grid xs={12} sm={6}>{renderCustomForm(leftColumnSections)}</Grid>
            <Grid xs={12} sm={6}>{renderCustomForm(rightColumnSections)}</Grid>
          </Grid>
        </Box>
      )
    }
  }

  const AreaSectionsLayout = useMemo(() => {
    return AreaLayout.filter((item: any) => !hiddenItems?.includes(item?.title)).map((layout: any) => {
      return (
        <CollapseSection
          key={layout.title}
          title={t(layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue, hiddenItems])

  // Do not show anything if params are missing
  const uuid = props?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>{AreaSectionsLayout}</Box>
    </Box>
  )
}

export default observer(Areas)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '60rem',
    overflowY: 'hidden'
  },
  customFormOuterContainer: {
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 2rem 2rem',
    mb: '1.5rem'
  },
  customFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  loading: {
    margin: 'auto'
  }
} as const
