import { ResponsiveBar } from '@nivo/bar'

export default function BarChart(props: any) {
  return (
    <ResponsiveBar
      data={props?.data}
      keys={styles.keys}
      indexBy='country'
      margin={styles.margin}
      padding={0.3}
      valueScale={styles.valueScale}
      indexScale={styles.indexScale}
      colors={styles.colors}
      defs={styles.defs}
      fill={styles.fill}
      borderColor={styles.borderColor as any}
      axisTop={null}
      axisRight={null}
      axisBottom={styles.axisBottom}
      axisLeft={styles.axisLeft}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={styles.labelTextColor as any}
      legends={styles.legends as any}
      role='application'
      ariaLabel='Nivo bar chart demo'
      barAriaLabel={e=>e.id+': '+e.formattedValue+' in country: '+e.indexValue}
    />
  )
}

const styles = {
  keys: [
    'hot dog',
    'burger',
    'sandwich',
    'kebab',
    'fries',
    'donut'
  ],
  margin: {
    top: 50,
    right: 130,
    bottom: 50,
    left: 60
  },
  valueScale: {
    type: 'linear'
  },
  indexScale: {
    type: 'band',
    round: true
  },
  colors: {
    scheme: 'nivo'
  },
  defs: [
    {
      id: 'dots',
      type: 'patternDots',
      background: 'inherit',
      color: '#38bcb2',
      size: 4,
      padding: 1,
      stagger: true
    },
    {
      id: 'lines',
      type: 'patternLines',
      background: 'inherit',
      color: '#eed312',
      rotation: -45,
      lineWidth: 6,
      spacing: 10
    }
  ],
  fill: [
    {
      match: {
        id: 'fries'
      },
      id: 'dots'
    },
    {
      match: {
        id: 'sandwich'
      },
      id: 'lines'
    }
  ],
  borderColor: {
    from: 'color',
    modifiers: [
      [
        'darker',
        1.6
      ]
    ]
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'country',
    legendPosition: 'middle',
    legendOffset: 32,
    truncateTickAt: 0
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'food',
    legendPosition: 'middle',
    legendOffset: -40
  },
  labelTextColor: {
    from: 'color',
    modifiers: [
      [
        'darker',
        1.6
      ]
    ]
  },
  legends: [
    {
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1
          }
        }
      ]
    }
  ]
} as const