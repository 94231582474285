import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

export enum Colors {
  transparent = 'transparent',
  black = '#000000',
  black20 = 'rgba(0, 0, 0, 0.2)',
  white = '#FFFFFF',
  white90 = 'rgba(255, 255, 255, 0.9)',
  white20 = 'rgba(255, 255, 255, 0.2)',
  primary = '#017C82',
  primaryDarker = '#00565B',
  primaryDarkest = '#003C40',
  primary10 = 'rgba(1, 124, 130, 0.1)',
  primary20 = 'rgba(1, 124, 130, 0.2)',
  primary30 = 'rgba(1, 124, 130, 0.3)',
  primary40 = 'rgba(1, 124, 130, 0.4)',
  primary50 = 'rgba(1, 124, 130, 0.5)',
  primary60 = 'rgba(1, 124, 130, 0.6)',
  primary70 = 'rgba(1, 124, 130, 0.7)',
  primary80 = 'rgba(1, 124, 130, 0.8)',
  primary90 = 'rgba(1, 124, 130, 0.9)',
  secondary = '#21E2EC',
  secondary10 = 'rgba(33, 226, 236, 0.1)',
  secondary20 = 'rgba(33, 226, 236, 0.2)',
  secondary30 = 'rgba(33, 226, 236, 0.3)',
  secondary40 = 'rgba(33, 226, 236, 0.4)',
  secondary50 = 'rgba(33, 226, 236, 0.5)',
  secondary60 = 'rgba(33, 226, 236, 0.6)',
  secondary70 = 'rgba(33, 226, 236, 0.7)',
  secondary80 = 'rgba(33, 226, 236, 0.8)',
  secondary90 = 'rgba(33, 226, 236, 0.9)',
  heading = '#003C40',
  heading90 = 'rgba(0, 60, 64, 0.9)',
  heading60 = 'rgba(0, 60, 64, 0.6)',
  text = '#2B2A2A',
  content = 'rgba(43, 42, 42, 0.7)',
  border = '#009CA4',
  border40 = 'rgba(0, 156, 164, 0.4)',
  highlight = '#FFBA0A',
  inactive = '#BFBFBF',
  backdrop = 'rgba(15, 46, 60, 0.5)',
  imageBackground = '#E9E9E9',
  grayBg = '#F2F2F2'
}

export enum Fonts {
  heading = 'Lato, sans-serif !important',
  body = 'Work Sans, sans-serif !important'
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560
    }
  },
  palette: {
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.primary
    },
    error: {
      main: Colors.primary
    },
  },
  typography: {
    fontFamily: 'Work Sans, sans-serif !important',
    h1: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '2.5rem',
      fontWeight: 900
    },
    h2: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '2rem',
      fontWeight: 900
    },
    h3: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.625rem',
      fontWeight: 900
    },
    h4: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.5rem',
      fontWeight: 900
    },
    h5: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.175rem',
      fontWeight: 900
    },
    h6: {
      fontFamily: 'Lato, sans-serif !important',
      color: Colors.heading,
      fontSize: '1rem',
      fontWeight: 900
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: Colors.white,
          pl: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem'
          },
          pr: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.625rem',
          padding: '0.75rem 1.5rem',
          fontFamily: 'Lato, sans-serif !important',
          fontSize: '1.125rem',
          fontWeight: 900
        },
        text: {
          color: Colors.text
        }
      }
    },
    MuiTypography: {
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            opacity: 1
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: '3.75rem',
          borderRadius: '0.625rem',
          '& .MuiListItemText-primary': {
            fontFamily: 'Lato, sans-serif !important',
            fontWeight: 700,
            fontSize: '1.125rem'
          },
          '&:hover': {
            backgroundColor: Colors.secondary10,
            transition: 'background-color 0.3s',
            pointer: 'cursor',
            '& .MuiListItemText-primary': {
              fontWeight: 900,
              transition: 'font-weight 0.3s'
            }
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0
        },
        primary: {
          color: Colors.white
        }
      }
    }
  }
})

export default theme