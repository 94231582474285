import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'
import FileUpload from '../../../Components/Common/FileUpload'
import File from '../../../Components/Common/File'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import { Button, CoverImage, Tabs, Tab, Chip, Table } from '../../../Components'
import { PropertyTabs } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'

const Property = (props: any) => {
  const {
    sessionStore,
    propertyStore,
    buildingStore,
    apartmentStore,
    areaStore
  }: any = useStore()
  const { me } = sessionStore
  const { property, loading } = propertyStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [tab, setTab] = useState(PropertyTabs.GeneralInformation)
  const [selectedImage, setSelectedImage] = useState(false)
  const [visibleDataType, setVisibleDataType] = useState('buildings')

  const clearSelectedImage = () => setSelectedImage(false)

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
    }
  }, [])

  useEffect(() => {
    if (tab === PropertyTabs.Attachments) {
      propertyStore.getPropertyFiles()
    } else if (tab === PropertyTabs.Reminders) {
      // propertyStore.getPropertyReminders()
    }
  }, [tab])

  useEffect(() => {
    if (property) {
      if (visibleDataType === 'buildings') {
        buildingStore.getBuildings(params?.uuid)
      } else if (visibleDataType === 'apartments') {
        apartmentStore.getApartments(params?.uuid)
      } else if (visibleDataType === 'areas') {
        // areaStore.getAreas(params?.uuid)
      }
    }
  }, [property, visibleDataType])

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  const updatePropertyBackgroundPhoto = (file: any) => {
    propertyStore.updatePropertyBackgroundPhoto(file?.uuid)
  }

  const downloadFile = async (file: any) => {
    // TODO: image download
    window.open(file?.url, '_blank')
  }

  const archiveFile = (file: any) => propertyStore.archivePropertyFile(file?.uuid)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === property?.backgroundPhoto) {
        return [
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updatePropertyBackgroundPhoto },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const getSortOptions = () => {
    return [
      { value: 'buildings', label: t('only_buildings') },
      { value: 'apartments', label: t('only_apartments') },
      { value: 'areas', label: t('only_areas') }
    ]
  }

  const getPropertyMenuOptions = () => {
    return [
      { text: t('edit_property'), onClick: toEditProperty }
    ]
  }


  const toEditProperty = () => navigate(`${location.pathname}/edit`)
  const uploadFiles = (files: any) => {
    propertyStore.uploadPropertyFiles(files)
  }
  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const viewBuildingPhotos = () => {
  }
  const viewApartmentPhotos = () => {
  }
  const viewAreaPhotos = () => {
  }

  const toCreateBuilding = () => navigate(`${location.pathname}/buildings/create`)
  const toCreateApartment = () => navigate(`${location.pathname}/apartments/create`)
  const toEditAreas = () => navigate(`${location.pathname}/edit?tab=areas`)
  
  const toBuilding = (building: any) => navigate(`${location.pathname}/buildings/${building?.uuid}`)
  const toApartment = (apartment: any) => navigate(`${location.pathname}/apartments/${apartment?.uuid}`)
  
  const getAddClickHandler = () => {
    if (visibleDataType === 'buildings') {
      return toCreateBuilding
    } else if (visibleDataType === 'apartments') {
      return toCreateApartment
    } else if (visibleDataType === 'areas') {
      return toEditAreas
    }
    return null
  }

  const getColumns = () => {
    if (visibleDataType === 'buildings') {
      return [
        {
          field: 'nameFi',
          headerName: t('building'),
          flex: 1,
          minWidth: 200,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<any>) => (
            <Button
              sx={styles.name}
              text={t(params.row.nameFi)}
              onClick={() => toBuilding(params.row)}
              variant='text'
            />
          )
        },
        {
          field: 'address',
          headerName: t('address'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          sortable: false,
          valueFormatter: (value: string) => value || '-'
        },
        {
          field: 'buildingPurpose',
          headerName: t('purpose'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => value ? t(value) : '-'
        },
        {
          field: 'floorCount',
          headerName: t('floors'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => value ? t(value) : '-'
        },
        {
          field: 'apartmentCount',
          headerName: t('apartments'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => value ? t(value) : '-'
        },
        {
          field: 'updatedAt',
          headerName: t('edited_at'),
          flex: 1,
          maxWidth: 320,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => {
            if (value) {
              const datetime = DateTime.fromISO(value)
              return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
            }
            return '-'
          }
        }
      ]
    } else if (visibleDataType === 'apartments') {
      return [
        {
          field: 'nameFi',
          headerName: t('apartment'),
          flex: 1,
          minWidth: 200,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<any>) => (
            <Button
              sx={styles.name}
              text={params.row.nameFi}
              onClick={() => toApartment(params.row)}
              variant='text'
            />
          )
        },
        {
          field: 'address',
          headerName: t('address'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          sortable: false,
          valueFormatter: (value: string) => value || '-'
        },
        {
          field: 'type',
          headerName: t('type'),
          flex: 1,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => value ? t(value) : '-'
        },
        {
          field: 'updatedAt',
          headerName: t('edited_at'),
          flex: 1,
          maxWidth: 320,
          headerClassName: 'kiinteistomuisti-table--header',
          disableColumnMenu: true,
          valueFormatter: (value: string) => {
            if (value) {
              const datetime = DateTime.fromISO(value)
              return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
            }
            return '-'
          }
        }
      ]
    } else if (visibleDataType === 'systems') {
    } else if (visibleDataType === 'spaces') {
    } else if (visibleDataType === 'areas') {
    }
    return []
  }

  const getRows = () => {
    if (visibleDataType === 'buildings') {
      return buildingStore.buildings
    } else if (visibleDataType === 'apartments') {
      return apartmentStore.apartments
    } else if (visibleDataType === 'systems') {
      // return systemStore.systems
    } else if (visibleDataType === 'spaces') {
      // return spaceStore.spaces
    } else if (visibleDataType === 'areas') {
      // return areaStore.areas
    }
    return []
  }

  const renderPropertyManagerInfo = () => {
    if (property?.propertyManager) {
      return (
        <Box sx={styles.column}>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>
            {t('property_manager_information')}
          </Typography>
          <Box sx={styles.infoContainer}>
            <Typography variant='body1' sx={styles.infoLabel}>{t('name')}</Typography>
            <Typography variant='body1' mb='1rem'>
              {`${property.propertyManager?.firstName} - ${property.propertyManager?.lastName}`}
            </Typography>
            <Typography variant='body1' sx={styles.infoLabel}>{t('email')}</Typography>
            <Chip text={property.propertyManager?.email} href email />
            <Typography variant='body1' sx={styles.infoLabel} mt='1rem'>
              {t('phone_number')}
            </Typography>
            <Chip text={property.propertyManager?.phone} href phone />
          </Box>
        </Box>
      )
    }
    return null
  }

  const renderAttachments = () => {
    return propertyStore.files.map((file: any) => (
      <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
        <File
          key={file.uuid}
          file={file}
          menuOptions={getFileActionOptions(file)}
          onFileClick={onFileClick}
        />
      </Grid>
    ))
  }

  const renderTabContent = () => {
    if (tab === PropertyTabs.GeneralInformation) {
      return (
        <>
          <Box sx={styles.row}>
            <Box sx={styles.column} mr='3rem'>
              <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('property_information')}</Typography>
              <Box sx={styles.infoContainer} mr='2rem'>
                <Typography variant='body1' sx={styles.infoLabel}>{t('property_type')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {property?.type ? t(property.type) : '-'}
                </Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('building_year')}</Typography>
                <Typography variant='body1' mb='1rem'>{property?.buildingYear || '-'}</Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('property_id')}</Typography>
                <Typography variant='body1' mb='0.5rem'>{property?.propertyId || '-'}</Typography>
              </Box>
            </Box>
            {renderPropertyManagerInfo()}
          </Box>
          <Typography variant='h2' mt='3rem' mb='1.5rem'>
            {t('property_items_title')}
          </Typography>
          <TableActions
            sort={visibleDataType}
            sortOptions={getSortOptions()}
            onSortSelect={setVisibleDataType}
            onPhotosClick={viewBuildingPhotos}
            onAddClick={getAddClickHandler()}
          />
          <Table
            rows={getRows()}
            columns={getColumns()}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === PropertyTabs.Attachments) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ xs: 0, sm: 2, md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
            mt='1.5rem'
          >
            <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
              <FileUpload onUploadFiles={uploadFiles} loading={loading} />
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === PropertyTabs.Reminders) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('reminders')}</Typography>
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (propertyStore?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            propertyStore?.files
            .filter((file: any) => file?.type === 'image')
            .map((file: any) => ({ src: file?.url }))
            .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            propertyStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage bgPhotoUrl={property?.backgroundPhoto} />
      <Typography variant='h2' textTransform='uppercase' mt='1rem'>
        {property?.nameFi || '-'}
      </Typography>
      <Box sx={styles.infoRow}>
        <Chip text={property?.businessId} />
        <Box sx={styles.dot} />
        <Typography variant='body1'>{property?.address || '-'}</Typography>
      </Box>
      <Tabs onEditClick={toEditProperty} menuOptions={getPropertyMenuOptions()}>
        <Tab
          value={PropertyTabs.GeneralInformation}
          text={t(PropertyTabs.GeneralInformation)}
          selected={tab === PropertyTabs.GeneralInformation}
          onClick={setTab}
          first
        />
        <Tab
          value={PropertyTabs.Attachments}
          text={t(PropertyTabs.Attachments)}
          selected={tab === PropertyTabs.Attachments}
          onClick={setTab}
          count={0}
        />
        <Tab
          value={PropertyTabs.Reminders}
          text={t(PropertyTabs.Reminders)}
          selected={tab === PropertyTabs.Reminders}
          onClick={setTab}
          count={0}
        />
      </Tabs>
      {renderTabContent()}
      {renderLightbox()}
    </Box>
  )
}

export default observer(Property)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  }
} as const
