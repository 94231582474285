import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import upperFirst from 'lodash/upperFirst'
import { Interweave } from 'interweave'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import File from '../../Components/Common/File'
import Input from '../../Components/Common/Input'
import Comment from '../../Components/Common/Comment'
import Lightbox from '../../Components/Common/Lightbox'
import Button from './Button'
import Chip from './Chip'
import { Colors } from '../../Utils/theme'

export default function ProjectModal(props: any) {
  const {
    onClose,
    loading,
    project,
    updateProjectBackgroundPhoto,
    createComment,
    updateComment,
    archiveComment,
    downloadFile,
    archiveFile
  } = props
  const { t } = useTranslation()

  const [comment, setComment] = useState('')
  const [selectedImage, setSelectedImage] = useState(false)
  const [editableCommentId, setEditableCommentId] = useState(null)

  const clearComment = () => {
    setComment('')
    setEditableCommentId(null)
  }
  const clearSelectedImage = () => setSelectedImage(false)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === project?.backgroundPhoto) {
        return [
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updateProjectBackgroundPhoto },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const saveComment = () => {
    if (!editableCommentId && comment) {
      createComment({ text: comment }, clearComment)
    } else if (editableCommentId && comment) {
      updateComment(editableCommentId, { text: comment }, clearComment)
    }
  }

  const toggleCommentEdit = (comment: any) => {
    if (!editableCommentId) {
      setEditableCommentId(comment.uuid)
      setComment(comment?.text)
    } else {
      setEditableCommentId(null)
      setComment('')
    }
  }

  const archiveProjectComment = (comment: any) => archiveComment(comment?.uuid)

  const renderAttachments = () => {
    if (project?.files?.length) {
      return project.files.map((file: any) => (
        <Grid key={file.uuid} xs={6} sm={4} xxxl={3}>
          <File
            file={file}
            menuOptions={getFileActionOptions(file)}
            onFileClick={onFileClick}
            dense
          />
        </Grid>
      ))
    }
    return <Typography variant='body1'>{t('no_files')}</Typography>
  }

  const renderLightbox = () => {
    if (project?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            project.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            project.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  const renderComments = () => {
    if (project?.comments?.length) {
      return project?.comments?.map((comment: any) => (
        <Comment
          key={comment.uuid}
          comment={comment}
          editComment={toggleCommentEdit}
          archiveComment={archiveProjectComment}
        />
      ))
    }
    return (
      <Typography variant='body1' mt='0.5rem' mb='1rem'>
        {t('no_comments')}
      </Typography>
    )
  }

  return (
    <Dialog onClose={onClose} sx={styles.dialog} disableRestoreFocus open>
      <Box sx={styles.leftContainer}>
        <Chip
          onClick={onClose}
          buttonSx={styles.chipButton}
          sx={styles.chip}
          text={t('close')}
        />
        <DialogContent sx={styles.content}>
          <DialogTitle variant='h2' sx={styles.name}>{project?.nameFi || ''}</DialogTitle>
          <Box sx={styles.infoRow}>
            <Chip text={t(project?.type)} />
            <Box sx={styles.dot} />
            <Typography variant='body1'>
              {upperFirst(DateTime.fromISO(project?.startTime).setLocale('fi').toFormat('LLLL yyyy'))}
              {' - '} 
              {upperFirst(DateTime.fromISO(project?.endTime).setLocale('fi').toFormat('LLLL yyyy'))}
            </Typography>
          </Box>
          <Interweave content={project?.descriptionFi || ''} style={styles.contentText} />
          <DialogTitle variant='h3' sx={styles.title} mt='1.5rem' mb='1rem'>
            {t('costs_and_financing')}
          </DialogTitle>
          <Box sx={styles.infoContainer}>
            <Box sx={styles.row}>
              <Box sx={styles.column}>
                <Typography variant='body1' sx={styles.infoLabel}>{t('total_cost')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {project?.totalCost ? `${project?.totalCost?.toLocaleString('fi')}€` : '-'}
                </Typography>
              </Box>
              <Box sx={styles.column}>
                <Typography variant='body1' sx={styles.infoLabel}>{t('financing')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {project?.financingType ? t(project.financingType) : '-'}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.row}>
              <Box sx={styles.column}>
                <Typography variant='body1' sx={styles.infoLabel}>{t('financier')}</Typography>
                <Typography variant='body1' mb='0.5rem'>{project?.financier || '-'}</Typography>
              </Box>
              <Box sx={styles.column}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('loan_interest')}
                </Typography>
                <Typography variant='body1' mb='0.5rem'>
                  {project?.loanInterestRate ? `${project?.loanInterestRate}%` : '-'}
                </Typography>
                </Box>
            </Box>
          </Box>
          <DialogTitle variant='h3' sx={styles.title} mt='1.5rem' mb='1rem'>
            {t('attachments')}
          </DialogTitle>
          <Box sx={styles.formContainer}>
            <Grid
              container
              columns={12}
              columnSpacing={{ xs: 0, sm: 2, xl: 4 }}
              rowSpacing='1.5rem'
            >
              {renderAttachments()}
            </Grid>
          </Box>
        </DialogContent>
        {renderLightbox()}
      </Box>
      <Box sx={styles.rightContainer}>
        <Box sx={styles.topRightContainer}>
          <DialogTitle variant='h3' sx={styles.title} mb='1.25rem'>
            {editableCommentId ? t('edit_comment') : t('add_comment')}
          </DialogTitle>
          <Input
            value={comment}
            onChange={setComment}
            multiline
          />
          <DialogActions sx={styles.actions}>
            <Button
              sx={styles.outlinedButton}
              text={t('clear')}
              onClick={clearComment}
              width='12rem'
              variant='text'
            />
            <Button
              sx={styles.button}
              text={editableCommentId ? t('save') : t('publish')}
              onClick={saveComment}
              loading={loading}
              disabled={loading || !comment}
              width='12rem'
            />
          </DialogActions>
        </Box>
        <Box sx={styles.bottomRightContainer}>
          <DialogTitle variant='h3' sx={styles.title} mb='1.25rem'>
            {t('previous_comments')}
          </DialogTitle>
          {renderComments()}
        </Box>
      </Box>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minHeight: '90vh',
      flexDirection: 'row',
      width: '100%',
      minWidth: {
        lg: '90%',
        xl: '80%',
        xxl: '70%',
        xxxl: '60%'
      },
      backgroundColor: Colors.transparent,
      overflow: 'hidden',
      boxShadow: 'none'
    }
  },
  chipButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  chip: {
    minWidth: '4rem'
  },
  name: {
    color: Colors.primary,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  title: {
    color: Colors.primary,
    padding: 0,
    alignSelf: 'flex-start'
  },
  content: {
    width: '100%',
  },
  contentText: {
    marginTop: '0.5rem',
    marginBottom: '1.25rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '100%',
    marginBottom: '0rem'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50%'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.75rem',
    marginRight: '0.75rem'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem',
    width: '100%'
  },
  infoLabel: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  outlinedButton: {
    height: '3.125rem',
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    height: '3.125rem',
    pl: '2.5rem',
    pr: '2.5rem'
  },
  leftContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '65%',
    height: '90vh',
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem',
    backgroundColor: Colors.white,
    mr: '1rem'
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '35%',
    height: '90vh',
    ml: '1rem '
  },
  topRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem',
    backgroundColor: Colors.white,
    mb: '2rem'
  },
  bottomRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    borderRadius: '0.625rem',
    padding: '1.25rem 1.25rem',
    backgroundColor: Colors.white
  }
} as const
