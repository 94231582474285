import { ResponsiveLine } from '@nivo/line'

export default function LineChart(props: any) {
  return (
    <ResponsiveLine
      data={props?.data}
      margin={styles.margin}
      xScale={styles.xScale}
      yScale={styles.yScale}
      yFormat=' >-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={styles.axisBottom}
      axisLeft={styles.axisLeft}
      pointSize={10}
      pointColor={styles.pointColor}
      pointBorderWidth={2}
      pointBorderColor={styles.pointBorderColor}
      pointLabel='data.yFormatted'
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      legends={styles.legends as any}
    />
  )
}

const styles = {
  margin: {
    top: 50,
    right: 110,
    bottom: 50,
    left: 60
  },
  xScale: {
    type: 'point'
  },
  yScale: {
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: true,
    reverse: false
  },
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'transportation',
    legendOffset: 36,
    legendPosition: 'middle'
  },
  axisLeft: {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'count',
    legendOffset: -40,
    legendPosition: 'middle'
  },
  pointColor: {
    theme: 'background'
  },
  pointBorderColor: {
    from: 'serieColor'
  },
  legends: [
    {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ]
    }
  ]
} as const