import {
  ComponentTypes,
  ComponentExtraProperties,
  LayoutPosition,
  FormTypes,
  ComponentExtraStyles
} from './layout'

const { DisabledIfNoParentValue } = ComponentExtraProperties
const { Left, Right } = LayoutPosition
const { Indented } = ComponentExtraStyles

export enum AreaSections {
  // Kulku- ja ajoväylät
  AccessAndDriveways = 'access_and_driveways',
  // Ulkoalueiden vedenpoisto
  WaterDrainage = 'water_drainage',
  // Nurmialueet
  LawnAreas = 'lawn_areas',
  // Istutukset
  Plantings = 'plantings',
  // Rakennusten vierustat
  BuildingSides = 'building_sides',
  // Pysäköintialueet
  ParkingAreas = 'parking_areas',
  // Leikkialueet
  PlayAreas = 'play_areas',
  // Varusteet ja kalusteet
  EquipmentAndFixtures = 'equipment_and_furniture',
  // Pihakansi
  YardDeck = 'yard_deck',
  // Sivurakennukset
  SideBuildings = 'side_buildings',
  // Jätehuolto
  WasteManagement = 'waste_management'
}

const {
  AccessAndDriveways,
  WaterDrainage,
  LawnAreas,
  Plantings,
  BuildingSides,
  ParkingAreas,
  PlayAreas,
  EquipmentAndFixtures,
  YardDeck,
  SideBuildings,
  WasteManagement
} = AreaSections

export const AreaSectionSubSections = {
  [AccessAndDriveways]: [
    /*
    {
      category: AccessAndDriveways,
      value: 'material',
      position: Left,
      items: [
        {
          value: 'pavement_material',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'paving', label: 'paving' },
            { value: 'asphalt', label: 'asphalt' },
            { value: 'crush',  label: 'crush' },
            { value: 'macadam', label: 'macadam' },
            { value: 'rock_ash', label: 'rock_ash' },
            { value: 'concrete', label: 'concrete' }
          ]
        },
        { value: 'pavement_material_notes', label: 'notes', type: ComponentTypes.Input }
      ]
    },
    */
  ],
  [WaterDrainage]: [
    /*
    {
      category: WaterDrainage,
      value: 'type',
      position: Left,
      items: [
        {
          value: 'pouring_into_surrounding_land_areas',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'stormwater_wells',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'other_what',
          type: ComponentTypes.BooleanCheckbox,
          items: [
            {
              value: 'other_what_info',
              label: ' ',
              type: ComponentTypes.Input,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        { value: 'drainage_notes', label: 'notes', type: ComponentTypes.Input }
      ]
    },
    {
      category: WaterDrainage,
      value: 'working_condition',
      position: Right,
      minHeight: '19.125rem',
      items: [
        {
          value: 'water_drainage_condition_no_deficiencies',
          label: 'no_deficiencies_in_drainage',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'water_drainage_condition_has_deficiencies',
          label: 'deficiencies_in_drainage',
          type: ComponentTypes.BooleanCheckbox,
          extraStyles: ['allowMargin'],
          items: [
            {
              value: 'water_drainage_condition_deficiency_in_pouring',
              label: 'deficiencies_in_pouring',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            },
            {
              value: 'water_drainage_condition_ponding_can_be_observed',
              label: 'ponding_can_be_observed',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            },
            {
              value: 'water_drainage_condition_stormwater_wells_maintenance_needed',
              label: 'stormwater_wells_maintenance_needed',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            },
            {
              value: 'water_drainage_condition_stormwater_wells_repair_needed',
              label: 'stormwater_wells_repair_needed',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        }
      ]
    }
    */
  ],
  [LawnAreas]: [
    /*
    {
      category: LawnAreas,
      value: 'basic_information',
      position: Left,
      items: [
        {
          value: 'lawn_area_size',
          label: 'area',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: LawnAreas,
      value: 'notes',
      position: Right,
      items: [
        {
          value: 'lawn_area_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [Plantings]: [
    /*
    {
      category: Plantings,
      value: 'plantations_in_the_area',
      position: Left,
      minHeight: '23.375rem',
      items: [
        {
          value: 'planting_type_no_plantations',
          label: 'no',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_type_wild_plantations',
          label: 'yes_wild_ones',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_type_cultivated_plantations',
          label: 'yes_cultivated_ones',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_type_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: Plantings,
      value: 'demarcations_of_plantations',
      position: Right,
      items: [
        {
          value: 'planting_demarcations_no_demarcations',
          label: 'no_separate_demarcations',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_wood_chip',
          label: 'with_wood_chip',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_gravel_or_cobblestone',
          label: 'with_gravel_or_cobblestone',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_curbstone',
          label: 'with_curbstone',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_wood_edges',
          label: 'with_wood_edges',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_plastic_edges',
          label: 'with_plastic_edges',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_metal_edges',
          label: 'with_metal_edges',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'planting_demarcations_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [BuildingSides]: [
    /*
    {
      category: BuildingSides,
      value: 'are_there_planting_or_grass_areas_near_the_building',
      position: Left,
      minHeight: '23.375rem',
      items: [
        {
          value: 'building_sides_planting',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'no', label: 'no' },
            { value: 'yes', label: 'yes' }
          ]
        },
        {
          value: 'planting_type_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: BuildingSides,
      value: 'materials',
      position: Right,
      items: [
        {
          value: 'building_sides_material_soil_or_grass',
          label: 'soil_or_grass',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_sand',
          label: 'sand',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_crush',
          label: 'crush',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_macadam',
          label: 'macadam',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_cobblestone',
          label: 'cobblestone',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_paving',
          label: 'paving',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_asphalt',
          label: 'asphalt',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'building_sides_material_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [ParkingAreas]: [
    /*
    {
      category: ParkingAreas,
      value: 'parking_arranged',
      position: Left,
      minHeight: '19.125rem',
      items: [
        {
          value: 'parking_arrangement_streets',
          label: 'for_street_areas',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_arrangement_parking_hall',
          label: 'to_the_parking_hall',
          type: ComponentTypes.BooleanCheckbox,
          extraStyles: ['allowMargin'],
          items: [
            {
              value: 'parking_arrangement_parking_hall_property_owned',
              label: 'the_propertys_own',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            },
            {
              value: 'parking_arrangement_parking_hall_shared_with_other_properties',
              label: 'shared_with_other_properties',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            },
            {
              value: 'parking_arrangement_parking_hall_public',
              label: 'public',
              type: ComponentTypes.BooleanCheckbox,
              extraProperties: [DisabledIfNoParentValue],
              extraStyles: [Indented]
            }
          ]
        },
        {
          value: 'parking_arrangement_carports',
          label: 'to_carports',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_arrangement_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: ParkingAreas,
      value: 'parking_area_coating',
      position: Right,
      items: [
        {
          value: 'parking_area_coating_paving',
          label: 'paving',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_asphalt',
          label: 'asphalt',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_crush',
          label: 'crush',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_macadam',
          label: 'macadam',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_rock_ash',
          label: 'rock_ash',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_concrete',
          label: 'concrete',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'parking_area_coating_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: ParkingAreas,
      value: 'parking_area_basic_information',
      position: Left,
      minHeight: '18rem',
      items: [
        {
          value: 'parking_area_number_of_parking_spaces',
          label: 'number_of_parking_spaces',
          placeholder: 'count',
          type: ComponentTypes.NumberSelect,
          maxCount: 150,
          mb: '1.125rem'
        },
        {
          value: 'parking_area_number_of_invalid_parking_spaces',
          label: 'number_of_invalid_parking_spaces',
          placeholder: 'count',
          maxCount: 30,
          type: ComponentTypes.NumberSelect
        }
      ]
    },
    {
      category: ParkingAreas,
      value: 'are_invalid_parking_spaces_clearly_marked',
      position: Right,
      items: [
        {
          value: 'parking_area_invalid_parking_spaces_clearly_marked',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'yes', label: 'yes' },
            { value: 'no', label: 'no' }
          ]
        }
      ]
    },
    {
      category: ParkingAreas,
      value: 'location_of_the_area_in_the_plot',
      position: Right,
      items: [
        {
          value: 'parking_area_location_in_the_plot',
          label: 'location',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [PlayAreas]: [
    /*
    {
      category: PlayAreas,
      value: 'has_safety_document',
      position: Left,
      items: [
        {
          value: 'play_area_has_safety_documentation',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'yes', label: 'yes' },
            { value: 'no', label: 'no' }
          ]
        },
        {
          value: 'play_area_safety_documentation_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    }
    */
  ],
  [EquipmentAndFixtures]: [
    /*
    [
      {
        title: 'mailboxes',
        type: FormTypes.SectionSubsections,
        items: [
          {
            items: [
              {
                category: EquipmentAndFixtures,
                value: 'location',
                position: Left,
                minHeight: '16.675rem',
                items: [
                  {
                    value: 'mailboxes_location',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'on_yard_on_rack', label: 'in_the_yard_on_own_rack' },
                      { value: 'in_connection_with_side_building', label: 'in_connection_with_side_building' },
                      { value: 'in_front_of_apartments', label: 'in_front_of_the_apartments' },
                      { value: 'integrated_into_apartment_doors', label: 'integrated_into_apartment_doors' }
                    ]
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'material',
                position: Right,
                items: [
                  {
                    value: 'mailboxes_material',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'metal', label: 'metal' },
                      { value: 'plastic', label: 'plastic' }
                    ]
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'lock',
                position: Right,
                items: [
                  {
                    value: 'mailboxes_has_lock',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'yes', label: 'yes' },
                      { value: 'no', label: 'no' }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    [
      {
        title: 'outdoor_stand',
        type: FormTypes.SectionSubsections,
        items: [
          {
            items: [
              {
                category: EquipmentAndFixtures,
                value: 'location_on_the_plot',
                position: Left,
                items: [
                  {
                    value: 'outdoor_stand_location',
                    label: ' ',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'material',
                position: Right,
                items: [
                  {
                    value: 'outdoor_stand_material',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'metal', label: 'metal' },
                      { value: 'wood', label: 'wood_frame' }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    [
      {
        title: 'clothes_line',
        type: FormTypes.SectionSubsections,
        items: [
          {
            items: [
              {
                category: EquipmentAndFixtures,
                value: 'location_on_the_plot',
                position: Left,
                items: [
                  {
                    value: 'clothes_line_location',
                    label: ' ',
                    type: ComponentTypes.Input
                  },
                  {
                    value: 'clothes_line_count',
                    label: ' ',
                    placeholder: 'count',
                    type: ComponentTypes.NumberSelect
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'material',
                position: Right,
                minHeight: '10.575rem',
                items: [
                  {
                    value: 'clothes_line_material',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'metal', label: 'metal' },
                      { value: 'wood', label: 'wood_frame' }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    [
      {
        title: 'barbecue_area',
        type: FormTypes.SectionSubsections,
        items: [
          {
            items: [
              {
                category: EquipmentAndFixtures,
                value: 'area_type',
                position: Left,
                minHeight: '18.875rem',
                items: [
                  {
                    value: 'barbecue_area_type_open',
                    label: 'open_barbecue_area',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'barbecue_area_type_hut',
                    label: 'barbecue_hut',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'barbecue_area_type_shelter',
                    label: 'barbecue_shelter',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'barbecue_area_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'structure_material',
                position: Right,
                items: [
                  {
                    value: 'barbecue_area_structure_material_metal',
                    label: 'metal',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'barbecue_area_structure_material_wood',
                    label: 'wood_frame',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'barbecue_area_structure_material_concrete',
                    label: 'concrete',
                    type: ComponentTypes.BooleanCheckbox
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'location_on_the_plot',
                position: Right,
                items: [
                  {
                    value: 'barbecue_area_location',
                    label: ' ',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    [
      {
        title: 'yard_fixtures',
        type: FormTypes.SectionSubsections,
        items: [
          {
            items: [
              {
                category: EquipmentAndFixtures,
                value: 'type',
                position: Left,
                minHeight: '25.575rem',
                items: [
                  {
                    value: 'yard_fixtures_type_benches',
                    label: 'benches',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_type_chairs',
                    label: 'chairs',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_type_tables',
                    label: 'tables',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_type_swings',
                    label: 'garden_swings',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_type_small_waste_collection_containers',
                    label: 'small_waste_collection_containers',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_type_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'structure_material',
                position: Right,
                items: [
                  {
                    value: 'yard_fixtures_structure_material_metal',
                    label: 'metal',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_structure_material_plastic',
                    label: 'plastic',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_structure_material_wood',
                    label: 'wood_frame',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_structure_material_rattan_polyrattan',
                    label: 'rattan_or_polyrattan',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_structure_material_glass',
                    label: 'glass',
                    type: ComponentTypes.BooleanCheckbox
                  },
                  {
                    value: 'yard_fixtures_structure_material_concrete',
                    label: 'concrete',
                    type: ComponentTypes.BooleanCheckbox
                  }
                ]
              },
              {
                category: EquipmentAndFixtures,
                value: 'location_on_the_plot',
                position: Right,
                items: [
                  {
                    value: 'yard_fixtures_location',
                    label: ' ',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    */
  ],
  [YardDeck]: [
    /*
    {
      category: YardDeck,
      value: 'structure_type',
      position: Left,
      items: [
        {
          value: 'yard_deck_structure_type',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'inverted', label: 'inverted_structure' },
            { value: 'closed' , label: 'closed_structure' },
            { value: 'not_known', label: 'not_known' }
          ]
        },
        {
          value: 'yard_deck_structure_type_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: YardDeck,
      value: 'spaces_below',
      position: Right,
      items: [
        {
          value: 'yard_deck_spaces_below_type',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'outdoor_spaces', label: 'outdoor_spaces' },
            { value: 'cold_spaces', label: 'cold_spaces' },
            { value: 'semi_heated_spaces', label: 'semi_heated_spaces' },
            { value: 'heated_spaces', label: 'heated_spaces' }
          ]
        }
      ]
    },
    {
      category: YardDeck,
      value: 'structure_age',
      position: Left,
      minHeight: '7.175rem',
      items: [
        {
          value: 'yard_deck_structure_age',
          label: ' ',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: YardDeck,
      value: 'spaces_above',
      position: Right,
      items: [
        {
          value: 'yard_deck_spaces_above_type',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'parking_area', label: 'parking_area' },
            { value: 'yard_green_and_planting_areas', label: 'yard_green_and_planting_areas' },
            { value: 'passage_ways', label: 'passage_ways' },
            { value: 'play_areas', label: 'play_areas' }
          ]
        }
      ]
    }
    */
  ],
  [SideBuildings]: [
    /*
    [
      {
        type: FormTypes.SectionSubsections,
        items: [
          {
            value: 'parking_building',
            items: [
              {
                category: SideBuildings,
                value: 'building_frame_structures',
                position: Left,
                items: [
                  {
                    value: 'building_frame_structure_type',
                    type: ComponentTypes.BooleanCheckbox,
                    options: [
                      { value: 'wood', label: 'wood_frame' },
                      { value: 'concrete', label: 'concrete' },
                      { value: 'steel', label: 'steel' }
                    ]
                  }
                ]
              },
              {
                category: SideBuildings,
                value: 'notes',
                position: Right,
                minHeight: '8.575rem',
                items: [
                  {
                    value: 'building_frame_structure_notes',
                    label: 'notes',
                    type: ComponentTypes.Input
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    */
  ],
  [WasteManagement]: [
    /*
    {
      category: WasteManagement,
      value: 'location',
      position: Left,
      minHeight: '24.325rem',
      items: [
        {
          value: 'waste_management_location',
          type: ComponentTypes.BooleanCheckbox,
          options: [
            { value: 'inside_the_building', label: 'inside_the_building' },
            { value: 'in_connection_with_side_building', label: 'in_connection_with_side_building' },
            { value: 'in_waste_shed', label: 'in_waste_shed' },
            { value: 'in_the_outdoor_area_in_enclosure', label: 'in_the_outdoor_area_in_enclosure' },
            { value: 'in_the_outdoor_area_in_lightly_built_canopy', label: 'in_the_outdoor_area_in_lightly_built_canopy' },
            { value: 'in_the_outdoor_area_in_open', label: 'in_the_outdoor_area_in_open' },
            { value: 'waste_shaft', label: 'waste_shaft' }
          ]
        },
        {
          value: 'waste_management_location_notes',
          label: 'notes',
          type: ComponentTypes.Input
        }
      ]
    },
    {
      category: WasteManagement,
      value: 'collected_waste_types',
      position: Right,
      items: [
        {
          value: 'collected_waste_type_combustible_waste',
          label: 'combustible_waste',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_mixed_waste',
          label: 'mixed_waste',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_plastic_waste',
          label: 'plastic_waste',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_bio_waste',
          label: 'bio_waste',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_glass',
          label: 'glass',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_ser_waste',
          label: 'ser_waste',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_cardboard',
          label: 'cardboard',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_paper',
          label: 'paper',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_batteries',
          label: 'batteries',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'collected_waste_type_metal',
          label: 'metal',
          type: ComponentTypes.BooleanCheckbox
        }
      ]
    },
    {
      category: WasteManagement,
      value: 'waste_containers',
      position: Left,
      items: [
        {
          value: 'waste_containers_loose_waste_containers',
          label: 'loose_waste_containers',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'waste_containers_deep_collection_containers',
          label: 'deep_collection_containers',
          type: ComponentTypes.BooleanCheckbox
        },
        {
          value: 'waste_containers_waste_compactor',
          label: 'waste_compactor',
          type: ComponentTypes.BooleanCheckbox
        }
      ]
    }
    */
  ]
}

export const AreaLayout = [
  {
    title: AccessAndDriveways,
    sections: AreaSectionSubSections[AccessAndDriveways]
  },
  {
    title: WaterDrainage,
    sections: AreaSectionSubSections[WaterDrainage]
  },
  {
    title: LawnAreas,
    sections: AreaSectionSubSections[LawnAreas]
  },
  {
    title: Plantings,
    sections: AreaSectionSubSections[Plantings]
  },
  {
    title: BuildingSides,
    sections: AreaSectionSubSections[BuildingSides]
  },
  {
    title: ParkingAreas,
    sections: AreaSectionSubSections[ParkingAreas]
  },
  {
    title: PlayAreas,
    sections: AreaSectionSubSections[PlayAreas]
  },
  {
    title: EquipmentAndFixtures,
    sections: AreaSectionSubSections[EquipmentAndFixtures]
  },
  {
    title: YardDeck,
    sections: AreaSectionSubSections[YardDeck]
  },
  {
    title: SideBuildings,
    sections: AreaSectionSubSections[SideBuildings]
  },
  {
    title: WasteManagement,
    sections: AreaSectionSubSections[WasteManagement]
  }
]