import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FileUpload from '../../../Components/Common/FileUpload'
import File from '../../../Components/Common/File'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import RoomItem from '../../../Components/Common/RoomItem'
import { CoverImage, Tabs, Tab, Chip } from '../../../Components'
import { ApartmentTabs } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'

const Apartment = (props: any) => {
  const { sessionStore, buildingStore, apartmentStore, roomStore }: any = useStore()
  const { me } = sessionStore
  const { apartment, loading } = apartmentStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [tab, setTab] = useState(ApartmentTabs.GeneralInformation)
  const [selectedImage, setSelectedImage] = useState(false)

  const clearSelectedImage = () => setSelectedImage(false)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const aUuid = params?.aUuid || null
    if (pUuid && aUuid && pUuid?.length === 36 && aUuid?.length === 36) {
      apartmentStore.getApartment(pUuid, aUuid)
    }
  }, [])

  useEffect(() => {
    if (tab === ApartmentTabs.Attachments) {
      apartmentStore.getApartmentFiles(params?.pUuid)
    } else if (tab === ApartmentTabs.Reminders) {
      // apartmentStore.getApartmentReminders()
    }
  }, [tab])

  useEffect(() => {
    if (apartment) {
      roomStore.getRooms(params.pUuid, params.aUuid)
    }
  }, [apartment])

  if (
      !apartment ||
      !params.pUuid ||
      !params.aUuid ||
      params.pUuid?.length !== 36 ||
      params.aUuid?.length !== 36
  ) {
    return null
  }

  const updateApartmentBackgroundPhoto = (file: any) => {
    apartmentStore.updateApartmentBackgroundPhoto(params?.pUuid, file?.uuid)
  }

  const downloadFile = async (file: any) => {
    // TODO: image download
    window.open(file?.url, '_blank')
  }

  const archiveFile = (file: any) => apartmentStore.archiveApartmentFile(params?.pUuid, file?.uuid)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === apartment?.backgroundPhoto) {
        return [
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updateApartmentBackgroundPhoto },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const getApartmentMenuOptions = () => {
    return [
      { text: t('edit_apartment'), onClick: toEditApartment }
    ]
  }

  const toEditApartment = () => navigate(`${location.pathname}/edit`)
  const uploadFiles = (files: any) => {
    apartmentStore.uploadApartmentFiles(params?.pUuid, files)
  }
  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const viewRoomPhotos = () => {
  }

  const toCreateRoom = () => navigate(`${location.pathname}/rooms/create`)
  const toRoom = (room: any) => navigate(`${location.pathname}/rooms/${room?.uuid}`)

  const renderRooms = () => {
    if (roomStore?.rooms?.length) {
      return (
        <Grid
          container
          columns={12}
          columnSpacing={{ xs: 0, sm: 2, md: 3, xl: 4, xxl: 5, xxxl: 6 }}
          rowSpacing='1.5rem'
          mt='1.5rem'
        >
          {
            roomStore.rooms.map((room: any) => (
              <Grid xs={12} sm={6} md={6} xl={4} xxl={4} xxxl={3}>
                <RoomItem item={room} onClick={() => toRoom(room)} />
              </Grid>
            ))
          }
        </Grid>
      )
    }
    return null
  }

  const renderAttachments = () => {
    return apartmentStore.files.map((file: any) => (
      <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
        <File
          key={file.uuid}
          file={file}
          menuOptions={getFileActionOptions(file)}
          onFileClick={onFileClick}
        />
      </Grid>
    ))
  }

  const renderTabContent = () => {
    if (tab === ApartmentTabs.GeneralInformation) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('apartment_information')}</Typography>
          <Box sx={styles.row}>
            <Box sx={styles.column} mr='2rem'>
              <Box sx={styles.infoContainer} mr='2rem'>
                <Typography variant='body1' sx={styles.infoLabel}>{t('type')}</Typography>
                <Typography variant='body1' mb='1rem'>
                  {apartment?.type ? t(apartment.type) : '-'}
                </Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('area')}</Typography>
                <Typography variant='body1' mb='1rem'>{`${apartment?.areaSize}` || '-'} m<sup>2</sup></Typography>
                <Typography variant='body1' sx={styles.infoLabel}>{t('address')}</Typography>
                <Typography variant='body1' mb='0.5rem'>{apartment?.address || '-'}</Typography>
              </Box>
            </Box>
          </Box>
          <TableActions
            title={t('rooms')}
            onPhotosClick={viewRoomPhotos}
            onAddClick={toCreateRoom}
          />
          {renderRooms()}
        </>
      )
    } else if (tab === ApartmentTabs.Attachments) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ xs: 0, sm: 2, md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
            mt='1.5rem'
          >
            <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
              <FileUpload onUploadFiles={uploadFiles} loading={loading} />
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === ApartmentTabs.Reminders) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('reminders')}</Typography>
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (apartmentStore?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            apartmentStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            apartmentStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage bgPhotoUrl={apartment?.backgroundPhoto} />
      <Typography variant='h2' textTransform='uppercase' mt='1rem'>
        {apartment?.nameFi || '-'}
      </Typography>
      <Box sx={styles.infoRow}>
        <Chip text={apartment?.property?.nameFi || ''} />
        <Box sx={styles.dot} />
        <Typography variant='body1'>{apartment?.address || '-'}</Typography>
      </Box>
      <Tabs onEditClick={toEditApartment} menuOptions={getApartmentMenuOptions()}>
        <Tab
          value={ApartmentTabs.GeneralInformation}
          text={t(ApartmentTabs.GeneralInformation)}
          selected={tab === ApartmentTabs.GeneralInformation}
          onClick={setTab}
          first
        />
        <Tab
          value={ApartmentTabs.Attachments}
          text={t(ApartmentTabs.Attachments)}
          selected={tab === ApartmentTabs.Attachments}
          onClick={setTab}
          count={0}
        />
        <Tab
          value={ApartmentTabs.Reminders}
          text={t(ApartmentTabs.Reminders)}
          selected={tab === ApartmentTabs.Reminders}
          onClick={setTab}
          count={0}
        />
      </Tabs>
      {renderTabContent()}
      {renderLightbox()}
    </Box>
  )
}

export default observer(Apartment)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '60rem',
    marginBottom: '3rem'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    // width: 'calc(50% - 1rem)',
    maxWidth: '30rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  }
} as const
