import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import FilterUsersModal from '../../../Components/Common/FilterUsersModal'
import { GridColDef, GridRenderCellParams, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { Button, Chip } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'

const Users = (props: any) => {
  const { sessionStore, userStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language, me } = sessionStore
  const { loading, totalUsers, users } = userStore
  
  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)
  
  useEffect(() => {
    if (!users?.length) {
      userStore.getUsers()
    }
  }, [users])

  const bulkActionOptions = useMemo(() => [
    // { value: 'download_as_csv', label: t('download_as_csv') }
  ], [language])

  const toUser = (row: any) => navigate(`/users/${row?.uuid}`)
  const toCreateUser = () => navigate(`/users/create`)

  const selectBulkAction = (action: string) => {
    setBulkAction(action)
    /*
    if (action === 'download_as_csv') {
      userStore.downloadUsersAsCSV()
    }
    */
  }

  const clearFilters = () => setFilters({})
  const updateFilters = (updatedFilters: any) => {
    setFilters(updatedFilters)
    closeFiltersModal()
  }

  const getUserCount = (previewFilters: any) => {
    let users = userStore.users
    if (searchKey) {
      users = users.filter((property: any) => property?.nameFi?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    if (previewFilters) {
      if (previewFilters?.status) {
        users = users.filter((user: any) => user?.status === previewFilters?.status)
      }
      if (previewFilters?.role) {
        users = users.filter((user: any) => user?.role === previewFilters?.role)
      }
    }
    return users?.length
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'name',
      headerName: t('user_name'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={`${params.row.firstName} ${params.row.lastName}`.trim()}
          onClick={() => toUser(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'role',
      headerName: t('user_level'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip text={params.row.email} href email />
      )
    },
    {
      field: 'status',
      headerName: t('state'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'lastActiveAt',
      headerName: t('last_login_at'),
      flex: 1,
      maxWidth: 280,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let users = userStore.users
    if (searchKey) {
      users = users.filter((user: any) => `${user?.firstName} ${user?.lastName}`?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    if (filters) {
      if (filters?.status) {
        users = users.filter((user: any) => user?.status === filters?.status)
      }
      if (filters?.role) {
        users = users.filter((user: any) => user?.role === filters?.role)
      }
    }
    return users
  }, [userStore.users, searchKey, filters])

  const renderFiltersModal = () => {
    if (showFiltersModal) {
      return (
        <FilterUsersModal
          filters={filters}
          onClose={closeFiltersModal}
          updateFilters={updateFilters}
          clearFilters={clearFilters}
          getUserCount={getUserCount}
          viewerRole={me?.role}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        filterCount={Object.keys(filters)?.length}
        onBulkActionSelect={selectBulkAction}
        bulkActionOptions={bulkActionOptions}
        bulkAction={bulkAction}
        onAddClick={toCreateUser}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderFiltersModal()}
    </Box>
  )
}

export default observer(Users)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: {
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  }
} as const
