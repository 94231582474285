import { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import logo from '../../Assets/Icons/logo.svg'
import ArrowDown from '../../Assets/Icons/arrow-down.svg'
import Link from '@mui/material/Link'
import { Colors } from '../../Utils/theme'

const Sidebar = (props: any) => {
  const { t } = useTranslation()
  const currentLocation = useLocation()

  const [openedSubMenus, setOpenedSubMenus] = useState<string[]>([])

  const toggleSubMenu = (parent: any) => {
    let newOpenedSubMenus = openedSubMenus.filter(item => item !== parent)
    if (newOpenedSubMenus.length === openedSubMenus.length) {
      newOpenedSubMenus = [...newOpenedSubMenus, parent]
    }
    setOpenedSubMenus(newOpenedSubMenus)
  }

  const renderItemIcon = (icon: any) => {
    if (icon) {
      return (
        <ListItemIcon sx={{ root: styles.icon }}>
          <Box
            component='img'
            src={icon}
            sx={styles.itemIcon}
            alt='icon'
          />
        </ListItemIcon>
      )
    }
    return null
  }

  const renderItemText = (text: any, isActive: boolean) => {
    return (
      <ListItemText
        primary={t(text)}
        sx={{ primary: isActive ? styles.activeItemText : styles.itemText }}
      />
    )
  }

  const renderSubItemText = (text: any) => {
    return <ListItemText primary={t(text)} sx={{ primary: styles.subItemText }} />
  }

  const onNotificationsClick = () => props.toggleNotifications()

  const renderChildItems = (item: any) => {
    if (item?.children) {
      return (
        <Collapse in={openedSubMenus.includes(item.url)} timeout='auto' unmountOnExit>
          {item.children.map((child: any) => {
            return (
              <ListItemButton key={child.url} title={t(item.text)} disableGutters>
                <Link key={child.url} href={child.url} sx={styles.subLink}>
                  {renderSubItemText(child.text)}
                </Link>
              </ListItemButton>
            )
          })}
        </Collapse>
      )
    }
    return null
  }

  const renderItems = (items: any) => {
    return items.map((item: any) => {
      const isActive = !!(currentLocation.pathname || '').slice(0, 6).includes((item.url || '').slice(0, 6))
      if (item?.children) {
        return (
          <Fragment key={item.url}>
            <ListItemButton
              title={t(item.text)}
              disableGutters
            >
              <Box sx={styles.link} onClick={() => toggleSubMenu(item.url)}>
                {renderItemIcon(item.icon)}
                {renderItemText(item.text, isActive)}
                <Box component='img' src={ArrowDown} sx={styles.arrowDown} />
              </Box>
            </ListItemButton>
            {renderChildItems(item)}
          </Fragment>
        )
      }

      if (item?.text === 'notifications') {
        return (
          <ListItemButton
            key={item.text}
            title={t(item.text)}
            onClick={onNotificationsClick}
            disableGutters
          >
            <Box sx={styles.link}>
              {renderItemIcon(item.icon)}
              {renderItemText(item.text, isActive)}
            </Box>
          </ListItemButton>
        )
      }

      return (
        <ListItemButton
          key={item.url}
          title={t(item.text)}
          disableGutters
        >
          <Link href={item.url} sx={styles.link}>
            {renderItemIcon(item.icon)}
            {renderItemText(item.text, isActive)}
          </Link>
        </ListItemButton>
      )
    })
  }

  const renderContent = () => {
    return (
      <Box sx={props?.permanentDrawer ? styles.drawerInner : styles.drawerInnerMobile}>
        <Box sx={styles.logoContainer}>
          <Link href='/dash'>
            <Box component='img' src={logo} alt='logo' sx={styles.logo} />
          </Link>
        </Box>
        <List sx={styles.primaryItemsList} disablePadding>
          {renderItems(props.primaryNavigationItems)}
        </List>
        <Box sx={styles.flex} />
        <List sx={styles.secondaryItemsList} disablePadding>
          {renderItems(props.secondaryNavigationItems)}
        </List>
      </Box>
    )
  }

  if (props?.permanentDrawer) {
    return (
      <Drawer variant='permanent' sx={styles.drawer}>
        {renderContent()}
      </Drawer>
    )
  } else {
    return (
      <Drawer
        variant='temporary'
        open={props.open}
        onClose={props.toggleDrawer}
        ModalProps={{ keepMounted: true }}
        sx={styles.drawer}
      >
        {renderContent()}
      </Drawer>
    )
  }
}

export default Sidebar

const styles = {
  drawer: {
    height: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 1203
  },
  drawerInner: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    minWidth: {
      xxxl: '20rem',
      xs: '17rem'
    },
    background: Colors.primary,
    pl: '1rem',
    pr: '1rem'
  },
  drawerInnerMobile: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: '100vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    background: Colors.primary,
    pl: '1rem',
    pr: '1rem'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: {
      xxxl: '13rem',
      xxl: '10.75rem',
      xs: '9rem'
    },
    pb: {
      xxxl: '1.25rem',
      xs: '0.75rem'
    }
  },
  logo: {
    width: {
      xxxl: '14rem',
      xxl: '12rem',
      xs: '10rem'
    },
    height: 'auto',
    '&:hover': {
      opacity: 0.8,
      transition: 'opacity 0.3s'
    }
  },
  flex: {
    flex: 1
  },
  primaryItemsList: {
    borderTop: `1px solid ${Colors.white20}`,
    pt: {
      xxxl: '1.5rem',
      xs: '1.125rem'
    }
  },
  secondaryItemsList: {
    borderTop: `1px solid ${Colors.white20}`,
    pt: {
      xxxl: '1.5rem',
      xs: '1.125rem'
    },
    pb: {
      xxxl: '2rem',
      xs: '1.5rem'
    }
  },
  listItem: {
    position: 'relative',
    height: '2.5rem'
  },
  subLink: {
    width: '100%',
    height: '1.75rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.75rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  link: {
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkClosed: {
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: '1.875rem'
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontWeight: 700,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  activeItemText: {
    fontWeight: 700,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  subItemText: {
    fontSize: '0.875rem'
  },
  itemIcon: {
    marginLeft: '0.25rem',
    marginRight: '0.875rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '1.5rem'
  },
  arrowDown: {
    position: 'absolute',
    right: '1rem',
    height: '1rem'
  }
} as const