import { useMemo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '../../../Components/Common/Chip'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Tabs from '../../../Components/Common/Tabs'
import Tab from '../../../Components/Common/Tab'
import Areas from '../Areas/Areas'
import { Button, Input, Select, CoverImage } from '../../../Components'
import { AreaLayout } from '../../../Utils/area'
import { BuildingYearOptions, FinnishMunicipalities, PropertyEditTabs, PropertyTypes } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const EditProperty = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const { sessionStore, propertyStore, areaStore }: any = useStore()
  const { me } = sessionStore
  const { property } = propertyStore
  const { areas } = areaStore

  const saveAreasRef = useRef<any>(null)
  
  // General
  const [tab, setTab] = useState(PropertyEditTabs.Property)

  // Property
  const [nameFi, setNameFi] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [type, setType] = useState(PropertyTypes.Condominium)
  const [buildingYear, setBuildingYear] = useState('')
  const [propertyId, setPropertyId] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [propertyManager, setPropertyManager] = useState<any>(null)

  // Confirmation dialog (property)
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (!property || property?.uuid !== uuid) {
        propertyStore.getProperty(uuid)
      }
    }
  }, [])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (property?.uuid === uuid) {
        setNameFi(property?.nameFi || '')
        setBusinessId(property?.businessId || '')
        setType(property?.type || PropertyTypes.Condominium)
        setBuildingYear(property?.buildingYear || '')
        setPropertyId(property?.propertyId || '')
        setAddress(property?.address || '')
        setPostalCode(property?.postalCode || '')
        setCity(property?.city || '')
        setPropertyManager(property?.propertyManager || null)
      }
    }
  }, [property])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === 'areas') setTab(PropertyEditTabs.Areas)
    }
  }, [location])

  const Title = useMemo(() => {
    if (tab === PropertyEditTabs.Property) {
      return t('edit_property')
    } else if (tab === PropertyEditTabs.Areas) {
      return t('edit_areas')
    }
  }, [tab])

  const getPropertyTypeOptions = () => {
    return [
      { value: PropertyTypes.Condominium, label: t(PropertyTypes.Condominium) }
    ]
  }
  const getPostalCodeOptions = () => []
  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }
  const getPropertyManagerOptions = () => {
    return []
  }

  const isSaveDisabled = () => {
    if (!nameFi || !businessId) {
      return true
    }
    return false
  }

  const changeBackgroundPhoto = (photo: any) => {
    propertyStore.updatePropertyBackgroundPhoto(property.uuid, photo)
  }

  const save = () => {
    if (tab === PropertyEditTabs.Property) {
      const updatedProperty = {
        nameFi,
        businessId,
        type,
        buildingYear,
        propertyId,
        address,
        postalCode,
        city
      }
      propertyStore.updateProperty(property?.uuid, updatedProperty)
    } else if (tab === PropertyEditTabs.Areas) {
      saveAreasRef.current.onSave()
    }
  }
  const archive = () => {
    closeActionConfirmation()
  }

  const renderTabContent = () => {
    if (tab === PropertyEditTabs.Property) {
      return (
        <>
          <Typography variant='h2' mt='1.5rem' mb='1.5rem'>{t('property_information')}</Typography>
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Input
                label={t('property_name')}
                value={nameFi}
                onChange={setNameFi}
                maxWidth='30rem'
                mr='2rem'
              />
              <Input
                label={t('business_id')}
                value={businessId}
                onChange={setBusinessId}
                maxWidth='16rem'
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('property_type')}
                options={getPropertyTypeOptions()}
                value={type}
                onChange={setType}
                maxWidth='16rem'
                mr='2rem'
              />
              <Select
                label={t('building_year')}
                options={BuildingYearOptions}
                value={buildingYear}
                onChange={setBuildingYear}
                maxWidth='16rem'
                mr='2rem'
              />
              <Input
                label={t('property_id')}
                value={propertyId}
                onChange={setPropertyId}
                maxWidth='24rem'
              />
            </Box>
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('location')}</Typography>
          <Box sx={styles.formContainer}>
            <Input
              label={t('address')}
              value={address}
              onChange={setAddress}
              maxWidth='30rem'
              mb='1.5rem'
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={postalCode}
                onChange={setPostalCode}
                maxWidth='12rem'
                mr='2rem'
              />
              <Select
                label={t('city_or_municipality')}
                options={getCityOptions()}
                value={city}
                onChange={setCity}
                maxWidth='30rem'
              />
            </Box>
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('property_manager')}</Typography>
          <Box sx={styles.formContainer}>
            <Select
              label={t('property_manager')}
              options={getPropertyManagerOptions()}
              value={propertyManager}
              onChange={setPropertyManager}
              maxWidth='30rem'
              mb='1.5rem'
            />
          </Box>
        </>
      )
    } else if (tab === PropertyEditTabs.Areas) {
      return <Areas uuid={params?.uuid || null} saveRef={saveAreasRef} />
    }
    return null
  }

  const renderArchiveButton = () => {
    if (tab === PropertyEditTabs.Property) {
      return (
        <Button
          text={t('archive_property')}
          onClick={openArchiveConfirmation}
          variant='text'
          width='100%'
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_property_title')}
          content={t('archive_property_info')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archive}
          actionText={t('archive')}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <CoverImage
          bgPhotoUrl={property?.backgroundPhoto}
          // onUpdateBackgroundPhoto={changeBackgroundPhoto}
        />
        <Typography variant='h2' textTransform='uppercase' mt='1rem'>{Title}</Typography>
        <Box sx={styles.infoRow}>
          <Chip text={property?.nameFi || '-'} />
        </Box>
        <Tabs>
          <Tab
            value={PropertyEditTabs.Property}
            text={t(PropertyEditTabs.Property)}
            selected={tab === PropertyEditTabs.Property}
            onClick={setTab}
            first
          />
          <Tab
            value={PropertyEditTabs.Areas}
            text={t(PropertyEditTabs.Areas)}
            selected={tab === PropertyEditTabs.Areas}
            onClick={setTab}
            count={0}
          />
        </Tabs>
        {renderTabContent()}
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={isSaveDisabled()}
          />
          {renderArchiveButton()}
        </Box>
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(EditProperty)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    marginRight: '3rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
