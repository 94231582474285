import { GridColDef } from '@mui/x-data-grid'
import i18n from '../Localization'
import { RoomSectionSubSections, RoomSections } from './room'

export const RoomTableSections: any = [
  RoomSections.SurfaceMaterials,
  RoomSections.Windows,
  RoomSections.Doors,
  RoomSections.SocketsAndLighting,
  RoomSections.Ventilation,
  RoomSections.Heating,
  { category: RoomSections.Fixtures, section: 'water_point' },
  { category: RoomSections.Fixtures, section: 'sink' },
  { category:  RoomSections.Fixtures, section: 'shower_cabin' },
  { category: RoomSections.Fixtures, section: 'bathtub' },
  { category: RoomSections.Fixtures, section: 'toilet' },
  { category: RoomSections.Fixtures, section: 'floor_drain' },
  { category: RoomSections.Fixtures, section: 'water_heater' },
  { category: RoomSections.Fixtures, section: 'fridge' },
  { category: RoomSections.Fixtures, section: 'freezer' },
  { category: RoomSections.Fixtures, section: 'stove' },
  { category: RoomSections.Fixtures, section: 'oven' },
  { category: RoomSections.Fixtures, section: 'smoke_detector' },
  { category: RoomSections.Fixtures, section: 'sauna_stove' },
  { category: RoomSections.OtherFixtures, section: 'hearth' }
]

const SurfaceMaterialColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('wall_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'wall' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('walls') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('wall_material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'walls' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('walls') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value.replace('walls_', '').trim()
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('ceiling_material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ceilingMaterial = row?.['ceiling_material'] || ''
      return ceilingMaterial ? i18n.t(ceilingMaterial) : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('floor_material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const floorMaterial = row?.['floor_material'] || ''
      return floorMaterial ? i18n.t(floorMaterial) : '-'
    }
  }
]

const WindowColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('window_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('window') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('window_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('window') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value.replace('windows_', '').trim()
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('air_filter'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const airFilter = row?.['window_air_filter'] || ''
      return airFilter ? i18n.t(airFilter) : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('window_directions'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const windowDirections = row?.['window_directions'] || ''
      return windowDirections ? i18n.t(windowDirections) : '-'
    }
  }
]

const DoorColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('door_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('door') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('door_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('door') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('door_has_window'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasWindow = row?.['door_has_window'] || ''
      return hasWindow ? i18n.t(hasWindow) : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('door_has_lock'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasLock = row?.['door_has_lock'] || ''
      return hasLock ? i18n.t(hasLock) : '-'
    }
  }
]

const SocketsAndLightingColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('sockets'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('socket') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('light_switches'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('switch') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('light_sockets'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('light_socket') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  }
]

const VentilationColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('ventilation_exhaust'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasExhaust = row?.['ventilation_exhaust_has_exhaust'] || ''
      return hasExhaust ? i18n.t(hasExhaust) : '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('ventilation_replacement'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasReplacement = row?.['ventilation_replacement_has_replacement'] || ''
      return hasReplacement ? i18n.t(hasReplacement) : '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('ventilation_supply'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasSupply = row?.['ventilation_supply_has_supply'] || ''
      return hasSupply ? i18n.t(hasSupply) : '-'
    }
  }
]

const HeatingColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('underfloor_heating'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const type = row?.['heating_underfloor_type'] || ''
      return type ? i18n.t(type) : '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('heating_radiator'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const type = row?.['heating_radiator_type'] || ''
      return type ? i18n.t(type) : '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('heating_distribution'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const distributionStatus = row?.['heating_distribution_working'] || ''
      return distributionStatus ? i18n.t('heating_distribution_working') : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('heating_valve'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const valveStatus = row?.['heating_valve_working'] || ''
      return valveStatus ? i18n.t('heating_valve_working') : '-'
    }
  }
]

const WaterPointColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('water_point_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('water_point_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('water_point_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('water_point_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const material = row?.['water_point_material_type'] || ''
      return material ? i18n.t(material) : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('water_point_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['water_point_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const SinkColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('sink_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('sink_material') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('sink_material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('sink_material') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('sink_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['sink_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const ShowerCabinColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('has_sink'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const hasSink = row?.['shower_cabin_has_sink'] || ''
      return hasSink ? i18n.t(hasSink) : '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('shower_cabin_door_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const doorType = row?.['shower_cabin_door_type'] || ''
      return doorType ? i18n.t(doorType) : '-'
    }
  }
]

const BathTubColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('bathtub_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const withBubbles = row?.['bathtub_type_with_bubbles'] || ''
      return withBubbles ? i18n.t(withBubbles) : '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('bathtub_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['bathtub_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const ToiletColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('toilet_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('toilet_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('toilet_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('toilet_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('toilet_material'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const material = row?.['toilet_material_type'] || ''
      return material ? i18n.t(material) : '-'
    }
  },
  {
    field: 'id4',
    headerName: i18n.t('toilet_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['toilet_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const FloorDrainColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('floor_drain_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('floor_drain_material') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('floor_drain_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('floor_drain_material') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('floor_drain_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['floor_drain_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const WaterHeaterColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('water_heater_volume'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const volume = row?.['water_heater_info_volume'] || ''
      return volume || '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('water_heater_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['water_heater_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const FridgeColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('fridge_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('fridge_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('fridge_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('fridge_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('fridge_energy_class'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const energyClass = row?.['fridge_energy_class'] || ''
      return energyClass ? energyClass?.toUpperCase() : '-'
    }
  }
]

const FreezerColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('freezer_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('freezer_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('freezer_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('freezer_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('freezer_energy_class'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const energyClass = row?.['freezer_energy_class'] || ''
      return energyClass ? energyClass?.toUpperCase() : '-'
    }
  }
]

const StoveColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('stove_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('stove_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('stove_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('stove_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('stove_energy_class'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const energyClass = row?.['stove_energy_class'] || ''
      return energyClass ? energyClass?.toUpperCase() : '-'
    }
  }
]

const OvenColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('oven_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('oven_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('oven_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('oven_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('oven_energy_class'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const energyClass = row?.['oven_energy_class'] || ''
      return energyClass ? energyClass?.toUpperCase() : '-'
    }
  }
]

const SmokeDetectorColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('smoke_detector_count'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const quantity = row?.['smoke_detector_count_notes'] || ''
      return quantity || '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('smoke_detector_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['smoke_detector_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const SaunaStoveColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('sauna_stove_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const type = row?.['sauna_stove_type'] || ''
      return type ? i18n.t(type) : '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('sauna_stove_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['sauna_stove_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

const HearthColumns: GridColDef[] = [
  {
    field: 'id1',
    headerName: i18n.t('hearth_quantity'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Find all fields that contain 'window' and 'quantity' in the key and sum them
      if (row) {
        const quantities = Object.keys(row)
          .filter((key: string) => key?.includes('hearth_type') && key?.includes('quantity'))
          .map((key: string) => row?.[key])
          .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        return quantities ? `${quantities} ${i18n.t('pcs')}` : '-'
      }
      return '-'
    }
  },
  {
    field: 'id2',
    headerName: i18n.t('hearth_type'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      // Get all fields that have 'window' and 'quantity' in the key
      // and remove the 'quantity' part from the key and use the new key
      // to get the material value
      if (row) {
        const materials = Object.keys(row)
          .filter((key: string) => key?.includes('hearth_type') && key?.includes('quantity'))
          .map((key: string) => key.replace('_quantity', '').trim())
          .map((key: string) => row?.[key] ? key : '')
          .map((value: string) => {
            if (value) {
              const i18nKey = value
              const count = row?.[`${value}_quantity`] || 0
              if (i18nKey && count) {
                return `${i18n.t(i18nKey)} (${count} ${i18n.t('pcs')})`
              }
            }
            return null
          })
          .filter((value: any) => value)
          .join(', ')

        return materials || '-'
      }
      return '-'
    }
  },
  {
    field: 'id3',
    headerName: i18n.t('hearth_age'),
    flex: 1,
    headerClassName: 'kiinteistomuisti-table--header',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (value, row) => {
      const ageType = row?.['hearth_age_type'] || ''
      return ageType ? i18n.t(ageType) : '-'
    }
  }
]

export const RoomColumns: any = {
  [RoomSections.SurfaceMaterials]: SurfaceMaterialColumns,
  [RoomSections.Windows]: WindowColumns,
  [RoomSections.Doors]: DoorColumns,
  [RoomSections.SocketsAndLighting]: SocketsAndLightingColumns,
  [RoomSections.Ventilation]: VentilationColumns,
  [RoomSections.Heating]: HeatingColumns,
  // TODO: fix these columns
  [RoomTableSections[6].section]: WaterPointColumns,
  [RoomTableSections[7].section]: SinkColumns,
  [RoomTableSections[8].section]: ShowerCabinColumns,
  [RoomTableSections[9].section]: BathTubColumns,
  [RoomTableSections[10].section]: ToiletColumns,
  [RoomTableSections[11].section]: FloorDrainColumns,
  [RoomTableSections[12].section]: WaterHeaterColumns,
  [RoomTableSections[13].section]: FridgeColumns,
  [RoomTableSections[14].section]: FreezerColumns,
  [RoomTableSections[15].section]: StoveColumns,
  [RoomTableSections[16].section]: OvenColumns,
  [RoomTableSections[17].section]: SmokeDetectorColumns,
  [RoomTableSections[18].section]: SaunaStoveColumns,
  [RoomTableSections[19].section]: HearthColumns
}
