import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import camelCase from 'lodash/camelCase'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FileUpload from '../../../Components/Common/FileUpload'
import File from '../../../Components/Common/File'
import Lightbox from '../../../Components/Common/Lightbox'
import Table from '../../../Components/Common/Table'
import { CoverImage, Tabs, Tab, Chip } from '../../../Components'
import { RoomLayout } from '../../../Utils/room'
import { RoomTabs } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'
import { RoomColumns, RoomTableSections } from '../../../Utils/roomTables'

const Room = (props: any) => {
  const { sessionStore, apartmentStore, roomStore }: any = useStore()
  const { me } = sessionStore
  const { apartment } = apartmentStore
  const { room, loading } = roomStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [tab, setTab] = useState(RoomTabs.GeneralInformation)
  const [selectedImage, setSelectedImage] = useState(false)

  const clearSelectedImage = () => setSelectedImage(false)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const aUuid = params?.aUuid || null
    const rUuid = params?.rUuid || null
    if (pUuid && aUuid && rUuid && pUuid?.length === 36 && aUuid?.length === 36 && rUuid?.length === 36) {
      roomStore.getRoom(pUuid, aUuid, rUuid)
    }
  }, [])

  useEffect(() => {
    if (tab === RoomTabs.Attachments) {
      roomStore.getRoomFiles(params?.pUuid, params?.aUuid)
    } else if (tab === RoomTabs.Reminders) {
      // roomStore.getRoomReminders()
    }
  }, [tab])

  const updateRoomBackgroundPhoto = (file: any) => {
    roomStore.updateRoomBackgroundPhoto(params?.pUuid, params?.aUuid, file?.uuid)
  }

  const downloadFile = async (file: any) => {
    // TODO: image download
    window.open(file?.url, '_blank')
  }

  const archiveFile = (file: any) => roomStore.archiveRoomFile(params?.pUuid, params?.aUuid, file?.uuid)

  const getFileActionOptions = (file: any) => {
    if (file?.type === 'image') {
      if (file?.url === room?.backgroundPhoto) {
        return [
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      } else {
        return [
          { text: t('set_as_main_photo'), onClick: updateRoomBackgroundPhoto },
          { text: t('download_file'), onClick: downloadFile },
          { text: t('delete_file'), onClick: archiveFile }
        ]
      }
    }
    return [
      { text: t('download_file'), onClick: downloadFile },
      { text: t('delete_file'), onClick: archiveFile }
    ]
  }

  const getRoomMenuOptions = () => {
    return [
      { text: t('edit_room'), onClick: toEditRoom }
    ]
  }

  const toEditRoom = () => navigate(`${location.pathname}/edit`)
  const uploadFiles = (files: any) => {
    roomStore.uploadRoomFiles(params?.pUuid, params?.aUuid, files)
  }
  const onFileClick = (file: any) => {
    if (file?.type === 'image') {
      setSelectedImage(file?.url)
    }
  }

  const RoomSectionColumns = useCallback((section: string) => {
    return RoomColumns?.[section] || []
  }, [room])

  const RoomSectionRows = useCallback((section: any) => {
    const roomSection = room?.[camelCase(section?.category || section)] || null
    if (roomSection && Object.keys(roomSection)?.length) {
      const row = { ...roomSection }
      const columnCount = RoomColumns?.[section?.section || section]?.length || 0
      for (let i = 1; i < columnCount + 1; i++) {
        if (!row?.id) {
          row.id = section
        }
        row[`id${i}`] = i
      }
      return [row]
    }
    return []
  }, [room])

  const RoomSections = useMemo(() => {
    return RoomTableSections
      .filter((item: any) => !((room?.hiddenItems || []).includes(item?.category || item)))
      .map((item: any) => {
        const columns = RoomSectionColumns(item?.section || item)
        const rows = RoomSectionRows(item)
        if (columns?.length && rows?.length) {
          return (
            <Table
              key={item?.section || item}
              title={t(item?.section || item)}
              columns={columns}
              rows={rows}
              hideCheckboxSelection
            />
          )
        }
        return null
      })
  }, [room])

  const renderAttachments = () => {
    return roomStore.files.map((file: any) => (
      <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
        <File
          key={file.uuid}
          file={file}
          menuOptions={getFileActionOptions(file)}
          onFileClick={onFileClick}
        />
      </Grid>
    ))
  }

  const renderTabContent = () => {
    if (tab === RoomTabs.GeneralInformation) {
      return RoomSections
    } else if (tab === RoomTabs.Attachments) {
      return (
        <>
          <Grid
            container
            columns={12}
            columnSpacing={{ xs: 0, sm: 2, md: 3, xl: 4, xxl: 5, xxxl: 6 }}
            rowSpacing='1.5rem'
            mt='1.5rem'
          >
            <Grid xs={12} sm={6} md={4} xl={3} xxl={12/5} xxxl={2}>
              <FileUpload onUploadFiles={uploadFiles} loading={loading} />
            </Grid>
            {renderAttachments()}
          </Grid>
        </>
      )
    } else if (tab === RoomTabs.Reminders) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('reminders')}</Typography>
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (roomStore?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            roomStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            roomStore?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  if (
    !room ||
    !params?.pUuid ||
    !params?.aUuid ||
    !params?.rUuid ||
    params.pUuid?.length !== 36 ||
    params.aUuid?.length !== 36 ||
    params.rUuid?.length !== 36
  ) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage bgPhotoUrl={room?.backgroundPhoto} />
      <Typography variant='h2' textTransform='uppercase' mt='1rem'>
        {t(room.type) || ''}
        <Box component='span' textTransform='lowercase' fontWeight={500}>
          {room?.areaSize ? ` (${room?.areaSize}m²)` : ''}
        </Box>
      </Typography>
      <Box sx={styles.infoRow}>
        <Chip text={room?.apartment?.property?.nameFi || ''} />
        <Box sx={styles.dot} />
        <Chip text={room?.apartment?.nameFi || ''} />
      </Box>
      <Tabs onEditClick={toEditRoom} menuOptions={getRoomMenuOptions()}>
        <Tab
          value={RoomTabs.GeneralInformation}
          text={t(RoomTabs.GeneralInformation)}
          selected={tab === RoomTabs.GeneralInformation}
          onClick={setTab}
          first
        />
        <Tab
          value={RoomTabs.Attachments}
          text={t(RoomTabs.Attachments)}
          selected={tab === RoomTabs.Attachments}
          onClick={setTab}
          count={0}
        />
        <Tab
          value={RoomTabs.Reminders}
          text={t(RoomTabs.Reminders)}
          selected={tab === RoomTabs.Reminders}
          onClick={setTab}
          count={0}
        />
      </Tabs>
      {renderTabContent()}
      {renderLightbox()}
    </Box>
  )
}

export default observer(Room)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '60rem',
    marginBottom: '3rem'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    // width: 'calc(50% - 1rem)',
    maxWidth: '30rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  }
} as const
