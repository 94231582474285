import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridColDef, GridRenderCellParams, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { Button, CoverImage, Tabs, Tab, Chip, Table } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { UserRoles, UserTabs } from '../../../Utils/constants'

const User = (props: any) => {
  const { sessionStore, userStore }: any = useStore()
  const { me } = sessionStore
  const { user, loading } = userStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [tab, setTab] = useState(UserTabs.GeneralInformation)

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (!user || user?.uuid !== uuid) {
        userStore.getUser(uuid)
      }
    }
  }, [])

  const propertyColumns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'nameFi',
      headerName: t('property_name'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'businessId',
      headerName: t('business_id'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'city',
      headerName: t('city'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'propertyManager',
      headerName: t('property_manager'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    }
  ], [])

  const apartmentColumns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'name',
      headerName: t('apartment'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'address',
      headerName: t('address'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'area',
      headerName: t('area'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ], [])

  const eventColumns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'type',
      headerName: t('event'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'createdAt',
      headerName: t('event_date'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'ipAddress',
      headerName: t('ip_address'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip text={params.row.email} href email />
      )
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    }
  ], [])

  const propertyRows = useMemo(() => user?.properties || [], [user?.properties])
  const apartmentRows = useMemo(() => user?.apartments || [], [user?.apartments])
  const eventRows = useMemo(() => userStore?.userEvents, [userStore.userEvents])
  
  const getUserMenuOptions = () => {
    return [
      { text: t('edit_user'), onClick: toEditUser },
      // { text: t('archive_user'), onClick: () => {} }
    ]
  }

  const toEditUser = () => navigate(`${location.pathname}/edit`)

  if (!user) {
    return null
  }

  const renderProperties = () => {
    if (user?.role === UserRoles.PropertyManager) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('properties')}</Typography>
          <Table rows={propertyRows} columns={propertyColumns} loading={loading} />
        </>
      )
    }
    return null
  }
  const renderApartments = () => {
    if (user?.role === UserRoles.Shareholder) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>
            {user?.uuid === me?.uuid ? t('your_apartments') : t('apartments')}
          </Typography>
          <Table rows={apartmentRows} columns={apartmentColumns} loading={loading} />
        </>
      )
    }
    return null
  }

  const renderTabContent = () => {
    if (tab === UserTabs.GeneralInformation) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('contact_information')}</Typography>
          <Box sx={styles.row}>
            <Box sx={styles.infoContainer} mr='2rem'>
              <Typography variant='body1' sx={styles.infoLabel}>{t('email_address')}</Typography>
              <Chip text={user?.email} href email />
            </Box>
            <Box sx={styles.infoContainer}>
              <Typography variant='body1' sx={styles.infoLabel}>{t('phone_number')}</Typography>
              <Box>
                <Chip text={user?.phone} href={user?.phone || null} phone />
              </Box>
            </Box>
          </Box>
          {renderProperties()}
          {renderApartments()}
        </>
      )
    } else if (tab === UserTabs.Events) {
      return (
        <>
          <Typography variant='h2' mt='2rem' mb='1.5rem'>{t('events')}</Typography>
          <Table rows={eventRows} columns={eventColumns} loading={loading} />
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={user?.backgroundPhoto}
        profilePhotoUrl={user?.profilePhoto}
        profile
      />
      <Typography variant='h2' textTransform='uppercase' mt='1rem'>
        {user?.firstName || ''} {user?.lastName || ''}
      </Typography>
      <Box sx={styles.infoRow}>
        <Typography variant='body1'>{t(user.role)}</Typography>
        <Box sx={styles.dot} />
        <Chip text={t(user.status)} />
      </Box>
      <Tabs onEditClick={toEditUser} menuOptions={getUserMenuOptions()}>
        <Tab
          value={UserTabs.GeneralInformation}
          text={t(UserTabs.GeneralInformation)}
          selected={tab === UserTabs.GeneralInformation}
          onClick={setTab}
          first
        />
        <Tab
          value={UserTabs.Events}
          text={t(UserTabs.Events)}
          selected={tab === UserTabs.Events}
          onClick={setTab}
        />
      </Tabs>
      {renderTabContent()}
    </Box>
  )
}

export default observer(User)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    pb: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '45rem',
    justifyContent: 'space-between'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  }
} as const
