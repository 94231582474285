import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Input, Select } from '../../../Components'
import { ApartmentTypes } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const CreateApartment = (props: any) => {
  const { sessionStore, apartmentStore, buildingStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [nameFi, setNameFi] = useState('')
  const [type, setType] = useState('')
  const [areaSize, setAreaSize] = useState(null)
  const [address, setAddress] = useState('')
  const [building, setBuilding] = useState<any>(null)

  useEffect(() => {
    const pUuid = params?.pUuid || null
    if (pUuid && pUuid?.length === 36) {
      buildingStore.getBuildings(pUuid)
    }
  }, [])

  const uuid = params?.pUuid || null
  if (!uuid || uuid?.length !== 36) {
    return null
  }

  const getApartmentTypeOptions = () => {
    return [
      { value: ApartmentTypes.OneRoomPlusKitchen, label: t(ApartmentTypes.OneRoomPlusKitchen) },
      { value: ApartmentTypes.TwoRoomsPlusKitchen, label: t(ApartmentTypes.TwoRoomsPlusKitchen) },
      { value: ApartmentTypes.ThreeRoomsPlusKitchen, label: t(ApartmentTypes.ThreeRoomsPlusKitchen) },
      { value: ApartmentTypes.FourRoomsPlusKitchen, label: t(ApartmentTypes.FourRoomsPlusKitchen) },
      { value: ApartmentTypes.FiveRoomsPlusKitchen, label: t(ApartmentTypes.FiveRoomsPlusKitchen) },
      { value: ApartmentTypes.OverFiveRoomsPlusKitchen, label: t(ApartmentTypes.OverFiveRoomsPlusKitchen) }
    ]
  }
  
  const getBuildingOptions = () => {
    return buildingStore.buildings?.map((building: any) => {
      return { value: building.id, label: building.nameFi }
    })
  }

  const isSendDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const create = () => {
    const callback = () => {
      setNameFi('')
      setType('')
      setAreaSize(null)
      setAddress('')
      setBuilding(null)
    }
    const newApartment = {
      nameFi,
      type,
      areaSize,
      address,
      buildingId: building || null
    }
    apartmentStore.createApartment(params?.pUuid, newApartment, callback)
  }
  const cancel = () => navigate(-1)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mb='1.5rem'>{t('apartment_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('apartment_name')}
            value={nameFi}
            onChange={setNameFi}
            maxWidth='46rem'
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Select
              label={t('building')}
              options={getBuildingOptions()}
              value={building}
              onChange={setBuilding}
              maxWidth='22rem'
              mr='2rem'
            />
            <Input
              label={t('address')}
              value={address}
              onChange={setAddress}
              maxWidth='22rem'
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('apartment_type')}
              options={getApartmentTypeOptions()}
              value={type}
              onChange={setType}
              maxWidth='16rem'
              mr='2rem'
            />
            <Input
              label={t('area')}
              value={areaSize}
              onChange={setAreaSize}
              maxWidth='10rem'
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_apartment')}
            onClick={create}
            width='100%'
            mb='1rem'
            disabled={isSendDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateApartment)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '55rem',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
