import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { Button } from '../../../Components'
import BuildingsIcon from '../../../Assets/Icons/buildings.svg'
import { Colors, Fonts } from '../../../Utils/theme'

const Dashboard = (props: any) => {
  const { sessionStore, userStore }: any = useStore()
  const { me, showInfoBanner } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const closeInfoBanner = () => sessionStore.setShowInfoBanner(false)

  const renderShowInfo = () => {
    return (
      <Collapse in={showInfoBanner} unmountOnExit>
        <Box sx={styles.infoContainer}>
          <Box sx={styles.infoContentContainer}>
            <Typography variant='h2' mb='1rem'>{t('welcome')}, {me?.firstName || '?'}!</Typography>
            <Typography variant='body1'>
              Sed posuere consectetur est at lobortis. Etiam porta sem malesuada magna mollis euismod.
              Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
              Donec ullamcorper nulla non metus auctor fringilla.
            </Typography>
            <Button
              onClick={() => {}}
              sx={styles.button}
              text={t('view_presentation')}
              iconRight='play'
            />
          </Box>
          <Box component='img' src={BuildingsIcon} sx={styles.infoIcon} />
          <Button
            onClick={closeInfoBanner}
            sx={styles.closeButton}
            text={`X ${t('close')}`}
          />
        </Box>
      </Collapse>
    )
  }

  const renderPropertySummary = () => {
    return (
      <Box sx={styles.summaryContainer}>
        <Typography variant='h4'>{t('properties')}</Typography>
        <Typography sx={styles.summaryValue} variant='body1'>3</Typography>
        <Typography sx={styles.summaryInfo} variant='body1'>{t('new_added')}</Typography>
      </Box>
    )
  }

  const renderReminderSummary = () => {
    return (
      <Box sx={styles.summaryContainer}>
        <Typography variant='h4'>{t('reminders')}</Typography>
        <Typography sx={styles.summaryValue} variant='body1'>15</Typography>
        <Typography sx={styles.summaryInfo} variant='body1'>{t('new_added')}</Typography>
      </Box>
    )
  }

  const renderUserSummary = () => {
    return (
      <Box sx={styles.summaryContainer}>
        <Typography variant='h4'>{t('users')}</Typography>
        <Typography sx={styles.summaryValue} variant='body1'>8</Typography>
        <Typography sx={styles.summaryInfo} variant='body1'>{t('new_added')}</Typography>
      </Box>
    )
  }

  const renderActionsInProgress = () => {
    return (
      <Box sx={styles.dataContainer}>
        <Typography variant='h3' mb='1rem'>{t('actions_in_progress')}</Typography>
        <Typography variant='body1'>
          Sed posuere consectetur est at lobortis. Etiam porta sem malesuada magna mollis euismod.
          Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          Donec ullamcorper nulla non metus auctor fringilla.
        </Typography>
      </Box>
    )
  }

  const renderUpcomingReminders = () => {
    return (
      <Box sx={styles.dataContainer}>
        <Typography variant='h3' mb='1rem'>{t('nearing_reminders')}</Typography>
        <Typography variant='body1'>
          Sed posuere consectetur est at lobortis. Etiam porta sem malesuada magna mollis euismod.
          Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          Donec ullamcorper nulla non metus auctor fringilla.
        </Typography>
      </Box>
    )
  }

  const renderActivityGraph = () => {
    return (
      <Box sx={styles.dataContainer} mt='2rem'>
        <Typography variant='h3' mb='1rem'>{t('successful_logins')}</Typography>
        <Typography variant='body1'>
          Sed posuere consectetur est at lobortis. Etiam porta sem malesuada magna mollis euismod.
          Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          Donec ullamcorper nulla non metus auctor fringilla.
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Grid container columns={12} spacing={4}>
        <Grid xs={12} xl={7.5}>
          {renderShowInfo()}
          <Grid container columns={12} spacing={4}>
            <Grid xs={4}>{renderPropertySummary()}</Grid>
            <Grid xs={4}>{renderReminderSummary()}</Grid>
            <Grid xs={4}>{renderUserSummary()}</Grid>
          </Grid>
          {renderActivityGraph()}
        </Grid>
        <Grid xs={12} xl={4.5}>
          <Grid container columns={12} spacing={4}>
            <Grid xs={12}>{renderActionsInProgress()}</Grid>
            <Grid xs={12}>{renderUpcomingReminders()}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(Dashboard)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100rem',
    pb: {
      xs: '6rem'
    }
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem',
    p: '2rem'
  },
  dataContainer: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem',
    p: '2rem'
  },
  infoContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.secondary10,
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem',
    p: '2rem',
    mb: '2rem'
  },
  infoContentContainer: {
    width: '45%',
    marginRight: '2rem'
  },
  button: {
    height: '3rem',
    mt: '2rem'
  },
  infoIcon: {
    maxWidth: '54%'
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '0rem',
    height: '2.375rem',
    backgroundColor: Colors.primary,
    fontFamily: `${Fonts.body} !important`,
    fontWeight: 400,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: '0.625rem',
    borderBottomLeftRadius: '0.625rem'
  },
  summaryValue: {
    fontSize: '4rem',
    fontWeight: 700,
    color: Colors.primary
  },
  summaryInfo: {
    fontSize: '1.375rem',
    fontWeight: 500,
    color: Colors.text,
    textTransform: 'lowercase'
  }
} as const
