import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'
import { BuildingYearOptions, FinnishMunicipalities, PropertyTypes } from '../../../Utils/constants'

const CreateProperty = (props: any) => {
  const { sessionStore, propertyStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [nameFi, setNameFi] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [type, setType] = useState(PropertyTypes.Condominium)
  const [buildingYear, setBuildingYear] = useState('')
  const [propertyId, setPropertyId] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [propertyManager, setPropertyManager] = useState<any>(null)

  useEffect(() => {
    // userStore.getPropertyManagers()
  }, [])

  const getPropertyTypeOptions = () => {
    return [
      { value: PropertyTypes.Condominium, label: t(PropertyTypes.Condominium) }
    ]
  }
  const getPostalCodeOptions = () => []
  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }
  const getPropertyManagerOptions = () => {
    return []
  }

  const isSendDisabled = () => {
    if (!nameFi || !businessId) return true
    return false
  }

  const create = () => {
    const callback = () => {
      setNameFi('')
      setBusinessId('')
      setType(PropertyTypes.Condominium)
      setBuildingYear('')
      setPropertyId('')
      setAddress('')
      setPostalCode('')
      setCity('')
      setPropertyManager(null)
    }
    const newProperty = {
      nameFi,
      businessId,
      type,
      buildingYear,
      propertyId,
      address,
      postalCode,
      city,
      propertyManager
    }
    propertyStore.createProperty(newProperty, callback)
  }
  const cancel = () => navigate(-1)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h2' mb='1rem'>{t('property_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('property_name')}
              value={nameFi}
              onChange={setNameFi}
              maxWidth='30rem'
              mr='2rem'
            />
            <Input
              label={t('business_id')}
              value={businessId}
              onChange={setBusinessId}
              maxWidth='16rem'
            />
          </Box>
          <Box sx={styles.row}>
            <Select
              label={t('property_type')}
              options={getPropertyTypeOptions()}
              value={type}
              onChange={setType}
              maxWidth='16rem'
              mr='2rem'
            />
            <Select
              label={t('building_year')}
              options={BuildingYearOptions}
              value={buildingYear}
              onChange={setBuildingYear}
              maxWidth='16rem'
              mr='2rem'
            />
            <Input
              label={t('property_id')}
              value={propertyId}
              onChange={setPropertyId}
              maxWidth='24rem'
            />
          </Box>
        </Box>
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('location')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('address')}
            value={address}
            onChange={setAddress}
            maxWidth='30rem'
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Input
              label={t('postal_code')}
              value={postalCode}
              onChange={setPostalCode}
              maxWidth='12rem'
              mr='2rem'
            />
            <Select
              label={t('city_or_municipality')}
              options={getCityOptions()}
              value={city}
              onChange={setCity}
            />
          </Box>
        </Box>
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('property_manager')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={t('property_manager')}
            options={getPropertyManagerOptions()}
            value={propertyManager}
            onChange={setPropertyManager}
            maxWidth='30rem'
            mb='1.5rem'
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_property')}
            onClick={create}
            width='100%'
            mb='1rem'
            disabled={isSendDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateProperty)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '55rem',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
