import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Support = (props: any) => {
  const { sessionStore, userStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.infoContainer}>
        <Typography variant='h4' mb='1rem'>{t('report_issue_title')}</Typography>
        <Typography variant='body1' mb='1rem'>{t('report_issue_info')}</Typography>
        <Link href='mailto:support@peanutsgroup.com'>
          <Typography variant='body1' mb='1rem'>support@peanutsgroup.com</Typography>
        </Link>
        <Typography variant='body1' mb='2rem'>{t('report_issue_response_info')}</Typography>
        <Typography variant='h4' mb='1rem'>{t('support_request_title')}</Typography>
        <Typography variant='body1' mb='1rem'>{t('support_request_info')}</Typography>
        <Typography variant='body1' mb='0.375rem'>- {t('support_request_info_1')}</Typography>
        <Typography variant='body1' mb='0.375rem'>- {t('support_request_info_2')}</Typography>
        <Typography variant='body1' mb='0.375rem'>- {t('support_request_info_3')}</Typography>
        <Typography variant='body1' mb='1rem'>- {t('support_request_info_4')}</Typography>
        <Typography variant='body1'>{t('support_request_info_5')}</Typography>
      </Box>
    </Box>
  )
}

export default observer(Support)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoContainer: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem',
    p: '2rem',
    maxWidth: '45rem'
  },
} as const
