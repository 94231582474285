import React from 'react'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Logo from '../../Assets/Icons/logo.svg'
import { Colors } from '../../Utils/theme'

const AuthLayout = (props: any) => {
  return (
    <Box sx={styles.container}>
      <Link href='/login'>
        <Box component='img' src={Logo} sx={styles.logo} alt='logo' />
      </Link>
      <Box component='main' sx={styles.contentContainer}>
        {props.children}
      </Box>
      <Typography variant='body2' sx={styles.footerText}>
        @ Copyright Korjauspartnerit Oy
      </Typography>
    </Box>
  )
}

export default observer(AuthLayout)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    background: Colors.primary,
    padding: {
      xs: '0 2rem'
    }
  },
  logo: {
    position: 'absolute',
    top: {
      lg: '2rem',
      xs: '1rem'
    },
    left: {
      lg: '2rem',
      xs: '0rem'
    },
    width: {
      xxl: '14rem',
      lg: '13rem',
      sm: '12rem',
      xs: '9rem'
    },
    height: 'auto',
    '&:hover': {
      opacity: 0.8,
      transition: 'opacity 0.3s'
    }
    /*
    margin: {
      xxl: '6rem 0 6rem 0',
      xl: '5rem 0 5rem 0',
      sm: '4rem 0 4rem 0',
      xs: '2rem 0 3rem 0'
    }
    */
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      lg: 'center',
      xs: 'flex-start'
    },
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    padding: {
      xs: '10rem 0rem'
    }
  },
  footerText: {
    color: Colors.white,
    textAlign: 'center',
    margin: '1.5rem 0'
  }
} as const
