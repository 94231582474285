import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import { Button } from '../../Components'
import DeleteIcon from '../../Assets/Icons/close.svg'
import BathroomIcon from '../../Assets/Icons/bathroom.svg'
import BedroomIcon from '../../Assets/Icons/bedroom.svg'
import KitchenIcon from '../../Assets/Icons/kitchen-furniture.svg'
import LivingRoomIcon from '../../Assets/Icons/living-room.svg'
import SaunaIcon from '../../Assets/Icons/sauna.svg'
import WcIcon from '../../Assets/Icons/toilet.svg'
import UtilityRoomIcon from '../../Assets/Icons/utility-room.svg'
import HallwayIcon from '../../Assets/Icons/hallway.svg'
import OtherIcon from '../../Assets/Icons/other-area.svg'
import BalconyIcon from '../../Assets/Icons/balcony.svg'
import WalkInClosetIcon from '../../Assets/Icons/walk-in-closet.svg'
import { Colors } from '../../Utils/theme'

const RoomIconMappings: any = {
  hallway: HallwayIcon,
  living_room: LivingRoomIcon,
  bedroom: BedroomIcon,
  kitchen: KitchenIcon,
  wc: WcIcon,
  bathroom: BathroomIcon,
  sauna: SaunaIcon,
  utility_room: UtilityRoomIcon,
  walk_in_closet: WalkInClosetIcon,
  balcony: BalconyIcon,
  other: OtherIcon
}

export default function RoomItem(props: any) {
  const { item } = props

  const { t } = useTranslation()

  const getContainerStyle = () => {
    const extraStyles: any = {}
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const onClick = () => props.onClick(item)
  const onDelete = () => props.onDelete(item)

  const getTitle = () => {
    if (item?.areaSize) {
      return `${t(item.type)} (${item.areaSize}m²)`
    }
    return t(item.type)
  }

  const getUpdatedAt = () => {
    if (item?.updatedAt) {
      const time = DateTime.fromISO(item.updatedAt).toFormat('dd.MM.yyyy HH.mm')
      return `${t('edited_at')} ${time}`
    }
    return ''
  }

  return (
    <ButtonBase sx={getContainerStyle()} onClick={onClick}>
      <Box component='img' src={RoomIconMappings?.[item.type]} alt='icon' />
      <Box sx={styles.contentContainer}>
        <Typography variant='h5' sx={styles.title}>
          {getTitle()}
        </Typography>
        <Typography variant='body1' sx={styles.updatedAt}>
          {getUpdatedAt()}
        </Typography>
      </Box>
      {/*
        <Button sx={styles.deleteButton} onClick={onDelete}>
          <Box component='img' src={DeleteIcon} alt='delete' />
        </Button>
      */}
      </ButtonBase>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.75rem',
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '1.375rem'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 800,
    color: Colors.heading
  },
  updatedAt: {
    fontSize: '1rem',
    color: Colors.primary
  },
  deleteButton: {
    height: '2.25rem',
    minWidth: '2.5rem',
    padding: 0
  },
  deleteButtonIcon: {
    width: '1.25rem',
    marginRight: '0rem'
  }
} as const