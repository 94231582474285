import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { Button, Input, Select, CoverImage } from '../../../Components'
import { UserRoles } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const EditUser = (props: any) => {
  const { sessionStore, userStore }: any = useStore()
  const { me } = sessionStore
  const { user } = userStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive')
  }
  const openActivateConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('activate')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (!user || user?.uuid !== uuid) {
        userStore.getUser(uuid)
      }
    }
  }, [])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (user?.uuid === uuid) {
        setFirstName(user?.firstName || '')
        setLastName(user?.lastName || '')
        setEmail(user?.email || '')
        setPhone(user?.phone || '')
        setRole(user?.role || '')
      }
    }
  }, [user])

  const getRoleOptions = () => {
    if (me?.role === UserRoles.Admin) {
      return [
        { value: UserRoles.Admin, label: t(UserRoles.Admin) },
        { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) },
        { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    } else if (me?.role === UserRoles.PropertyManager) {
      return [
        { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    return []
  }

  const changeBackgroundPhoto = (photo: any) => {
    if (me.uuid === user.uuid) {
      sessionStore.updateMyBackgroundPhoto(photo)
    } else {
      userStore.updateUserBackgroundPhoto(user.uuid, photo)
    }
  }
  const changeProfilePhoto = (photo: any) => {
    if (me.uuid === user.uuid) {
      sessionStore.updateMyProfilePhoto(photo)
    } else {
      userStore.updateUserProfilePhoto(user.uuid, photo)
    }
  }

  const archive = () => {
    if (me.uuid === user.uuid) {
      sessionStore.archiveMe()
    } else {
      userStore.archiveUser(user.uuid)
    }
  }

  const save = () => {
    const updatedUser = {
      firstName,
      lastName,
      phone
    }
    if (me.uuid === user.uuid) {
      sessionStore.updateMe(updatedUser)
    } else {
      userStore.updateUser(user.uuid, updatedUser)
    }
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t(`${actionType}_user_title`)}
          content={t(`${actionType}_user_info`)}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archive}
          actionText={t(actionType)}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <CoverImage
          bgPhotoUrl={user?.backgroundPhoto}
          profilePhotoUrl={user?.profilePhoto}
          onUpdateBackgroundPhoto={changeBackgroundPhoto}
          onUpdateProfilePhoto={changeProfilePhoto}
          profile
        />
        <Typography variant='h2' mt='3rem' mb='1.5rem'>{t('basic_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
              maxWidth='25rem'
            />
          </Box>
          <Box sx={styles.row}>
            <Input
              label={t('email')}
              value={email}
              onChange={setEmail}
              mr='2rem'
              minWidth='24rem'
              maxWidth='40rem'
              copy
              disabled
            />
            <Input
              label={t('phone')}
              value={phone}
              onChange={setPhone}
              maxWidth='20rem'
            />
          </Box>
          <Select
            label={t('user_role')}
            options={getRoleOptions()}
            value={role}
            onChange={setRole}
            maxWidth='24rem'
            mb='1.5rem'
            disabled
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h4' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('save')} onClick={save} width='100%' mb='1rem' />
          <Button
            text={!user?.archivedAt ? t('archive_user') : t('activate_user')}
            onClick={!user?.archivedAt ? openArchiveConfirmation : openActivateConfirmation}
            variant='text'
            width='100%'
          />
        </Box>
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(EditUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    marginRight: '3rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
