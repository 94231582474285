import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class AreaStore {
  rootStore

  areas: any = {}
  area: any = null
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setAreas = (areas: any) => { this.areas = areas }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getAreas(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getAreas(pUuid)
      if (response?.ok) {
        const areas = response.data || {}
        this.setAreas(areas)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateAreas(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateAreas(pUuid, payload)
      if (response?.ok) {
        this.getAreas(pUuid)
        showMessage(i18n.t('areas_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('areas_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
