import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BuildingStructures from './BuildingStructures'
import BuildingParts from './BuildingParts'
import BuildingSystems from './BuildingSystems'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Chip from '../../../Components/Common/Chip'
import Tabs from '../../../Components/Common/Tabs'
import Tab from '../../../Components/Common/Tab'
import { Button, Input, Select, CoverImage } from '../../../Components'
import {
  BuildingYearOptions,
  FinnishMunicipalities,
  BuildingTypes,
  BuildingPurposes,
  BuildingEditTabs
} from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const EditBuilding = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const { sessionStore, buildingStore }: any = useStore()
  const { me } = sessionStore
  const { building } = buildingStore

  const saveBuildingDataRef = useRef<any>(null)

  // General
  const [tab, setTab] = useState(BuildingEditTabs.GeneralInformation)

  // Building general information
  const [nameFi, setNameFi] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [buildingPurpose, setBuildingPurpose] = useState(BuildingPurposes.Residential)
  const [buildingYear, setBuildingYear] = useState('')
  const [type, setType] = useState(BuildingTypes.Condominium)
  const [areaSize, setAreaSize] = useState('')
  const [floorCount, setFloorCount] = useState('')
  const [apartmentCount, setApartmentCount] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionActionType('archive')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionActionType('')
  }

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    if (pUuid && bUuid && pUuid?.length === 36 && bUuid?.length === 36) {
      // buildingStore.getBuilding(pUuid, bUuid)
    }
  }, [])

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    if (pUuid && bUuid && pUuid?.length === 36 && bUuid?.length === 36) {
      if (tab  === BuildingEditTabs.GeneralInformation) {
        buildingStore.getBuilding(pUuid, bUuid)
      } else if (tab === BuildingEditTabs.BuildingStructures) {
        buildingStore.getBuildingStructures(pUuid, bUuid)
      } else if (tab === BuildingEditTabs.BuildingParts) {
        buildingStore.getBuildingParts(pUuid, bUuid)
      } else if (tab === BuildingEditTabs.BuildingSystems) {
        buildingStore.getBuildingSystems(pUuid, bUuid)
      }
    }
  }, [tab])

  useEffect(() => {
    const bUuid = params?.bUuid || null
    if (bUuid && bUuid?.length === 36) {
      if (building && building?.uuid === bUuid) {
        setNameFi(building?.nameFi)
        setBuildingId(building?.buildingId)
        setBuildingPurpose(building?.buildingPurpose)
        setBuildingYear(building?.buildingYear)
        setType(building?.type)
        setAreaSize(building?.areaSize)
        setFloorCount(building?.floorCount)
        setApartmentCount(building?.apartmentCount)
        setAddress(building?.address)
        setPostalCode(building?.postalCode)
        setCity(building?.city)
      }
    }
  }, [building])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === 'structures') {
        setTab(BuildingEditTabs.BuildingStructures)
      } else if (selectedTab === 'parts') {
        setTab(BuildingEditTabs.BuildingParts)
      }
    }
  }, [location])

  const getBuildingPurposeOptions = () => {
    return [
      { value: 'residential', label: t('residential') },
      // { value: 'commercial', label: t('commercial') }
    ]
  }
  const getBuildingTypeOptions = () => {
    return [
      { value: BuildingTypes.Condominium, label: t(BuildingTypes.Condominium) }
    ]
  }

  const getFloorCountOptions = () => {
    return Array.from({ length: 40 }, (_, i) => i + 1).map((item: any) => {
      return { value: item, label: item }
    })
  }

  const getApartmentCountOptions = () => {
    return Array.from({ length: 120 }, (_, i) => i + 1).map((item: any) => {
      return { value: item, label: item }
    })
  }

  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }

  const isSaveDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const changeBackgroundPhoto = (photo: any) => {
    buildingStore.updateBuildingBackgroundPhoto(params?.pUuid, photo)
  }

  const save = () => {
    if (tab === BuildingEditTabs.GeneralInformation) {
      const updatedBuilding = {
        nameFi,
        buildingId,
        buildingPurpose,
        buildingYear,
        type,
        areaSize,
        floorCount,
        apartmentCount,
        address,
        postalCode,
        city
      }
      buildingStore.updateBuilding(params?.pUuid, updatedBuilding)
    } else {
      saveBuildingDataRef.current.onSave()
    }
  }

  const archive = () => {
    closeActionConfirmation()
  }

  const renderTabContent = () => {
    if (tab === BuildingEditTabs.GeneralInformation) {
      return (
        <>
          <Typography variant='h2' mt='1.5rem' mb='1.5rem'>{t('building_information')}</Typography>
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Input
                label={t('building_name')}
                value={nameFi}
                onChange={setNameFi}
                maxWidth='30rem'
                mr='2rem'
              />
              <Input
                label={t('building_id')}
                value={buildingId}
                onChange={setBuildingId}
                maxWidth='16rem'
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('building_type')}
                options={getBuildingTypeOptions()}
                value={type}
                onChange={setType}
                maxWidth='16rem'
                mr='2rem'
              />
              <Select
                label={t('building_purpose')}
                options={getBuildingPurposeOptions()}
                value={buildingPurpose}
                onChange={buildingPurpose}
                maxWidth='20rem'
                mr='2rem'
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('floor_count')}
                options={getFloorCountOptions()}
                value={floorCount}
                onChange={setFloorCount}
                maxWidth='18rem'
                mr='2rem'
              />
              <Select
                label={t('apartment_count')}
                options={getApartmentCountOptions()}
                value={apartmentCount}
                onChange={setApartmentCount}
                maxWidth='18rem'
                mr='2rem'
              />
              <Input
                label={t('area')}
                value={areaSize}
                onChange={setAreaSize}
                maxWidth='10rem'
              />
            </Box>
            <Select
              label={t('building_year')}
              options={BuildingYearOptions}
              value={buildingYear}
              onChange={setBuildingYear}
              maxWidth='24rem'
              mb='1.5rem'
            />
          </Box>
          <Typography variant='h2' mt='2rem' mb='1rem'>{t('location')}</Typography>
          <Box sx={styles.formContainer}>
            <Input
              label={t('address')}
              value={address}
              onChange={setAddress}
              maxWidth='30rem'
              mb='1.5rem'
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={postalCode}
                onChange={setPostalCode}
                maxWidth='12rem'
                mr='2rem'
              />
              <Select
                label={t('city_or_municipality')}
                options={getCityOptions()}
                value={city}
                onChange={setCity}
                maxWidth='34rem'
              />
            </Box>
          </Box>
        </>
      )
    } else if (tab === BuildingEditTabs.BuildingStructures) {
      return <BuildingStructures uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    } else if (tab === BuildingEditTabs.BuildingParts) {
      return <BuildingParts uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    } else if (tab === BuildingEditTabs.BuildingSystems) {
      return <BuildingSystems uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    }
    return null
  }

  const renderArchiveButton = () => {
    if (!building?.archivedAt) {
      return (
        <Button
          text={t('archive_building')}
          onClick={openArchiveConfirmation}
          variant='text'
          width='100%'
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_building_title')}
          content={t('archive_building_info')}
          onClose={closeActionConfirmation}
          cancelText={t('cancel')}
          onAction={archive}
          actionText={t('archive')}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <CoverImage
          bgPhotoUrl={building?.backgroundPhoto}
          // onUpdateBackgroundPhoto={changeBackgroundPhoto}
        />
        <Typography variant='h2' textTransform='uppercase' mt='1rem'>
          {t('edit_building_information')}
        </Typography>
        <Box sx={styles.infoRow}>
          <Chip text={building?.nameFi || '-'} />
        </Box>
        <Tabs mb={tab === BuildingEditTabs.GeneralInformation ? '0.5rem' : '1rem'}>
          <Tab
            value={BuildingEditTabs.GeneralInformation}
            text={t(BuildingEditTabs.GeneralInformation)}
            selected={tab === BuildingEditTabs.GeneralInformation}
            onClick={setTab}
            first
          />
          <Tab
            value={BuildingEditTabs.BuildingStructures}
            text={t(BuildingEditTabs.BuildingStructures)}
            selected={tab === BuildingEditTabs.BuildingStructures}
            onClick={setTab}
            count={0}
          />
          <Tab
            value={BuildingEditTabs.BuildingParts}
            text={t(BuildingEditTabs.BuildingParts)}
            selected={tab === BuildingEditTabs.BuildingParts}
            onClick={setTab}
            count={0}
          />
          <Tab
            value={BuildingEditTabs.BuildingSystems}
            text={t(BuildingEditTabs.BuildingSystems)}
            selected={tab === BuildingEditTabs.BuildingSystems}
            onClick={setTab}
            count={0}
          />
        </Tabs>
        {renderTabContent()}
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={isSaveDisabled()}
          />
          {renderArchiveButton()}
        </Box>
      </Box>
      {renderActionConfirmation()}
    </Box>
  )
}

export default observer(EditBuilding)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    marginRight: '3rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '60rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
