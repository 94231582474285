import { useMemo, forwardRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BarChart from './BarChart'
import LineChart from './LineChart'
import { Colors } from '../../Utils/theme'

const CostDevelopment = forwardRef((props: any, ref: any) => {
  const { costs, type } = props

  const Chart = useMemo(() => {
    if (type === 'bar') {
      return (
        <BarChart
          data={BarData}
        />
      )
    } else if (type === 'line') {
      return (
        <LineChart
          data={LineData}
        />
      )
    }
    return null
  }, [costs, type])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.chartContainer}>
        {Chart}
      </Box>
    </Box>
  
  )
})

export default CostDevelopment

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 17rem)',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  }
} as const

const BarData = [
  {
    "country": "AD",
    "hot dog": 174,
    "hot dogColor": "hsl(121, 70%, 50%)",
    "burger": 130,
    "burgerColor": "hsl(283, 70%, 50%)",
    "sandwich": 56,
    "sandwichColor": "hsl(298, 70%, 50%)",
    "kebab": 164,
    "kebabColor": "hsl(315, 70%, 50%)",
    "fries": 173,
    "friesColor": "hsl(349, 70%, 50%)",
    "donut": 29,
    "donutColor": "hsl(335, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 90,
    "hot dogColor": "hsl(225, 70%, 50%)",
    "burger": 90,
    "burgerColor": "hsl(249, 70%, 50%)",
    "sandwich": 183,
    "sandwichColor": "hsl(304, 70%, 50%)",
    "kebab": 27,
    "kebabColor": "hsl(300, 70%, 50%)",
    "fries": 76,
    "friesColor": "hsl(229, 70%, 50%)",
    "donut": 46,
    "donutColor": "hsl(80, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 31,
    "hot dogColor": "hsl(175, 70%, 50%)",
    "burger": 97,
    "burgerColor": "hsl(274, 70%, 50%)",
    "sandwich": 66,
    "sandwichColor": "hsl(183, 70%, 50%)",
    "kebab": 0,
    "kebabColor": "hsl(31, 70%, 50%)",
    "fries": 87,
    "friesColor": "hsl(299, 70%, 50%)",
    "donut": 22,
    "donutColor": "hsl(130, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 185,
    "hot dogColor": "hsl(357, 70%, 50%)",
    "burger": 52,
    "burgerColor": "hsl(146, 70%, 50%)",
    "sandwich": 94,
    "sandwichColor": "hsl(326, 70%, 50%)",
    "kebab": 70,
    "kebabColor": "hsl(139, 70%, 50%)",
    "fries": 137,
    "friesColor": "hsl(17, 70%, 50%)",
    "donut": 156,
    "donutColor": "hsl(64, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 170,
    "hot dogColor": "hsl(164, 70%, 50%)",
    "burger": 2,
    "burgerColor": "hsl(46, 70%, 50%)",
    "sandwich": 33,
    "sandwichColor": "hsl(293, 70%, 50%)",
    "kebab": 66,
    "kebabColor": "hsl(212, 70%, 50%)",
    "fries": 99,
    "friesColor": "hsl(160, 70%, 50%)",
    "donut": 50,
    "donutColor": "hsl(234, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 183,
    "hot dogColor": "hsl(344, 70%, 50%)",
    "burger": 70,
    "burgerColor": "hsl(132, 70%, 50%)",
    "sandwich": 48,
    "sandwichColor": "hsl(116, 70%, 50%)",
    "kebab": 30,
    "kebabColor": "hsl(37, 70%, 50%)",
    "fries": 181,
    "friesColor": "hsl(95, 70%, 50%)",
    "donut": 7,
    "donutColor": "hsl(345, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 149,
    "hot dogColor": "hsl(19, 70%, 50%)",
    "burger": 5,
    "burgerColor": "hsl(202, 70%, 50%)",
    "sandwich": 35,
    "sandwichColor": "hsl(18, 70%, 50%)",
    "kebab": 92,
    "kebabColor": "hsl(29, 70%, 50%)",
    "fries": 115,
    "friesColor": "hsl(315, 70%, 50%)",
    "donut": 167,
    "donutColor": "hsl(245, 70%, 50%)"
  }
]

const LineData = [
  {
    "id": "japan",
    "color": "hsl(295, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 238
      },
      {
        "x": "helicopter",
        "y": 191
      },
      {
        "x": "boat",
        "y": 169
      },
      {
        "x": "train",
        "y": 40
      },
      {
        "x": "subway",
        "y": 209
      },
      {
        "x": "bus",
        "y": 95
      },
      {
        "x": "car",
        "y": 172
      },
      {
        "x": "moto",
        "y": 94
      },
      {
        "x": "bicycle",
        "y": 85
      },
      {
        "x": "horse",
        "y": 150
      },
      {
        "x": "skateboard",
        "y": 293
      },
      {
        "x": "others",
        "y": 82
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(92, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 180
      },
      {
        "x": "helicopter",
        "y": 61
      },
      {
        "x": "boat",
        "y": 17
      },
      {
        "x": "train",
        "y": 249
      },
      {
        "x": "subway",
        "y": 171
      },
      {
        "x": "bus",
        "y": 222
      },
      {
        "x": "car",
        "y": 81
      },
      {
        "x": "moto",
        "y": 195
      },
      {
        "x": "bicycle",
        "y": 295
      },
      {
        "x": "horse",
        "y": 117
      },
      {
        "x": "skateboard",
        "y": 138
      },
      {
        "x": "others",
        "y": 243
      }
    ]
  },
  {
    "id": "us",
    "color": "hsl(8, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 146
      },
      {
        "x": "helicopter",
        "y": 133
      },
      {
        "x": "boat",
        "y": 56
      },
      {
        "x": "train",
        "y": 11
      },
      {
        "x": "subway",
        "y": 221
      },
      {
        "x": "bus",
        "y": 215
      },
      {
        "x": "car",
        "y": 144
      },
      {
        "x": "moto",
        "y": 120
      },
      {
        "x": "bicycle",
        "y": 225
      },
      {
        "x": "horse",
        "y": 165
      },
      {
        "x": "skateboard",
        "y": 180
      },
      {
        "x": "others",
        "y": 201
      }
    ]
  },
  {
    "id": "germany",
    "color": "hsl(252, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 80
      },
      {
        "x": "helicopter",
        "y": 297
      },
      {
        "x": "boat",
        "y": 189
      },
      {
        "x": "train",
        "y": 40
      },
      {
        "x": "subway",
        "y": 136
      },
      {
        "x": "bus",
        "y": 183
      },
      {
        "x": "car",
        "y": 173
      },
      {
        "x": "moto",
        "y": 199
      },
      {
        "x": "bicycle",
        "y": 258
      },
      {
        "x": "horse",
        "y": 234
      },
      {
        "x": "skateboard",
        "y": 281
      },
      {
        "x": "others",
        "y": 59
      }
    ]
  },
  {
    "id": "norway",
    "color": "hsl(350, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 82
      },
      {
        "x": "helicopter",
        "y": 260
      },
      {
        "x": "boat",
        "y": 189
      },
      {
        "x": "train",
        "y": 231
      },
      {
        "x": "subway",
        "y": 77
      },
      {
        "x": "bus",
        "y": 41
      },
      {
        "x": "car",
        "y": 204
      },
      {
        "x": "moto",
        "y": 189
      },
      {
        "x": "bicycle",
        "y": 114
      },
      {
        "x": "horse",
        "y": 12
      },
      {
        "x": "skateboard",
        "y": 276
      },
      {
        "x": "others",
        "y": 88
      }
    ]
  }
]