import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class RoomStore {
  rootStore

  rooms: any = []
  room: any = null
  files: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setRooms = (rooms: any) => { this.rooms = rooms }
  setRoom = (room: any) => { this.room = room }
  setFiles = (files: any) => { this.files = files }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getRooms(pUuid: string, aUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRooms(pUuid, aUuid)
      if (response?.ok) {
        const rooms = response.data?.items || []
        this.setRooms(rooms)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoom(pUuid: string, aUuid: string, rUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoom(pUuid, aUuid, rUuid)
      if (response?.ok) {
        const room = response?.data || null
        this.setRoom(room)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createRoom(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createRoom(pUuid, aUuid, payload)
      if (response?.ok) {
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoom(pUuid: string, aUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoom(pUuid, aUuid, this.room.uuid, payload)
      if (response?.ok) {
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateRoomBackgroundPhoto(pUuid: string, aUuid: string, fUuid: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateRoomBackgroundPhoto(pUuid, aUuid, this.room?.uuid, fUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setRoom(data)
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveRoom(pUuid: string, aUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveRoom(pUuid, aUuid, this.room.uuid)
      if (response?.ok) {
        this.getRooms(pUuid, aUuid)
        showMessage(i18n.t('room_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('room_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getRoomFiles(pUuid: string, aUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getRoomFiles(pUuid, aUuid, this.room.uuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadRoomFiles(pUuid: string, aUuid: string, files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadRoomFile(pUuid, aUuid, this.room.uuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        this.getRoomFiles(pUuid, aUuid)
        if (okResponses?.length === files?.length) {
          if (okResponses?.length === 1) {
            showMessage(i18n.t('file_uploaded'))
          } else {
            showMessage(i18n.t('files_uploaded'))
          }
        }
      } else {
        showMessage(i18n.t('file_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveRoomFile(pUuid: string, aUuid: string, fUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveRoomFile(pUuid, aUuid, this.room.uuid, fUuid)
      if (response?.ok) {
        this.getRoomFiles(pUuid, aUuid)
        showMessage(i18n.t('file_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
