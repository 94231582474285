import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class CostStore {
  rootStore

  tab: any = null
  costs: any = []
  costSettings: any = null
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTab = (tab: string) => { this.tab = tab }
  setCosts = (costs: any) => { this.costs = costs }
  setCostSettings = (costSettings: any) => { this.costSettings = costSettings }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getCosts(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getCosts(pUuid)
      if (response?.ok) {
        const data = response?.data || []
        const costs = data?.items || []
        const settings = data?.settings || null
        this.setCosts(costs)
        this.setCostSettings(settings)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getCostSettings(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getCostSettings(pUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setCostSettings(data)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
