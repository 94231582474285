import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'
import { UserRoles, UserRolesWithApartment, UserRolesWithProperty } from '../../../Utils/constants'

const CreateUser = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { sessionStore, userStore, propertyStore }: any = useStore()

  const { me } = sessionStore
  const { properties } = propertyStore

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [property, setProperty] = useState<any>(null)
  const [apartment, setApartment] = useState<any>(null)

  useEffect(() => {
    if (['admin'].includes(me?.role)) {
      propertyStore.getProperties()
    } else {
      // propertyStore.getMyProperties()
    }
  }, [])

  useEffect(() => {
    if (property) {
      // propertyStore.getApartments(property)
    }
  }, [property])

  const getPropertyOptions = () => {
    return propertyStore.properties.map((item: any) => {
      return {
        label: item.nameFi,
        value: item.uuid
      }
    })
  }

  const getApartmentOptions = () => {
    return propertyStore.apartments.map((item: any) => {
      return {
        label: item.nameFi,
        value: item.uuid
      }
    })
  }

  const getRoleOptions = () => {
    if (me?.role === UserRoles.Admin) {
      return [
        { value: UserRoles.Admin, label: t(UserRoles.Admin) }
        // { value: UserRoles.PropertyManager, label: t(UserRoles.PropertyManager) },
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    if (me?.role === UserRoles.PropertyManager) {
      return [
        // { value: UserRoles.Shareholder, label: t(UserRoles.Shareholder) }
      ]
    }
    return []
  }

  const create = () => {
    const callback = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setProperty(null)
      setApartment(null)
      if (me?.role === UserRoles.PropertyManager) {
        setRole(UserRoles.Shareholder)
      } else {
        setRole('')
      }
    }
    const newUser = {
      firstName,
      lastName,
      email,
      phone,
      role,
      property,
      apartment
    }
    userStore.createUser(newUser, callback)
  }
  const cancel = () => navigate(-1)

  const isSendDisabled = () => {
    if (!email || !role) return true
    if (!email.includes('@')) return true
    if (UserRolesWithProperty.includes(role) && !property) return true
    return false
  }

  const renderPropertySelect = () => {
    if (role && UserRolesWithProperty.includes(role)) {
      return (
        <Select
          label={t('property')}
          options={getPropertyOptions()}
          value={property}
          onChange={setProperty}
          maxWidth='30rem'
          mb='1.5rem'
        />
      )
    }
    return null
  }

  const renderApartmentSelect = () => {
    if (role && UserRolesWithApartment.includes(role) && property) {
      return (
        <Select
          label={t('apartment')}
          options={getApartmentOptions()}
          value={apartment}
          onChange={setApartment}
          maxWidth='30rem'
          mb='1.5rem'
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('user_information')}</Typography>
         <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Input
            label={t('email')}
            value={email}
            onChange={setEmail}
            mb='1.5rem'
          />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            maxWidth='20rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h4' mt='2rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={t('user_level')}
            options={getRoleOptions()}
            value={role}
            onChange={setRole}
            maxWidth='20rem'
            mb='1.5rem'
          />
          {renderPropertySelect()}
          {renderApartmentSelect()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_user')}
            onClick={create}
            width='100%'
            mb='1rem'
            disabled={isSendDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
