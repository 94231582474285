import { Sub } from 'emotion-icons/crypto'
import {
  Subtitle,
  Input,
  Select,
  MultiSelect,
  Row,
  OtherOption,
  OtherPlaceholder,
  UnknownOption,
  UnknownPlaceholder,
  YearOptions,
  Option,
  IfOtherSelected,
  IfUnknownSelected,
  BooleanOptions,
  IfYesSelected,
  IfCustomSelected,
  DefaultOptions
} from './layout'

export enum BuildingSections {
  // Perustukset ja perusmuuri
  Foundation = 'foundation',
  // Alapohja
  BaseFloor = 'base_floor',
  // Ulkoseinät ja julkisivu
  ExteriorWall = 'exterior_wall',
  // Välipohjat
  IntermediateFloor = 'intermediate_floor',
  // Väliseinät
  InteriorWall = 'interior_wall',
  // Yläpohja
  UpperFloor = 'upper_floor',
  // Vesikatto
  WaterRoof = 'water_roof',
  // Ikkunat
  Window = 'window',
  // Ovet
  Door = 'door',
  // Parvekkeet
  Balcony = 'balcony',
  // Terassit
  Terrace = 'terrace',
  // Ilmanvaihto
  Ventilation = 'ventilation',
  // Lämmitys
  Heating = 'heating',
  // Viemäröinti
  Sewerage = 'sewerage',
  // Vesijohdot
  WaterPipes = 'water_pipes',
  // Jäähdytys
  Cooling = 'cooling',
  // Hissi
  Elevator = 'elevator',
  // Sähkö
  Electric = 'electric'
}

const {
  Foundation,
  BaseFloor,
  ExteriorWall,
  IntermediateFloor,
  InteriorWall,
  UpperFloor,
  WaterRoof,
  Window,
  Door,
  Balcony,
  Terrace,
  Ventilation,
  Heating,
  Sewerage,
  WaterPipes,
  Cooling,
  Elevator,
  Electric
} = BuildingSections

// Rakenteet
const BuildingStructureSections = {
  [Foundation]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'foundation_type',
        ...Select,
        options: [
          Option('pile', 'pile_foundation'),
          Option('pad', 'pad_foundation'),
          Option('pillar', 'pillar_foundation'),
          Option('edge_reinforced_tile'),
          Option('rock', 'rock_foundation'),
          Option('natural_stone', 'natural_stone_cladding'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'typeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('type')],
        ...Input
      },
      {
        key: 'typeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('type')],
        ...Input
      },
      {
        key: 'material',
        label: 'foundation_material',
        visibleIfValues: [{ key: 'type', values: ['pile'] }],
        ...Select,
        options: [
          Option('steel_piles'),
          Option('concrete_piles'),
          Option('wood_piles')
        ] 
      },
      {
        key: 'material',
        label: 'foundation_material',
        visibleIfValues: [{ key: 'type', values: ['pad'] }],
        ...Select,
        options: [
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('concrete_block', 'concrete_block_pad'),
        ] 
      }
    ],
    [
      Subtitle('plinth'),
      {
        key: 'plinthMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete_block'),
          Option('poured_concrete', 'concrete_poured_in_place'),
          Option('concrete_element'),
          Option('steel'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'plinthMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthMaterial')],
        ...Input
      },
      {
        key: 'plinthMaterialNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthMaterial')],
        ...Input
      },
      {
        key: 'plinthInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'plinthCoating',
        label: 'plinth_coating',
        ...Select,
        options: [
          Option('paint'),
          Option('plaster'),
          Option('sheet_metal'),
          Option('plastic'),
          OtherOption,
          UnknownOption
        ]
      },
      {
        key: 'plinthCoatingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthCoating')],
        ...Input
      },
      {
        key: 'plinthCoatingNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthCoating')],
        ...Input
      },
      {
        key: 'plinthCoatingInstallationYear',
        label: 'coating_installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'plinthDampProofing',
        label: 'damp_proofing',
        ...Select,
        options: [
          Option('plastic_dam_plate'),
          Option('liquid_bitumen'),
          Option('bitumen_roll'),
          Option('drainable_insulation_board')
        ]
      },
      {
        key: 'plinthDampProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('plinthDampProofing')],
        ...Input
      },
      {
        key: 'plinthDampProofingNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfUnknownSelected('plinthDampProofing')],
        ...Input
      },
      {
        key: 'plinthDampProofingInstallationYear',
        label: 'damp_proofing_installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [BaseFloor]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'type',
        ...Select,
        options: [
          Option('concrete_steel_slab'),
          Option('supporting_concrete_steel_slab'),
          Option('edge_reinforced_concrete_steel_slab'),
          Option('plate_structure'),
          Option('aggregate_ventilated', 'aggregate_ventilated_base_floor'),
          Option('wooden_ventilated', 'wooden_ventilated_base_floor'),
        ]
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
    ],
    [
      Subtitle('frame_material'),
      {
        key: 'frameMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood_element'),
          Option('steel'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'frameMateriaNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      },
      {
        key: 'frameInsulation',
        label: 'insulation',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption
        ]
      },
      {
        key: 'frameInsulationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameInsulation')],
        ...Input
      },
      {
        key: 'frameInsulationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('frameInsulation', 'organic_material')],
        ...Input
      },
      {
        key: 'frameInsulationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'frameInsulationUpperSurfacePlate',
        label: 'insulation_upper_surface_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('surface_plate'),
      {
        key: 'surfacePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('frameInsulationUpperSurfacePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('gypsum_mass'),
          Option('earth_moist_concrete'),
          Option('fiber_reinforced_concrete'),
          OtherOption
        ]
      },
      {
        key: 'surfacePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          IfYesSelected('frameInsulationUpperSurfacePlate'),
          IfOtherSelected('surfacePlateMaterial')
        ],
        ...Input
      },
      {
        key: 'surfacePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('frameInsulationUpperSurfacePlate')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('ventilation_route'),
      {
        key: 'ventilationType',
        label: 'type',
        ...Select,
        options: [
          Option('plinth', 'plinth_ventilation'),
          Option('pile', 'pile_ventilation'),
          Option('natural_stone', 'natural_stone_ventilation'),
          OtherOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationType')],
        ...Input
      },
      {
        key: 'ventilationTypeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfCustomSelected('ventilationType', 'no_viable_ventilation')],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'hasMechanicalExtraction',
            label: 'mechanical_extraction',
            // disabledIfValues: [IfYesSelected('hasMechanicalVentilation')],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasMechanicalVentilation',
            label: 'mechanical_ventilation',
            // disabledIfValues: [IfYesSelected('hasMechanicalExtraction')],
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        key: 'extractionMachineType',
        label: 'machine_model_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Select,
        options: DefaultOptions
      },
      {
        key: 'extractionMachineInstallationYear',
        label: 'installation_year_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'extractionMachineLocation',
        label: 'machine_location_extraction',
        visibleIfValues: [IfYesSelected('hasMechanicalExtraction')],
        ...Select,
        options: [
          Option('water_roof'),
          Option('ventilated_base_floor'),
          OtherOption
        ]
      },
      {
        key: 'extractionMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('extractionMachineLocation')],
        ...Input
      },
      {
        key: 'ventilationMachineType',
        label: 'machine_model_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Select,
        options: DefaultOptions
      },
      {
        key: 'ventilationMachineInstallationYear',
        label: 'installation_year_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'ventilationMachineLocation',
        label: 'machine_location_ventilation',
        visibleIfValues: [IfYesSelected('hasMechanicalVentilation')],
        ...Select,
        options: [
          Option('ventilated_base_floor'),
          Option('technical_space'),
          OtherOption
        ]
      },
      {
        key: 'ventilationMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationMachineLocation')],
        ...Input
      }
    ]
  ],
  [ExteriorWall]: [
    [
      Subtitle('exterior_walls'),
      {
        key: 'frameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('concrete_block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          OtherOption
        ]
      },
      {
        key: 'frameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('organic_material'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('air_gap'),
          Option('no_insulation', 'no_insulation_alt'),
          OtherOption
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      /* Not documented
      {
        key: 'insulationInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      */
      {
        key: 'hasWindBarrierBoard',
        label: 'wind_barrier_board',
        ...Select,
        options: BooleanOptions
      },
    ],
    [
      Subtitle('wind_barrier_board'),
      {
        key: 'windBarrierBoardType',
        label: 'type',
        visibleIfValues: [IfYesSelected('hasWindBarrierBoard')],
        ...Select,
        options: [
          Option('wind_barrier_board'),
          Option('bitumen', 'bitumen_board'),
          Option('wool', 'wind_barrier_wool'),
          Option('gypsum', 'wind_barrier_gypsum'),
          OtherOption
        ]
      },
      {
        key: 'windBarrierBoardTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('windBarrierBoardType')],
        ...Input
      },
      {
        key: 'windBarrierBoardInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('hasWindBarrierBoard')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('facade'),
      {
        key: 'facadeMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('brick'),
          Option('natural_stone'),
          Option('thin_plaster', 'thin_insulation_plaster'),
          Option('thick_plaster', 'thick_insulation_plaster'),
          Option('board_plaster'),
          Option('steel'),
          Option('glass'),
          OtherOption
        ] 
      },
      {
        key: 'facadeMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('facadeMaterial')],
        ...Input
      },
      {
        key: 'facadeInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      /* Not documented
      {
        key: 'facadeCoatingSameAsMaterial',
        label: 'facade_coating_same_as_material',
        ...Select,
        options: [
        ]
      },
      */
    ],
    [
      Subtitle('facade_coating'),
      {
        key: 'facadeCoatingMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('uncoated'),
          Option('paint'),
          Option('washed_concrete'),
          Option('natural_stone_tile'),
          Option('ceramic_tile'),
          Option('brick_tile'),
          Option('steel'),
          Option('coated_steel_sheet'),
          Option('aluminum'),
          Option('copper'),
          Option('fiber_cement'),
          Option('ceramic_board', 'ceramic_facade_board'),
          Option('plastic', 'plastic_coating'),
          OtherOption 
        ]
      },
      {
        key: 'facadeCoatingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('facadeCoatingMaterial')],
        ...Input
      },
      {
        key: 'facadeCoatingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [IntermediateFloor]: [
    [
      Subtitle('basic_information'),
      {
        key: 'frameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_sub_slab_beam', 'concrete_sub_slab_beam_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'frameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameMaterial')],
        ...Input
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'frameInsulationMaterial',
        label: 'insulation_material',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption,
          Option('no_insulation', 'no_insulation_alt')
        ]
      },
      {
        key: 'frameInsulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('frameInsulationMaterial')],
        ...Input
      },
      {
        key: 'frameInsulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('frameInsulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationUpperSurfacePlate',
        label: 'insulation_upper_surface_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('surface_plate'),
      {
        key: 'surfacePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('insulationUpperSurfacePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('gypsum_mass'),
          Option('earth_moist_concrete'),
          Option('fiber_reinforced_concrete'),
          OtherOption
        ]
      },
      {
        key: 'surfacePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('surfacePlateMaterial')],
        ...Input
      },
      {
        key: 'surfacePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('insulationUpperSurfacePlate')],
        ...Select,
        options: YearOptions()
      }
    ]
  ],
  [InteriorWall]: [
    [
      Subtitle('bearing_interior_walls'),
      /* Not documented
      {
        key: 'bearingType',
        label: 'type',
        ...Select,
        options: [
        ]
      },
      */
      {
        key: 'bearingMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('block'),
          Option('brick'),
          Option('lightweight_concrete'),
          Option('poured_concrete_block'),
          Option('eps_block'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('log'),
          Option('steel'),
          OtherOption
        ]
      },
      {
        key: 'bearingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('lightweight_interior_walls'),
      {
        key: 'lightweightMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood'),
          Option('kerto'),
          Option('steel_frame'),
          Option('mass_wall', 'mass_wall_alt'),
          Option('block'),
          Option('brick'),
          Option('lightweight_concrete'),
          OtherOption
        ]
      },
      {
        key: 'lightweightMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('lightweightMaterial')],
        ...Input
      },
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('mineral_wool'),
          Option('organic_material'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('air_gap'),
          OtherOption,
          Option('no_insulation', 'no_insulation_alt')
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('insulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationCladdingMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('gypsum_board'),
          Option('fiberboard'),
          Option('chipboard'),
          Option('uncoated'),
          OtherOption
        ]
      },
      {
        key: 'insulationCladdingMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationCladdingMaterial')],
        ...Input
      }
    ]
  ],
  [UpperFloor]: [
    [
      Subtitle('bearing_structures'),
      {
        key: 'bearingStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('poured_concrete', 'concrete_poured_in_place_alt'),
          Option('concrete_element', 'concrete_element_alt'),
          Option('wood', 'wood_built_in_place'),
          Option('wood_element'),
          Option('steel_beam'),
          Option('plate'),
          OtherOption
        ]
      },
      {
        key: 'bearingStructureMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingStructureMaterial')],
        ...Input
      }
    ],
    [
      Subtitle('insulation'),
      {
        key: 'insulationMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('mineral_wool_board'),
          Option('mineral_wool', 'mineral_wool_blown'),
          Option('organic_material'),
          Option('mixed_fill', 'mixed_filling_alt'),
          Option('lightweight_concrete', 'lightweight_concrete_alt'),
          Option('eps'),
          Option('xps'),
          Option('pir'),
          Option('light_gravel'),
          Option('crushed_foam_glass'),
          OtherOption
        ]
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('insulationMaterial')],
        ...Input
      },
      {
        key: 'insulationMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfCustomSelected('insulationMaterial', 'organic_material')],
        ...Input
      },
      {
        key: 'insulationUpperFirePlate',
        label: 'insulation_upper_fire_plate',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('fire_plate'),
      {
        key: 'firePlateMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('insulationUpperFirePlate')],
        ...Select,
        options: [
          Option('concrete'),
          Option('thin_layer_plaster'),
          Option('lap_brick', 'lap_brick_masonry'),
          OtherOption
        ]
      },
      {
        key: 'firePlateMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('firePlateMaterial')],
        ...Input
      },
      {
        key: 'firePlateInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('insulationUpperFirePlate')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('upper_floor_space'),
      {
        key: 'ventilationRouteType',
        label: 'type',
        ...Select,
        options: [
          Option('eaves_gap', 'through_eaves_gaps'),
          Option('gable_gap', 'through_gable_gaps'),
          Option('underpressure_fan', 'through_underpressure_fans'),
          Option('ventilation_valve', 'through_ventilation_valves_at_ends'),
          OtherOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationRouteTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationRouteType')],
        ...Input
      },
      {
        key: 'ventilationRouteTypeNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [IfCustomSelected('ventilationRouteType', 'no_viable_ventilation')],
        ...Input
      },
      {
        key: 'parallelOrLowStructure',
        label: 'parallel_to_water_roof_or_low_upper_floor_structure',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'ventilationHeight',
        label: 'ventilation_height',
        visibleIfValues: [IfYesSelected('parallelOrLowStructure')],
        ...Select,
        options: [
          Option('20'),
          Option('50'),
          Option('100'),
          Option('200'),
          Option('400'),
          OtherOption,
          UnknownOption,
          Option('no_viable_ventilation')
        ]
      },
      {
        key: 'ventilationHeightNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('ventilationHeight')],
        ...Input
      },
      {
        key: 'ventilationHeightNotes',
        ...UnknownPlaceholder,
        visibleIfValues: [
          IfUnknownSelected('ventilationHeight'),
          // Test this
          IfCustomSelected('ventilationHeight', 'no_viable_ventilation')
        ],
        ...Input
      }
    ]
  ],
  [WaterRoof]: [
    [
      Subtitle('bearing_structures'),
      {
        key: 'bearingStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_lattice'),
          Option('wood_pile'),
          Option('steel_beam'),
          OtherOption
        ]
      },
      {
        key: 'bearingStructureMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('bearingStructureMaterial')],
        ...Input
      },
      {
        key: 'bearingStructureInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'bearingStructureSameAsUpperFloor',
        label: 'bearing_structure_same_as_upper_floor',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('underlayment'),
      {
        key: 'oldWaterRoofUnderlayment',
        label: 'old_water_roof_as_underlayment',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'underlaymentMaterial',
        label: 'material',
        visibleIfValues: [IfYesSelected('oldWaterRoofUnderlayment')],
        ...Select,
        options: [
          Option('fiber_reinforced_plastic'),
          Option('fiberboard'),
          Option('underlayment_roll'),
          Option('solid_plank'),
          Option('bitumen_roll'),
          Option('tin_roof'),
          Option('shake_roof'),
          OtherOption
        ]
      },
      {
        key: 'underlaymentInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('oldWaterRoofUnderlayment')],
        ...Select,
        options: YearOptions()
      }
    ],
    [
      Subtitle('water_roof'),
      {
        key: 'type',
        label: 'type',
        ...Select,
        options: [
          Option('gable_roof'),
          Option('broken_gable_roof'),
          Option('pulpit_roof'),
          Option('flat_roof'),
          Option('aum_roof'),
          Option('mansard_roof'),
          Option('folding_roof'),
          Option('inverse_roof'),
          OtherOption
        ]
      },
      {
        key: 'typeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('type')],
        ...Input
      },
      {
        key: 'material',
        label: 'material',
        ...Select,
        options: [
          Option('profile_tin_roof'),
          Option('seamed_roof'),
          Option('corrugated_roof'),
          Option('copper'),
          Option('aluminum'),
          Option('clay_tile'),
          Option('concrete_tile'),
          Option('bitumen_roll'),
          Option('fiber_cement'),
          Option('glass'),
          Option('slate'),
          Option('bitumen_shingle'),
          Option('wood_shingle'),
          Option('green_roof'),
          OtherOption
        ]
      },
      {
        key: 'materialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('material')],
        ...Input
      },
      {
        key: 'installationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      }
    ]
  ],
}

const BuildingPartSections = {
  [Window]: [
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'totalCount',
            label: 'total_count',
            ...Input
          },
          {
            key: 'apartmentWindowCount',
            label: 'apartment_windows',
            ...Input
          },
          {
            key: 'commonSpaceWindowCount',
            label: 'common_space_windows',
            ...Input
          }
        ]
      }
    ],
    [
      Subtitle('apartment_windows'),
      {
        key: 'apartmentWindowType',
        label: 'type',
        ...Select,
        options: [
          Option('msu'),
          Option('ms'),
          Option('msk'),
          Option('mse'),
          Option('msea'),
          Option('mek'),
          Option('meka'),
          Option('se'),
          Option('ms2e'),
          Option('ms3e'),
          Option('dk')
        ]
      },
      {
        key: 'apartmentWindowMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          OtherOption
        ]
      },
      {
        key: 'apartmentWindowMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentWindowMaterial')],
        ...Input
      },
      {
        key: 'apartmentWindowCount',
        label: 'type_count',
        placeholder: 'enter_amount',
        ...Input
      },
      {
        key: 'apartmentWindowInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentWindowProperties',
        label: 'equipment',
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      }
    ],
    [
      Subtitle('common_space_windows'),
      {
        key: 'commonSpaceWindowType',
        label: 'type',
        ...Select,
        options: [
          Option('msu'),
          Option('ms'),
          Option('msk'),
          Option('mse'),
          Option('msea'),
          Option('mek'),
          Option('meka'),
          Option('se'),
          Option('ms2e'),
          Option('ms3e'),
          Option('dk')
        ]
      },
      {
        key: 'commonSpaceWindowMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood'),
          Option('wood_aluminum'),
          Option('aluminum'),
          Option('steel'),
          Option('plastic'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceWindowMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceWindowMaterial')],
        ...Input
      },
      {
        key: 'commonSpaceWindowCount',
        label: 'type_count',
        placeholder: 'enter_amount',
        ...Input
      },
      {
        key: 'commonSpaceWindowInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceWindowProperties',
        label: 'equipment',
        ...MultiSelect,
        options: [
          Option('blinds'),
          Option('view_protection'),
          Option('burglary_protection'),
          Option('selective_protection'),
          Option('sun_protection_glass'),
          Option('safety_glass'),
          Option('fire_protection_glass')
        ]
      }
    ]
  ],
  [Door]: [
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'totalCount',
            label: 'total_count',
            ...Input
          },
          {
            key: 'exteriorDoorCount',
            label: 'building_exterior_doors',
            ...Input
          },
          {
            key: 'balconyAndTerraceDoorCount',
            label: 'balcony_and_terrace_doors',
            ...Input
          }
        ]
      },
      {
        ...Row,
        items: [
          {
            key: 'commonSpaceDoorCount',
            label: 'common_space_doors',
            ...Input
          },
          {
            key: 'apartmentDoorCount',
            label: 'apartment_doors',
            ...Input
          },
          {
            key: 'interiorDoorCount',
            label: 'interior_doors',
            ...Input
          }
        ]
      }
    ],
    [
      Subtitle('building_exterior_doors'),
      {
        key: 'exteriorDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'exteriorDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('exteriorDoorType')],
        ...Input
      },
      {
        key: 'exteriorDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'exteriorDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'exteriorDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'exteriorDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('exteriorDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'exteriorDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('exteriorDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'exteriorDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('balcony_and_terrace_doors'),
      {
        key: 'balconyAndTerraceDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'balconyAndTerraceDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('balconyAndTerraceDoorType')],
        ...Input
      },
      {
        key: 'balconyAndTerraceDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'balconyAndTerraceDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'balconyAndTerraceDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'balconyAndTerraceDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('balconyAndTerraceDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'balconyAndTerraceDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('balconyAndTerraceDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'balconyAndTerraceDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('building_common_space_doors'),
      {
        key: 'commonSpaceDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceDoorType')],
        ...Input
      },
      {
        key: 'commonSpaceDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'commonSpaceDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'commonSpaceDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('commonSpaceDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'commonSpaceDoorLockInstallationYear',
        label: 'installation_year',
        visibleIfValues: [IfYesSelected('commonSpaceDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('building_apartment_doors'),
      {
        key: 'apartmentDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'apartmentDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentDoorType')],
        ...Input
      },
      {
        key: 'apartmentDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'apartmentDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'apartmentDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('apartmentDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'apartmentDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('apartmentDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('building_interior_doors'),
      {
        key: 'interiorDoorType',
        label: 'type',
        ...Select,
        options: [
          Option('single_leaf', 'single_leaf_door'),
          Option('double_leaf', 'double_leaf_door'),
          Option('revolving', 'revolving_door'),
          Option('overhead', 'overhead_door'),
          Option('sliding', 'sliding_door'),
          OtherOption
        ]
      },
      {
        key: 'interiorDoorTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('interiorDoorType')],
        ...Input
      },
      {
        key: 'interiorDoorFireCompartmentalization',
        label: 'fire_compartmentalization',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorHasExtension',
        label: 'equipped_with_extension',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorHasTurningClipHinges',
        label: 'turning_clip_hinges',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('wood_or_wood_fiber'),
          Option('steel'),
          Option('aluminum'),
          Option('glass')
        ]
      },
      {
        key: 'interiorDoorInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'interiorDoorHasLock',
        label: 'lock_system',
        ...Select,
        options: BooleanOptions
      },
      {
        key: 'interiorDoorLockType',
        label: 'lock_system_type',
        visibleIfValues: [IfYesSelected('interiorDoorHasLock')],
        ...Select,
        options: [
          Option('mechanical'),
          Option('electronic')
        ]
      },
      {
        key: 'interiorDoorLockInstallationYear',
        label: 'lock_system_installation_year',
        visibleIfValues: [IfYesSelected('interiorDoorHasLock')],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'interiorDoorCount',
        label: 'door_type_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
  ],
  [Balcony]: [
    [
      Subtitle('basic_information'),
      {
        key: 'buildingHasBalconies',
        label: 'building_has_balconies',
        ...Select,
        // Todo: default is true
        options: BooleanOptions
      }
    ],
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'apartmentBalconyCount',
            label: 'apartment_balconies',
            ...Input
          },
          {
            key: 'commonSpaceBalconyCount',
            label: 'common_space_balconies',
            ...Input
          },
          {
            key: 'glassBalconyCount',
            label: 'balconies_with_glass',
            ...Input
          }
        ]
      }
    ],
    [
      Subtitle('apartment_balconies'),
      {
        key: 'balconyInApartments',
        label: 'all_apartments_have_balconies',
        ...Select,
        options: [
          Option('yes'),
          Option('only_some'),
        ]
      },
      {
        key: 'apartmentBalconyType',
        label: 'type',
        ...Select,
        options: [
          Option('in', 'balcony_in'),
          Option('out', 'balcony_out'),
          Option('french', 'french_balcony')
        ]
      },
      {
        key: 'apartmentBalconyStructureType',
        label: 'bearing_structures',
        ...Select,
        options: [
          Option('building_frame'),
          Option('substructures'),
          Option('pillar', 'pillar_structures'),
          Option('hung')
        ]
      },
      {
        key: 'apartmentBalconyStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete'),
          Option('steel'),
          Option('wood')
        ]
      },
      {
        key: 'apartmentBalconyInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      // Todo: possible position change
      {
        key: 'apartmentBalconyCount',
        label: 'balcony_type_count',
        placeholder: 'enter_amount',
        ...Input
      },
      Subtitle('water_proofing'),
      {
        key: 'apartmentBalconyWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen'),
          OtherOption
        ]
      },
      {
        key: 'apartmentBalconyWaterProofingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentBalconyWaterProofingType')],
        ...Input
      },
      {
        key: 'apartmentBalconyWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [{ key: 'apartmentBalconyWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'apartmentBalconyRailType',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'balcony_plating')
        ]
      },
      {
        key: 'apartmentBalconyRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'apartmentHaveGlazedBalconies',
        label: 'balconies_have_glazing',
        ...Select,
        options: [
          Option('all', 'in_all_balconies'),
          Option('some', 'in_some_balconies'),
          Option('no')
        ]
      },
      {
        key: 'apartmentBalconyGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentBalconyGlazedCount',
        label: 'glazed_balcony_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('common_space_balconies'),
      {
        key: 'commonSpaceBalconyType',
        label: 'type',
        ...Select,
        options: [
          Option('in', 'balcony_in'),
          Option('out', 'balcony_out'),
          Option('french', 'french_balcony')
        ]
      },
      {
        key: 'commonSpaceBalconyStructureType',
        label: 'bearing_structures',
        ...Select,
        options: [
          Option('building_frame'),
          Option('substructures'),
          Option('pillar', 'pillar_structures'),
          Option('hung')
        ]
      },
      {
        key: 'commonSpaceBalconyStructureMaterial',
        label: 'material',
        ...Select,
        options: [
          Option('concrete'),
          Option('steel'),
          Option('wood')
        ]
      },
      {
        key: 'commonSpaceBalconyInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceBalconyCount',
        label: 'balcony_type_count',
        placeholder: 'enter_amount',
        ...Input
      },
      Subtitle('water_proofing'),
      {
        key: 'commonSpaceBalconyWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing_alt'),
          UnknownOption,
          Option('mass'),
          Option('paint'),
          Option('bitumen'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceBalconyWaterProofingTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceBalconyWaterProofingType')],
        ...Input
      },
      {
        key: 'commonSpaceBalconyWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [{ key: 'commonSpaceBalconyWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'commonSpaceBalconyRailType',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'commonSpaceBalconyRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'commonSpaceHasGlazedBalconies',
        label: 'balconies_have_glazing',
        ...Select,
        options: [
          Option('all', 'in_all_balconies'),
          Option('some', 'in_some_balconies'),
          Option('no')
        ]
      },
      {
        key: 'commonSpaceBalconyGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceBalconyGlazedCount',
        label: 'glazed_balcony_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ]
  ],
  [Terrace]: [
    [
      Subtitle('basic_information'),
      {
        key: 'buildingHasTerraces',
        label: 'building_has_terraces',
        ...Select,
        // Todo: default is true
        options: BooleanOptions
      },
      {
        key: 'allApartmentsHaveTerraces',
        label: 'all_apartments_have_terraces',
        ...Select,
        options: BooleanOptions
      }
    ],
    [
      Subtitle('total_counts_per_building'),
      {
        ...Row,
        items: [
          {
            key: 'apartmentTerraceCount',
            label: 'apartment_terraces',
            ...Input
          },
          {
            key: 'commonSpaceTerraceCount',
            label: 'common_space_terraces',
            ...Input
          },
          {
            key: 'glassTerraceCount',
            label: 'terraces_with_glass',
            ...Input
          }
        ]
      }
    ],
    [
      Subtitle('apartment_terraces'),
      {
        key: 'apartmentTerraceType',
        label: 'type',
        ...Select,
        options: [
          Option('covered', 'covered_terrace'),
          Option('uncovered', 'uncovered_terrace'),
          Option('in', 'terrace_in'),
          Option('patio')
        ]
      },
      {
        key: 'apartmentTerraceFrameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('wood'),
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('stratum', 'stratums'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceFrameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceFrameMaterial')],
        ...Input
      },
      {
        key: 'apartmentTerraceSurfaceMaterial',
        label: 'surface_structures',
        ...Select,
        options: [
          Option('wood'),
          Option('concrete'),
          Option('stone'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceSurfaceMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceSurfaceMaterial')],
        ...Input
      },
      {
        key: 'apartmentTerraceInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentTerraceCount',
        label: 'apartment_terrace_count',
        ...Input
      },
      Subtitle('water_proofing'),
      {
        key: 'apartmentTerraceWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen', 'bitumen_roll'),
          OtherOption
        ]
      },
      {
        key: 'apartmentTerraceWaterProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('apartmentTerraceWaterProofingType')],
        ...Input
      },
      {
        key: 'apartmentTerraceWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'apartmentTerraceWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }
        ],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'apartmentTerraceRailMaterial',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'apartmentTerraceRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'apartmentsHaveGlazedTerraces',
        label: 'terraces_have_glazing',
        ...Select,
        options: [
          Option('all', 'yes_in_all_apartments'),
          Option('some', 'yes_in_some_apartments'),
          Option('no', 'terraces_have_no_glazing')
        ]
      },
      {
        key: 'apartmentTerraceGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'apartmentTerraceGlazedCount',
        label: 'glazed_terrace_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ],
    [
      Subtitle('common_space_terraces'),
      {
        key: 'commonSpaceTerraceType',
        label: 'type',
        ...Select,
        options: [
          Option('covered', 'covered_terrace'),
          Option('uncovered', 'uncovered_terrace'),
          Option('in', 'terrace_in'),
          Option('patio')
        ]
      },
      {
        key: 'commonSpaceTerraceFrameMaterial',
        label: 'frame_material',
        ...Select,
        options: [
          Option('wood'),
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('stratum', 'stratums'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceFrameMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceFrameMaterial')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceSurfaceMaterial',
        label: 'surface_structures',
        ...Select,
        options: [
          Option('wood'),
          Option('concrete'),
          Option('stone'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceSurfaceMaterialNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceSurfaceMaterial')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceTerraceCount',
        label: 'common_space_specific_terrace_count',
        placeholder: 'enter_amount',
        ...Input
      },
      Subtitle('water_proofing'),
      {
        key: 'commonSpaceTerraceWaterProofingType',
        label: 'type',
        ...Select,
        options: [
          Option('no', 'no_water_proofing'),
          UnknownOption,
          Option('mass', 'mass_water_proofing'),
          Option('paint'),
          Option('bitumen', 'bitumen_roll'),
          OtherOption
        ]
      },
      {
        key: 'commonSpaceTerraceWaterProofingNotes',
        ...OtherPlaceholder,
        visibleIfValues: [IfOtherSelected('commonSpaceTerraceWaterProofingType')],
        ...Input
      },
      {
        key: 'commonSpaceTerraceWaterProofingInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'commonSpaceTerraceWaterProofingType', values: ['mass', 'paint', 'bitumen', 'other'] }
        ],
        ...Select,
        options: YearOptions()
      },
      Subtitle('rails'),
      {
        key: 'commonSpaceTerraceRailMaterial',
        label: 'type',
        ...Select,
        options: [
          Option('steel'),
          Option('aluminum'),
          Option('concrete'),
          Option('wood'),
          Option('glass'),
          Option('plate', 'plating')
        ]
      },
      {
        key: 'commonSpaceTerraceRailInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      Subtitle('glazings'),
      {
        key: 'commonSpaceTerraceHasGlazing',
        label: 'terraces_have_glazing',
        ...Select,
        options: [
          Option('all', 'yes_in_all_apartments'),
          Option('some', 'yes_in_some_apartments'),
          Option('no', 'terraces_have_no_glazing')
        ]
      },
      {
        key: 'commonSpaceTerraceGlazingInstallationYear',
        label: 'installation_year',
        ...Select,
        options: YearOptions()
      },
      {
        key: 'commonSpaceTerraceGlazedCount',
        label: 'glazed_terrace_count',
        placeholder: 'enter_amount',
        ...Input
      }
    ]
  ]
}

export const BuildingSystemSections = {
  [Ventilation]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'ventilation_type',
        ...Select,
        options: [
          // Koneellinen poistoilmanvaihto
          Option('mechanical', 'mechanical_ventilation'),
          // Painovoimainen ilmanvaihto
          Option('gravity', 'gravity_ventilation'),
          // Koneellinen tulo- ja poistoilmanvaihto
          Option('mechanical_supply_and_extract', 'mechanical_supply_and_extract_ventilation')
        ]
      },
      {
        key: 'machineModel',
        label: 'machine_model',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'machineModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] },
          IfOtherSelected('machineModel')
        ],
        ...Input
      },
      {
        key: 'machineLocation',
        label: 'machine_location',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('air_supply_room'),
          Option('water_roof'),
          Option('upper_floor'),
          Option('technical_space'),
          OtherOption
        ]
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] },
          IfOtherSelected('machineLocation')
        ],
        ...Input
      },
      {
        ...Row,
        items: [
          {
            key: 'hasCondenseWaterDrainage',
            label: 'condense_water_drainage',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasHeatRecovery',
            label: 'heat_recovery',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasApartmentSpecificMachine',
            label: 'apartment_specific_machine',
            visibleIfValues: [
              { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
            ],
            ...Select,
            options: BooleanOptions
          }
        ]
      },
      {
        key: 'condenseWaterDrainageLocation',
        label: 'condense_water_drainage_location',
        visibleIfValues: [
          { key: 'hasCondenseWaterDrainage', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('floor_drain'),
          Option('sewer'),
          OtherOption
        ]
      },
      {
        key: 'condenseWaterDrainageLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'hasCondenseWaterDrainage', values: ['yes'] },
          IfOtherSelected('condenseWaterDrainageLocation')
        ],
        ...Input
      },
      {
        key: 'heatRecoveryCell',
        label: 'heat_recovery_cell',
        visibleIfValues: [
          { key: 'hasHeatRecovery', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('cross_flow'),
          Option('counter_flow'),
          Option('rotary')
        ]
      },
      {
        key: 'apartmentSpecificMachineLocation',
        label: 'apartment_specific_machine_location',
        visibleIfValues: [
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('bathroom', 'bathroom_short'),
          Option('utility_room', 'utility_room_short'),
          Option('walk_in_closet', 'walk_in_closet_short'),
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificMachineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificMachineLocation')
        ],
        ...Input
      }
    ],
    [
      Subtitle('filters'),
      {
        key: 'filterType',
        label: 'filters',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('mat', 'mat_filters'),
          Option('cassette', 'cassette_filters'),
          Option('bag', 'bag_filters')
        ]
      },
      {
        key: 'filtersReplacedAt',
        label: 'replaced',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract'] }
        ],
        ...Input
      }
    ],
    [
      Subtitle('replacement_air'),
      {
        key: 'replacementAirRoute',
        label: 'route',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract', 'gravity'] }
        ],
        ...Select,
        options: [
          Option('window_frame_valve', 'window_frame_valves'),
          Option('extraction_air_valve', 'extraction_air_valves'),
          Option('ventilation_window', 'ventilation_windows'),
          Option('no'),
          OtherOption
        ]
      },
      {
        key: 'replacementAirRouteNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'mechanical_supply_and_extract', 'gravity'] },
          IfOtherSelected('replacementAirRoute')
        ],
        ...Input
      }
    ],
    [
      Subtitle('ventilation_ducts'),
      {
        key: 'ductMaterial',
        label: 'material',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('plastic'),
          Option('stone', 'stone_material'),
          Option('metal'),
          Option('asbestos')
        ]
      },
      {
        key: 'ductSize',
        label: 'size',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('100'),
          Option('125'),
          Option('160'),
          Option('200'),
          Option('250'),
          Option('300'),
          OtherOption
        ]
      },
      {
        key: 'ductSizeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] },
          IfOtherSelected('ductSize')
        ],
        ...Input
      }
    ],
    [
      Subtitle('valves'), 
      {
        key: 'extractValveType',
        label: 'extract_valves',
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('poppet', 'poppet_valve'),
          Option('extract', 'extract_air_valve'),
          OtherOption
        ]
      },
      {
        key: 'extractValveTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical', 'gravity', 'mechanical_supply_and_extract'] },
          IfOtherSelected('extractValveType')
        ],
        ...Input
      },
      {
        key: 'supplyValveType',
        label: 'supply_valves',
        visibleIfValues: [
          { key: 'type', values: ['mechanical_supply_and_extract'] }
        ],
        ...Select,
        options: [
          Option('supply', 'supply_air_valve'),
          Option('supply_diffuser', 'supply_air_diffuser'),
          Option('poppet', 'poppet_valve'),
          OtherOption
        ]
      },
      {
        key: 'supplyValveTypeNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['mechanical_supply_and_extract'] },
          IfOtherSelected('supplyValveType')
        ],
        ...Input
      }
    ],
  ],
  [Heating]: [
    [
      Subtitle('basic_information'),
      {
        key: 'type',
        label: 'heating_type',
        ...Select,
        options: [
          // Suorasähkölämmitys
          Option('electric', 'electric_heating'),
          // Varaava sähkölämmitys
          Option('reserving_electric', 'reserving_electric_heating'),
          // Kaukolämpö
          Option('district', 'district_heating'),
          // Öljy
          Option('oil', 'oil_heating'),
          // Pelletti/puu
          Option('wood', 'wood_heating'),
          // Maalämpö
          Option('geothermal', 'geothermal_heating'),
          // Ilmavesilämpöpumppu
          Option('air_water', 'air_water_heat_pump'),
          // Poistoilmalämpöpumppu
          Option('extract_air', 'extract_air_heat_pump')
        ]
      },
      {
        key: 'machineLocation',
        label: 'machine_location_alt',
        visibleIfValues: [
          { key: 'type', values: ['electric'] }
        ],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          Option('boiler_room'),
          OtherOption
        ],
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        ...Input,
        visibleIfValues: [IfOtherSelected('machineLocation')]
      },
      {
        key: 'hasApartmentSpecificMachine',
        label: 'apartment_specific_heat_pump',
        visibleIfValues: [
          { key: 'type', values: ['electric'] }
        ],
        ...Select,
        options: BooleanOptions
      },
      {
        ...Row,
        items: [
          {
            key: 'hasApartmentSpecificElectricHeating',
            label: 'apartment_specific_electric_heating',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasPressureReliefValveExtraction',
            label: 'pressure_relief_valve_extraction',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          },
          {
            key: 'hasApartmentSpecificMachine',
            label: 'apartment_specific_machine_alt',
            visibleIfValues: [
              { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
            ],
            ...Select,
            options: BooleanOptions
          }
        ]
      }
    ],
    [
      {
        ...Subtitle('water_heater'),
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric'] }
        ]
      },
      {
        ...Subtitle('heat_exchanger'),
        visibleIfValues: [
          { key: 'type', values: ['district'] }
        ]
      },
      {
        ...Subtitle('pot_and_burner'),
        visibleIfValues: [
          { key: 'type', values: ['oil', 'wood'] }
        ]
      },
      {
        ...Subtitle('heating_machine'),
        visibleIfValues: [
          { key: 'type', values: ['geothermal', 'air_water', 'extract_air'] }
        ]
      },
      {
        key: 'machineModel',
        label: 'model',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'machineModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          IfOtherSelected('machineModel')
        ],
        ...Input,
      },
      {
        key: 'machineVolume',
        label: 'volume',
        placeholder: 'enter_amount_litres',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric'] }
        ],
        ...Input
      },
      {
        key: 'machineInstallationYear',
        label: 'installation_year',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] }
        ],
        ...Select,
        options: YearOptions()
      },
      {
        key: 'machineLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] }
        ],
        ...Select,
        options: [
          Option('technical_space'),
          Option('heat_distribution_room'),
          Option('boiler_room'),
          OtherOption
        ]
      },
      {
        key: 'machineLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood'] },
          IfOtherSelected('machineLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('relief_valve_extraction'),
      {
        key: 'reliefValveExtractionLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasPressureReliefValveExtraction', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('floor_drain'),
          Option('sewer'),
          OtherOption
        ]
      },
      {
        key: 'reliefValveExtractionLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasPressureReliefValveExtraction', values: ['yes'] },
          IfOtherSelected('reliefValveExtractionLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('apartment_specific_heat_pump'),
      {
        key: 'apartmentSpecificHeatPumpModel',
        label: 'model',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: [
          ...DefaultOptions,
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificHeatPumpModelNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificHeatPumpModel')
        ],
        ...Input,
      },
      {
        key: 'apartmentSpecificHeatPumpInstallationYear',
        label: 'installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Input
      },
      {
        key: 'apartmentSpecificHeatPumpLocation',
        label: 'location',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] }
        ],
        ...Select,
        options: [
          Option('living_room', 'living_room_short'),
          Option('bedroom', 'bedroom_short'),
          Option('hallway', 'hallway_short'),
          OtherOption
        ]
      },
      {
        key: 'apartmentSpecificHeatPumpLocationNotes',
        ...OtherPlaceholder,
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
          { key: 'hasApartmentSpecificMachine', values: ['yes'] },
          IfOtherSelected('apartmentSpecificHeatPumpLocation')
        ],
        ...Input,
      }
    ],
    [
      Subtitle('heat_distribution'),
      {
        key: 'heatDistributionType',
        label: 'type',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('ceiling', 'ceiling_er_heating'),
          Option('floor', 'floor_er_heating'),
          Option('air', 'air_heating'),
          Option('radiator', 'radiator_heating'),
        ]
      },
      {
        key: 'heatDistributionType',
        label: 'type',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water'] }
        ],
        ...Select,
        options: [
          Option('water_radiator', 'water_radiators'),
          Option('water_underfloor', 'water_underfloor_heating')
        ]
      },
      {
        key: 'heatDistributionInstallationYear',
        label: 'installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Input
      },
      {
        key: 'heatDistributionThermostatInstallationYear',
        label: 'thermostat_installation_year',
        placeholder: 'enter_date',
        visibleIfValues: [
          { key: 'type', values: ['electric', 'reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Input
      },
      {
        key: 'heatDistributionFrameLines',
        label: 'frame_lines',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('plastic_pcx'),
          Option('steel'),
          Option('copper'),
          Option('composite')
        ]
      },
      {
        key: 'heatDistributionFrameLineSize',
        label: 'frame_line_size',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] },
        ],
        ...Select,
        options: [
          Option('22'),
          Option('25'),
          Option('32'),
          Option('40'),
          Option('50')
        ]
      },
      {
        key: 'heatDistributionCircuits',
        label: 'heat_distribution_circuits',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('plastic_pcx'),
          Option('steel'),
          Option('copper'),
          Option('composite')
        ]
      },
      {
        key: 'heatDistributionCircuitSize',
        label: 'heat_distribution_circuit_size',
        visibleIfValues: [
          { key: 'type', values: ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water', 'extract_air'] }
        ],
        ...Select,
        options: [
          Option('12'),
          Option('15'),
          Option('18'),
          Option('22'),
          Option('25'),
        ]
      },
    ]
  ],
  [Sewerage]: [
  ],
  [WaterPipes]: [
  ],
  [Cooling]: [
  ],
  [Elevator]: [
  ],
  [Electric]: [
  ]
}

export const BuildingStructuresLayout = [
  {
    title: Foundation,
    label: 'foundations_and_plinth',
    sections: BuildingStructureSections[Foundation]
  },
  {
    title: BaseFloor,
    sections: BuildingStructureSections[BaseFloor]
  },
  {
    title: ExteriorWall,
    label: 'exterior_walls_and_facade',
    sections: BuildingStructureSections[ExteriorWall]
  },
  {
    title: IntermediateFloor,
    label: 'intermediate_floors',
    sections: BuildingStructureSections[IntermediateFloor]
  },
  {
    title: InteriorWall,
    label: 'interior_walls',
    sections: BuildingStructureSections[InteriorWall]
  },
  {
    title: UpperFloor,
    sections: BuildingStructureSections[UpperFloor]
  },
  {
    title: WaterRoof,
    sections: BuildingStructureSections[WaterRoof]
  },
]

export const BuildingPartsLayout = [
  {
    title: Window,
    label: 'windows',
    sections: BuildingPartSections[Window]
  },
  {
    title: Door,
    label: 'doors',
    sections: BuildingPartSections[Door]
  },
  {
    title: Balcony,
    label: 'balconies',
    sections: BuildingPartSections[Balcony]
  },
  {
    title: Terrace,
    label: 'terraces',
    sections: BuildingPartSections[Terrace]
  }
]

export const BuildingSystemsLayout = [
  {
    title: Ventilation,
    sections: BuildingSystemSections[Ventilation]
  },
  {
    title: Sewerage,
    sections: BuildingSystemSections[Sewerage]
  },
  {
    title: WaterPipes,
    sections: BuildingSystemSections[WaterPipes]
  },
  {
    title: Heating,
    sections: BuildingSystemSections[Heating]
  },
  {
    title: Cooling,
    sections: BuildingSystemSections[Cooling]
  },
  {
    title: Elevator,
    sections: BuildingSystemSections[Elevator]
  },
  {
    title: Electric,
    sections: BuildingSystemSections[Electric]
  }
]