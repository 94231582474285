import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Colors } from '../../Utils/theme'

export default function FormSection(props: any) {
  const { title, position, isLast } = props

  return (
    <Box
      mr={position === 'left' ? '1rem' : '0'}
      ml={position === 'right' ? '1rem' : '0'}
    >
      <Typography
        variant='body1'
        color={Colors.primaryDarkest}
        fontSize='1.125rem'
        fontWeight={600}
        mb='0.375rem'
      >
        {title}
      </Typography>
      <Box
        sx={styles.container}
        minHeight={props.minHeight || 'auto'}
        mb={isLast ? 0 : '2rem'}
      >
        {props.children}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.25rem'
  }
} as const