import { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import { Button, Input, Select } from '../../../Components'
import { RoomTypes, RoomLayout, RoomSections } from '../../../Utils/room'
import { FormTypes } from '../../../Utils/layout'
import { Colors } from '../../../Utils/theme'

const CreateRoom = (props: any) => {
  const { sessionStore, roomStore }: any = useStore()
  const { me } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  // Room general information
  const [type, setType] = useState('')
  const [areaSize, setAreaSize] = useState(null)
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  
  const surfaceMaterials: any = useRef({})
  const windows: any = useRef({})
  const doors: any = useRef({})
  const socketsAndLighting: any = useRef({})
  const ventilation: any = useRef({})
  const heating: any = useRef({})
  const fixtures: any = useRef({})
  const otherFixtures: any = useRef({})
  
  const [hiddenItems, setHiddenItems] = useState<any>([])

  // Collapse states
  const [openSections, setOpenSections] = useState([
    'room_information',
    ...RoomLayout.map((section: any) => section.title)
  ])

  // Modal
  const [showVisibleFieldsModal, setShowVisibleFieldsModal] = useState(false)

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const openVisibleFieldsModal = () => setShowVisibleFieldsModal(true)
  const closeVisibleFieldsModal = () => setShowVisibleFieldsModal(false)

  const onChange = (category: string, key: string, value: any) => {
    if (category === RoomSections.SurfaceMaterials) {
      surfaceMaterials.current = { ...(surfaceMaterials?.current || {}), [key]: value }
    } else if (category === RoomSections.Windows) {
      windows.current = { ...(windows?.current || {}), [key]: value }
    } else if (category === RoomSections.Doors) {
      doors.current = { ...(doors?.current || {}), [key]: value }
    } else if (category === RoomSections.SocketsAndLighting) {
      socketsAndLighting.current = { ...(socketsAndLighting?.current || {}), [key]: value }
    } else if (category === RoomSections.Ventilation) {
      ventilation.current = { ...(ventilation?.current || {}), [key]: value }
    } else if (category === RoomSections.Heating) {
      heating.current = { ...(heating?.current || {}), [key]: value }
    } else if (category === RoomSections.Fixtures) {
      fixtures.current = { ...(fixtures?.current || {}), [key]: value }
    } else if (category === RoomSections.OtherFixtures) {
      otherFixtures.current = { ...(otherFixtures?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${category}_${key}_${value}`)
  }

  const getValues = useCallback((category: string) => {
    if (category === RoomSections.SurfaceMaterials) {
      return surfaceMaterials?.current || {}
    } else if (category === RoomSections.Windows) {
      return windows?.current || {}
    } else if (category === RoomSections.Doors) {
      return doors?.current || {}
    } else if (category === RoomSections.SocketsAndLighting) {
      return socketsAndLighting?.current || {}
    } else if (category === RoomSections.Ventilation) {
      return ventilation?.current || {}
    } else if (category === RoomSections.Heating) {
      return heating?.current || {}
    } else if (category === RoomSections.Fixtures) {
      return fixtures?.current || {}
    } else if (category === RoomSections.OtherFixtures) {
      return otherFixtures?.current || {}
    }
  }, [surfaceMaterials, windows, doors, ventilation, heating, fixtures, otherFixtures])

  const isSaveDisabled = () => {
    if (!type) return true
    return false
  }

  const getRoomTypeOptions = () => Object.values(RoomTypes).map((type: any) => ({
    label: t(type),
    value: type
  }))

  const create = () => {
    const callback = () => {
      setType('')
      setAreaSize(null)
      surfaceMaterials.current = {}
      windows.current = {}
      doors.current = {}
      socketsAndLighting.current = {}
      ventilation.current = {}
      heating.current = {}
      fixtures.current = {}
      otherFixtures.current = {}
      setHiddenItems([])
    }
    const newRoom = {
      type,
      areaSize,
      surfaceMaterials: surfaceMaterials.current,
      windows: windows.current,
      doors: doors.current,
      socketsAndLighting: socketsAndLighting.current,
      ventilation: ventilation.current,
      heating: heating.current,
      fixtures: fixtures.current,
      otherFixtures: otherFixtures.current,
      hiddenItems: hiddenItems.join(',')
    }
    roomStore.createRoom(params?.pUuid, params?.aUuid, newRoom, callback)
  }
  const cancel = () => navigate(-1)

  const renderCustomForm = (sections: any) => {
    if (sections?.length) {
      return sections.map((section: any, index: number) => {
        return (
          <CustomForm
            key={section?.value || index}
            section={section}
            values={getValues(section.category)}
            onChange={onChange}
            lastUpdatedValue={lastUpdatedValue}
            isLast={index === sections.length - 1}
          />
        )
      })
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title?: string): any => {
    // Check if is an array of sections
    if (Array.isArray(sections[0])) {
      return sections.map((section: any) => renderCustomFormContainer(section[0]))
    }
    if (sections?.type === FormTypes.SectionSubsections) {
      return (
        <>
          {
            sections?.title && (
              <Typography variant='h4' mt='0.75rem' mb='1.25rem' color={Colors.primary}>
                {t(sections.title)}
              </Typography>
            )
          }
          {sections?.items.map((item: any) => renderCustomFormContainer(item.items, item.value))}
        </>
      )
    } else {
      const leftColumnSections = sections.filter((section: any) => section?.position === 'left')
      const rightColumnSections = sections.filter((section: any) => section?.position === 'right')
      return (
        <Box sx={styles.customFormOuterContainer}>
          {
            title && (
              <Typography variant='h5' mb='1.25rem' textTransform='uppercase'>
                {t(title)}
              </Typography>
            )
          }
          <Grid container columns={12} sx={styles.customFormContainer}>
            <Grid xs={12} sm={6}>{renderCustomForm(leftColumnSections)}</Grid>
            <Grid xs={12} sm={6}>{renderCustomForm(rightColumnSections)}</Grid>
          </Grid>
        </Box>
      )
    }
  }

  const RoomSectionsLayout = useMemo(() => {
    return RoomLayout.filter((item: any) => !hiddenItems?.includes(item?.title)).map((layout: any) => {
      return (
        <CollapseSection
          key={layout.title}
          title={t(layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue, hiddenItems])

  // Do not show anything if params are missing
  const pUuid = params?.pUuid || null
  const aUuid = params?.aUuid || null
  if (!pUuid || pUuid?.length !== 36 || !aUuid || aUuid?.length !== 36) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <CollapseSection
          title={t('room_information')}
          open={openSections?.includes('room_information')}
          onClick={() => toggleOpenSection('room_information')}
        >
          <Box sx={styles.formContainer}>
            <Box sx={styles.row}>
              <Select
                label={t('room_type')}
                options={getRoomTypeOptions()}
                value={type}
                onChange={setType}
                maxWidth='24rem'
                mr='2rem'
              />
              <Input
                label={t('area')}
                value={areaSize}
                onChange={setAreaSize}
                maxWidth='16rem'
              />
            </Box>
          </Box>
        </CollapseSection>
        {RoomSectionsLayout}
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_room')}
            onClick={create}
            width='100%'
            mb='1rem'
            disabled={isSaveDisabled()}
          />
          <Button
            text={t('cancel')}
            onClick={cancel}
            variant='text'
            width='100%' 
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateRoom)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '60rem',
    overflowY: 'hidden'
  },
  customFormOuterContainer: {
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 2rem 2rem',
    mb: '1.5rem'
  },
  customFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
